import { useContext, useEffect, useState,useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL, GlobalFormId } from "../../config";
import axios, { all } from "axios";
import { UserContext } from "../../context/userContext";
import { Loading } from "../../Loading/loading";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const FormSearch = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [allForms, setAllForms] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [form, setForm] = useState({});
  const [statue, setStatue] = useState(null);
  const [hotel, setHotelId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [myForms, setMyForms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize,setPageSize] = useState(10);
  const [paginatedUsers, setPaginatedUsers] = useState([]);
  const totalPages = Math.ceil(allForms?.length / pageSize);
  const contentRef = useRef();
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const handleDownloadPdf = () => {
        setIsGeneratingPdf(true); // Set state to hide the button

        setTimeout(() => {
            const input = contentRef.current;

            html2canvas(input, { scale: 2 }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`${form.name}`);

                setIsGeneratingPdf(false); 
            });
        }, 100);
};
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);

  function statusChange(state) {
    if (state.isOpen) {
      return "Open";
    }
    if (state.isPending) {
      return "signing";
    }
    if (state.isAccepted) {
      return "Accepted";
    }
    if (state.isRejected) {
      return "Rejected";
    }
    if (state.isRetuningToDepartment || state.isRetuningToUser) {
      return "Returned";
    }
  }
  function statusValue(value) {
    setStatue(value);
    //console.log(value);
    filterForms()
  }
  function hotelChange(value) {
    //console.log(value);
    setHotelId(value);
    filterForms()
  }
  function departmentChange(value) {
    //console.log(value);
    setDepartmentId(value);
    filterForms()
  }

  function filterForms() {
    //console.log('sdds');
    setAllForms(
      myForms.filter((form) => {
        var filterCount = 0;
        var count = 0;
        if (departmentId) {
          count++;
          if (form.department.departmentId == departmentId) {
            filterCount++;
          }
        }
        if (hotel) {
          count++;
          if (form.hotel.hotelId == hotel) {
            filterCount++;
            console.log('hotel change');

          }
        }
        if (startDate) {
          count++;
          if (
            new Date(form.createdDate.substring(0, 10)) >= new Date(startDate)
          ) {
            filterCount++;
          }
        }
        if (endDate) {
          count++;
          if (
            new Date(form.createdDate.substring(0, 10)) <= new Date(endDate)
          ) {
            filterCount++;
          }
        }

        if (statue) {
          count++;
          if (statue == "Returned" && form.userFormStatus.isRetuningToUser) {
            filterCount++;
          } else if (statue == "signing" && form.userFormStatus.isPending) {
            filterCount++;
          } else if (statue == "Open" && form.userFormStatus.isOpen) {
            filterCount++;
          }
          if (statue == "Rejected" && form.userFormStatus.isRejected) {
            filterCount++;
          }
        }

        if (count == filterCount) {
          return true;
        } else {
          return false;
        }
      })
    );
  }



  async function getFormById(formId) {
    try {
      if (user) {
        // setEmptyValues()
        //console.log(formId);
        setLoading(true);
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetFormById/${formId}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log("getFormById", data);
        if (data) {
          setForm(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  }


  const searchByStatus=(e)=>{
    const status=e.target.value
    //console.log(status);

      setAllForms(myForms.filter((form)=>statusChange(form.userFormStatus).toLowerCase().includes(status.toLowerCase())))
  }
  
  useEffect(() => {
    if (id&&user) {
      //console.log(id);
      getFormById(id);
      getForms();
    }
  }, [id, user]);


    async function getForms() {
      try {
 
          setLoading(true);
          const { data } = await axios.get(
            `${BASE_URL}/api/RegisterationForms/GetDepartmentForms`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );

          const forms=data.filter((form)=>form.view&&form.formId==id)
          setAllForms(forms);
          const sortedUsers = [...forms].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setPaginatedUsers(sortedUsers.slice((page - 1) * pageSize, page * pageSize));   
          setMyForms(forms);
          //console.log("data", data);
        
      } catch (error) {
        console.log(error);
      } finally {
        // setLoading(false);
      }
    }

  const viewForm=(id)=>{
    navigate(`/form-view/${id}`)
    }    
  async function getAllDepartments() {
    const { data } = await axios.get(
      `${BASE_URL}/api/Departments/GetAllDepartments`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    setDepartments(data);
    //console.log("GetAllDepartments", data);
  }
  async function getAllHotels() {
    const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    //console.log("GetAllHotels", data);
    setHotels(data);
  }

  
const rowCount=(e)=>{
  setPageSize(Number(e.target.value));
    setPage(0);
}
useEffect(() => {
  if (page==0) {
    setPage(1)
  }
},[page])

  useEffect(() => {
    if (user) {
      getAllDepartments();
      getAllHotels();
    }
  }, [user]);

  const handelStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handelEndDate = (e) => {
    setEndDate(e.target.value);
  };
  useEffect(() => {
    const sortedUsers = [...allForms].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    setPaginatedUsers(sortedUsers?.slice((page - 1) * pageSize, page * pageSize));
  }, [page, allForms]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPaginationItems = () => {
    let start = Math.max(1, page - 1);
    let end = Math.min(totalPages, page + 1);
    const pages = [];
    
    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };

const goToForm=()=>{
  localStorage.setItem("formId",JSON.stringify(form.formId))
  navigate('/fill-form')

}
  if (!form.length && loading) {
    return <Loading />;
  }
   else {
    return (
      <>
        <div className="smallPadding w-100">
          <div className="page-breadcrumb no-print mb-2">
            <div className="row">
              <div className="col-lg-12 px-1">
                <div className="card">
                  <div className="px-3 pt-2">
                    <div className="col-md-12">
                      <div className=" breadleft">
                        <h5 className="page-title">
                          <span className="page-title-toSave">{form.name}</span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="ml-auto breadright">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">Forms</li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              {form.name}
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body text-start">
                    <div>
                      <Link 
                        //  to={!form.create ? "#" : `/fill-form/${form.formId}`}
                        onClick={()=>goToForm()}
                        className={`btn btn-outline-cyan btn-lg border border-1 `}
                      >
                        <strong>
                          <i className="fa fa-cloud"></i>&nbsp; Add {form.name}{" "}
                          Form
                        </strong>
                      </Link>
                    </div>

                    <div className="row justify-content-center align-items-center my-2">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="hotel"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Hotels
                        </label>
                        <div className="dropdown  show-tick show-menu-arrow form-control filter-indexs">
                          <select
                            onChange={(e) => {
                              const selectedValues = e.target.value;
                              if (selectedValues !== "") {
                                hotelChange(selectedValues);
                              }
                            }}
                            id="hotel_id"
                            className=" show-menu-arrow form-control filter-indexs"
                            data-container="body"
                            data-live-search="true"
                            title="Select Hotel"
                            data-hide-disabled="true"
                            data-actions-box="true"
                            data-virtual-scroll="false"
                            multiple=""
                            tabIndex="-98"
                          >
                            <option value="">Select Hotel</option>
                            {hotels.map((hotel) => (
                              <option key={hotel.id} value={hotel.id}>
                                {hotel.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="department"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Department
                        </label>
                        <div className="dropdown  show-tick show-menu-arrow form-control filter-indexs">
                          <select
                            onChange={(e) => {
                              const selectedValues = e.target.value;
                              if (selectedValues !== "") {
                                departmentChange(selectedValues);
                              }
                            }}
                            id="department_id"
                            className=" show-menu-arrow form-control filter-indexs"
                            data-container="body"
                            data-live-search="true"
                            title="Select Department"
                            data-hide-disabled="true"
                            data-actions-box="true"
                            data-virtual-scroll="false"
                            multiple=""
                            tabIndex="-98"
                          >
                            <option value="">Select Department</option>
                            {departments.map((department) => (
                              <option key={department.id} value={department.id}>
                                {department.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label className="text-right control-label col-form-label ">
                          From Creation Date:
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="from_date"
                            id="fromDate"
                            onChange={(e) => handelStartDate(e)}
                            className="form-control Delivery-updater"
                            data-toggle="datepicker"
                            placeholder="yyyy-mm-dd"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label className="text-right control-label col-form-label ">
                          To Creation Date:
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="to_date"
                            id="toDate"
                            onChange={(e) => handelEndDate(e)}
                            className="form-control Delivery-updater"
                            data-toggle="datepicker"
                            placeholder="yyyy-mm-dd"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="hotel1"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Status
                        </label>
                        <div className="dropdown  show-tick show-menu-arrow form-control filter-indexs">
                          <select
                            id="hotel_id1"
                            className=" show-menu-arrow form-control filter-indexs"
                            data-container="body"
                            data-live-search="true"
                            title="Select Hotel"
                            data-hide-disabled="true"
                            data-actions-box="true"
                            data-virtual-scroll="false"
                            multiple=""
                            tabIndex="-98"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (selectedValue !== "") {
                                statusValue(e.target.value);
                              }
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="Open">Open</option>
                            <option value="signing">signing</option>
                            <option value="Returned">Returned</option>
                            <option value="Rejected">Rejected</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me mt-5">
                      <div className="me-3">
                        <input
                          onClick={filterForms}
                          type="submit"
                          name="submit"
                          className="btn btn-cyan m-0 px-4 mb-2"
                        />
                      </div>
                    </div>
                    </div>
                    <div className="border-top"></div>
                    <h4 className="d-none" id="indexName">
                      Complimentary Form
                    </h4>
                    <h4 className="card-title" id="totalCount"></h4>
                    <span id="tableFun" className="d-none">
                      general/complimentary/complimentary_ajax
                    </span>
                    <div
                      id="allowance-list-table_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer">
                       <div className="row  mt-2 gy-3">
                        <div className="col-1 col-sm-2 col-me3">
                          <div
                            className="dataTables_length"
                            id="allowance-list-table_length"
                          >
                            <label>
                              <select className="form-control" value={pageSize} onChange={(event)=>rowCount(event)}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        {!isGeneratingPdf && (
                       <div className="col-3 col-me4">
                          <div className="dt-buttons btn-group">
                            <button
                              className="btn btn-secondary  buttons-collection btn-outline-secondary btn-sm"
                              tabIndex="0"
                              aria-controls="allowance-list-table"
                              type="button"
                              aria-haspopup="true"
                              onClick={handleDownloadPdf}
                            >
                              <span>
                                Export <i className="far fa-file-alt "></i>
                              </span>
                            </button>
                            <button
                              className="btn btn-secondary buttons-collection dropdown-toggle buttons-colvis"
                              tabIndex="0"
                              aria-controls="allowance-list-table"
                              type="button"
                              aria-haspopup="true"
                            >
                              <span>Visable</span>
                            </button>
                          </div>
                        </div>)}
                        <div className="col-8 col-sm-6 col-me2">
                          <div
                            id="allowance-list-table_filter"
                            className="dataTables_filter text-md-end"
                          >
                            <label className="d-inline-flex align-items-center">
                              Search:&nbsp;&nbsp;
                              <input
                                type="search"
                                className="form-control"
                                placeholder="Form status"
                                aria-controls="allowance-list-table"
                                onChange={(event)=>searchByStatus(event)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div ref={contentRef} className="row mt-3 ">
                        {isGeneratingPdf &&(
                          <div className="text-center mb-3">
                          <h1 className="pt-5">{form.name} Report</h1>

                          </div>
                        )}
                        <div className="col-12 paddingTable-me">
                          {/* filtration table..................................................... */}
                          <div className="table-responsive">
                            <table
                              id="allowance-list-table"
                              className="table table-hover bg-black indexTable no-footer dataTable dtr-inline"
                              role="grid"
                              aria-describedby="allowance-list-table_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th

                                    className="tableCol sorting_desc "
                                    id="id"
                                    width="1%"
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="id"
                                    width="9%"
                                  >
                                    Form
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="hid"
                                    width="9%"
                                  >
                                    Hotel Name
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="dep_code"
                                    width="9%"
                                  >
                                    Department Name
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="status"
                                    width="9%"
                                  >
                                    Status
                                  </th>

                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="timestamp"
                                    width="9%"
                                  >
                                    Created At
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {/* <tr className="odd">
                     <td colSpan="12" className="dataTables_empty" valign="top">No matching records found</td>
                      </tr> */}
                                {paginatedUsers.map((form, index) => (

                                  <tr key={index} className="odd " style={{ cursor: 'pointer' }} onClick={()=>viewForm(form.userFormId)} >
                                    <td valign="top">{(page - 1) * pageSize + index + 1}</td>
                                    <td valign="top">{form.formName} #{form.userFormId}</td>
                                    <td valign="top">{form.hotel.hotelName}</td>
                                    <td valign="top">
                                      {form?.department?.departmentName}
                                    </td>
                                    <td valign="top">
                                      {statusChange(form.userFormStatus)}
                                    </td>
                                    {/* <td  valign="top">No matching records found</td> */}
                                    <td valign="top">
                                      {form.createdDate.substring(0, 10)} {form.createdDate.substring(11, 16)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div
                            id="allowance-list-table_processing"
                            className="dataTables_processing card d-none"
                          >
                            Processing...
                          </div>
                        </div>
                      </div>
{allForms.length>9&&
                      <div className="d-flex justify-content-center">
                        <nav>
                          <ul className="pagination">
                            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(page - 1)}>Previous</button>
                            </li>
                            {getPaginationItems()}
                            <li className={`page-item ${page === Math.ceil(allForms.length / pageSize) ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(page + 1)}>Next</button>
                            </li>
                          </ul>
                        </nav>
                      </div>
}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
