import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Loading } from "../Loading/loading";
import { BASE_URL } from "../config";

export const Reports = () => {
    const [loading, setLoading] = useState(false);
    const [fileUrl, setFileUrl] = useState("");
    const [fileUrl1, setFileUrl1] = useState("");
    const [user, setUser] = useState("");
    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endtDate, setEndDate] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user"));
        setUser(token);
    }, []);

    const handleGenerateReport = async () => {
        setErrorMessage("");
        setLoading(true);
        setFileUrl("")
        setFileUrl1("")

        await generateReportPdf();
        await generateReportExcel();
        setLoading(false);
    };

    const generateReportPdf = async () => {
        try {
            const { data, headers } = await axios.post(
                `${BASE_URL}/api/Reports/GetFormReportPdf`,
                { name, startDate, endtDate },
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                    responseType: "blob",
                }
            );
            console.log("Report Data:", data);

            const blob = new Blob([data], {
                type: headers['content-type'] || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fUrl = URL.createObjectURL(blob);
            setFileUrl1(fUrl);
        } catch (error) {
            console.error(error);
            setErrorMessage("Sorry, no forms found.");
        } finally {
            setLoading(false);
        }
    }
    const generateReportExcel = async () => {
        try {
            const { data, headers } = await axios.post(
                `${BASE_URL}/api/Reports/GetFormReportExcel`,
                { name, startDate, endtDate },
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                    responseType: "blob",
                }
            );
            console.log("Report Data:", data);

            const blob = new Blob([data], {
                type: headers['content-type'] || "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const fUrl = URL.createObjectURL(blob);
            setFileUrl(fUrl);
        } catch (error) {
            console.error(error);
            setErrorMessage("Sorry, no forms found.");
        } finally {
            setLoading(false);
        }
    }


    const handleDownloadPdf = () => {
        if (fileUrl1) {
            const link = document.createElement("a");
            link.href = fileUrl1;
            link.download = `${name}.pdf`;
            link.click();
        }
    };

    const handleDownloadExcel = () => {
        if (fileUrl) {
            const link = document.createElement("a");
            link.href = fileUrl;
            link.download = `${name}.xlsx`;
            link.click();
        }
    };


    return (
        <div className="container">
            <div className="mt-4 ">
                <form className="reportform" onSubmit={(e) => { e.preventDefault(); handleGenerateReport(); }}>
                    <h4 className="mb-4 text-center">Generate Report</h4>
                    <div className="row">

                        <div className="mb-3 col-12">
                            <label htmlFor="name" className="form-label">Select Report Type</label>
                            <select
                                id="name"
                                className="form-select"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            >
                                <option value="" disabled>Choose...</option>
                                <option value="Out Going">Out Going</option>
                                <option value="Out Delivery">Out Delivery</option>
                                <option value="Gate Pass">Gate Pass</option>
                            </select>
                        </div>

                        <div className="mb-3 col-6">
                            <label htmlFor="startDate" className="form-label">Start Date</label>
                            <input
                                type="date"
                                id="startDate"
                                className="form-control"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                required
                            />
                        </div>

                        <div className="mb-3 col-6">
                            <label htmlFor="endDate" className="form-label">End Date</label>
                            <input
                                type="date"
                                id="endDate"
                                className="form-control"
                                value={endtDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">

                        <button type="submit" className="btn btn-outline-success" disabled={loading}>

                            {!loading ? "Get Report" : "Generating..."}
                        </button>
                    </div>
                </form>
                {errorMessage && (
                    <div className="alert alert-danger m-3 text-center ">
                        {errorMessage}
                    </div>
                )}
                <div className="row mt-4">
                    {(fileUrl && !errorMessage) && (
                        <div className="col-6  p-5">
                            <div className="text-end">
                                {name}.xlsx
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACcklEQVR4nGNgGAWjYBSMgmEJDOsNpfRqDX11aw0bdGsNN+vWGj5jGIzAvt6eRb9GX1uvxiBOt8Zwol6t0W69WsM3erVG/9HxQLuVQb3Uilev2thGt9YoDeRY3RrDI3o1ht+xOXbAPaBboSsIdmyNUb5ujdEi3Rqjq3o1Rn+JdeyAe0CPAofiwj7rA8jC3uv9jwxpD/isDyA99kY9UDsaA/8HXRJCBjCH4QOjHtBDCr3Obd0ooVO+qgpF3q7d6f+XH1/g8j07+gZXDBjWm/6/9eI23PC7r+7+N6gzgcsvPb4cLnfn5Z3/Rg1mg68YTZmfjhJCuUsKwOIevT7/f/7+CRb79+/f/+R5aYO3Hth7bT/cA5ceXwaLbbmwFS62+cKWwZ2Jvfp8//+AhjYIlKws///3318w+9P3T/8dO10Gtwf0ao3+zzowB27Jx28f4ez2LZ1Doxg1b7b+//LjSxTLrj+78d+gHpGpB20e0Ks1+m/VaofhgWfvn/03a7IaGh5YfXot3OGvP7+Bs2funz34k1Di3BRwUQkC3399/5+3tBBuIago9Z0QOHg9YNJo8f/uq3twC+YcnAcWP3r7GFzs2J0Tg9cDMw/MRil9bNscwOKhUyPgRSkIlK6sGHx5IGhy6P9ff37hbOvsvLwLJV+AMvqg8YBBncn/8w8vwB0IKoFMmyxRHTch4P+fv3/gahYfWzr4kpAehXjUAwMdA4OmItMb9UDAaAwMqSTkvd7/MMOIG9zFNbyuW2tkDJsLGNTD6+RMcOjVGnWAZmMG7QQHCYBRq95QRbfOOFS31rBNr8Zom16N4XNSDBgFo2AUjAKGIQEAkqNB3aFhJ4wAAAAASUVORK5CYII=" />
                            <img className="cursor-pointer ms-4" onClick={handleDownloadExcel} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVR4nO3WsUoDQRAG4NFYikZvRsFCSOET2FjY+BCpLBSZXUHwGaJtbPMUVhZiYeFM0MJSUPAFBEGwFCWFJ4eNhcScOW53yXww/f47/y0HYIypFDnNhw3EjixAYMlvwISWfIXIAgSW/AZMaMlXiCxAYLaB0GwDoSWxAXTS++ugZQed9OpL0D5tEOt5hYe/hI7M1BcAABYPb+fQ6f3YAVgf53ekCSEsc7+FTl7+H0BecV/XICTysomsH+VvXgaZ1y2IAbHslu49q4eYIOvJ6B+tdiE6nXwanZ6NUJ2L4hWDGNGBzCLr3ZCbfyheL4hZtnezQqxPv3T+ecFfr0IKkK/WkeXtx82/L7FsQErIa5ucfBaTcX8bUkQsx+TkCNKVT32PmUBU8f/+uAMWwNkG8lorZMyE+ALBYbmRGmsCgQAAAABJRU5ErkJggg==" />
                            </div>
                        </div>
                    )}
                    {(fileUrl1 && !errorMessage) && (
                        <div className="col-5 p-5 ">
                            <div className="text-start">
                                {name}.pdf
                                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEF0lEQVR4nO2bUWhTVxjHP4tsc7UP8yUMHAh78GG+7DGusmxaFGVMV+o20eGjUpZGl7hQlcqQUmEWVJBaKyv0ocW9DJtKjJ0MkbQb1NWmhbbRpGuT2lDjzTm3+iC2/3FyTZMba43U5PY254M/3By+nHznl++c8x04l0iaNGnScjRVVTcxxn5ijDUUSKdUVf0WwHtkpAFYxTlvZIzNcc5hgB6oqvqJYQBUVa02aODzSiQSMcbYRkMAcM6DqUBCDyZw3eMviPx37oExNg+BMTauKMqGgg4ewDuZqX/ohwbYrPaC6dzZq9nZEJyZmfmwYACi0ej7mQEcqKxLBlb/tQO/7jmSV1VscSR/q/niH9nTYURVVYuhABSHE3C58qqK8pr5TGi90pUNoT+RSHywogFszwDwxeYa/N7xpw6Coii9sVhsbVEAsAkIn9XAc+2ODkI8Hr8F4N2iAGCz2rG13IFu3z86CNPT0x4Aq4sCgE1A2OLAX7f6dBCmpqauAigpCgA2qx0Vnx9Fj39AB2F8fPw3UbUWBQCb1Y4dX7rwb9+wDkIoFGopGgA2qx1fVfyMocB9HYRAIHC2aADYrHbs3lmL4Oh/OghdXV3Vpgewozz3kvm7b05hLByZjzMcDoeI6GNTA6jdpZXCucpRfT6zUpwrLS3dS0SrTQtg1ulCzO7E5I+La+iQMxnTgb2/6KaBxWI5SEQW0wJAjhKxJAFU1i0E4CMJYCkWlRkQlVOAyzWAy0WQy12Ay22Qv64OuH0bePo0LUUBhoaAxsa0z40bwJMnaZ9Hj4DBQaC5Wd9XfT0Qj+v7E/L7l3EdMDGBpPX3A7292uDn5gDGgBMnNJ+REc0nGAQCAe07wkdYZ2e6LwFEmIAg+kqprc0EAM6cSbfdvau1NTXpAVy4kPa5fBmYnQWePwdOn9YDGBgwUSU4kQXg2LGXB7wQAKHhYa29o0MPIBTSnoUExNpaEwB4+FB7fvxY+zw5CbjdiwPo6dHaPR49gGzLnCbLFsDYGDA6qq0BN28CdXVpn1cBSLUvlgGXLgHHj5tsDXBlaSEALS3aQrji1gDXIgBSu0Akog1eaKFdwFQA+vq0Pf7kyVcH7PW+XAcIECK9M/0aGoBnz4DubhMBcBkjCcAhMwByCljlGgC5CFbKXQByG+SyDoAshLisBCFLYS7PApCHIYs8DR7M23G4vgCXpXOViCVvZwEYfF3+TSRiS8UpYi4rK9u/ZABGvjDxJvJ5/0Y4lL4kFYlEJonoeyJaT0s1zvnhzNQyg1pbW1teAFi3ZAAAVoXD4SZx88rogb1OIkafz9dZUlKyj4h2EdHbuzrrdrur2tvb27xe77XlKBFbVVWV88U/X0lEeXmhYj0RlRPR9mWqbUT0KRGtycfgpUmjlWX/A+Aor+2U7Q0HAAAAAElFTkSuQmCC" />
                            <img className="cursor-pointer ms-4" onClick={handleDownloadPdf} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVR4nO3WsUoDQRAG4NFYikZvRsFCSOET2FjY+BCpLBSZXUHwGaJtbPMUVhZiYeFM0MJSUPAFBEGwFCWFJ4eNhcScOW53yXww/f47/y0HYIypFDnNhw3EjixAYMlvwISWfIXIAgSW/AZMaMlXiCxAYLaB0GwDoSWxAXTS++ugZQed9OpL0D5tEOt5hYe/hI7M1BcAABYPb+fQ6f3YAVgf53ekCSEsc7+FTl7+H0BecV/XICTysomsH+VvXgaZ1y2IAbHslu49q4eYIOvJ6B+tdiE6nXwanZ6NUJ2L4hWDGNGBzCLr3ZCbfyheL4hZtnezQqxPv3T+ecFfr0IKkK/WkeXtx82/L7FsQErIa5ucfBaTcX8bUkQsx+TkCNKVT32PmUBU8f/+uAMWwNkG8lorZMyE+ALBYbmRGmsCgQAAAABJRU5ErkJggg==" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

