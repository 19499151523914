import React from "react"
import Style from '../formLevel/form-level.module.css'
import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { Link, useParams } from "react-router-dom";
import AcceptedStatus from '../../assets/imgs/accepted icon.png'
import RejectedStatus from '../../assets/imgs/Rejected icon.jpg'
export const LevelView=()=>{
    const { id } = useParams();
    const [user, setUser] = useState("");
    const [levels, setLevels] = useState([]);
    const [currLevel, setCurrLevel] = useState(0); // Default to a high value
    const [userDepartmentId, setUserDepartmentId] = useState(0);
    const [userData, setUserData] = useState('');
    const [rejected, setRejected] = useState(false);
    const [disMiss, setdisMiss] = useState(false);
    const [comment, setComment] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [hotelId, setHotelId] = useState('');
    const [hotels, setHotels] = useState([]);
    const [departments, setDepartments] = useState([]);
  
  
    useEffect(() => {
      const token = JSON.parse(localStorage.getItem("user"));
      const data = JSON.parse(localStorage.getItem("userData"));
      setUserData(data)
      setUser(token);
    }, []);
  
    useEffect(() => {
      if (userData) {
        getUserData(userData.id)
      }
    }, [userData]);
    const getUserData = async (id) => {
      try {
        if (user && id) {
          const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          //console.log(data);
          setUserDepartmentId(data.newDepartmentId)
        }
  
      } catch (error) {
        //console.log(error);
      }
    }
  
  
    const GetDiscrepancyRequest = async () => {
      try {
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/DiscrepancyRequest/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        sortLevelOrder(data);
  
        //console.log("Levels", data);
      } catch (error) {
        //console.log(error);
      }
    };
    const acceptForm = async () => {
      try {
        const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/AcceptForm/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log(data);
        GetDiscrepancyRequest();
  
      } catch (error) {
        //console.log(error);
      }
    };
  
    const RejectForm = async () => {
      try {
        const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/RejectForm/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log(data);
        GetDiscrepancyRequest();
  
      } catch (error) {
        //console.log(error);
      }
    };
  
    const ReturnFormToUserCreated = async () => {
      if (comment) {
        //console.log(id);
        setdisMiss(true)
        try {
          const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/ReturnFormToUserCreated/${id}?comment=${comment}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          setComment("")
          window.location.reload();
          //console.log(data);
          GetDiscrepancyRequest();
        } catch (error) {
          //console.log(error);
        }
        finally {
          setdisMiss(false)
  
        }
      }
    };
  
  
    const ReturnFormToDepartment = async () => {
      if (comment && departmentId) {
        setdisMiss(true)
        try {
          const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/ReturnFormToDepartment/${id}/${departmentId}?comment=${comment}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          window.location.reload();
          setComment("")
          //console.log(data);
          GetDiscrepancyRequest();
        } catch (error) {
          //console.log(error);
        }
        finally {
          setdisMiss(false)
  
        }
      }
    };
  
  
  
    useEffect(() => {
      if (user) {
        GetDiscrepancyRequest();
      }
    }, [user]);
  
    useEffect(() => {
      if (levels.length > 0) {
        //console.log(levels);
        var num = 1000000;
        levels.filter((level) => {
          if (
            !level.isAccepted &&
            !level.isRejected &&
            !level.isRetunedToCreatedUser &&
            !level.isRetunedToDepartment &&
            level.levelOrder < num
          ) {
            num = level.levelOrder;
            setCurrLevel(level.levelOrder);
            //console.log(level.levelOrder);
          }
        });
      }
    }, [levels]);
  
    useEffect(() => {
      //console.log(currLevel);
    }, [currLevel]);
  
    const sortLevelOrder = (arr) => {
      const sortedLevels = [...arr].sort((a, b) => a.levelOrder - b.levelOrder);
      setLevels(sortedLevels);
    };
    const getStatus = (level) => {
      if (level.isAccepted) {
        return "Accepted";
      } else if (level.isRejected) {
        if (!rejected) {
          setRejected(true);
        }
        return "Rejected";
      } else if (level.isRetunedToCreatedUser) {
        return "Returned to created user";
      } 
      else if (level.isRetunedToDepartment) {
        return "Returned to created department";
      }
      else {
        return "Waiting admin confirm";
      }
    };
  
    const [selectedOption, setSelectedOption] = useState('user'); // Default option
  
    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };
    const handleDepartmentChange = (event) => {
      const selectedDepartmentId = event.target.value;
      setDepartmentId(selectedDepartmentId);
    }
    const getDepartments = async (event) => {
      try {
        const hotelId = event.target.value
        setHotelId(hotelId)
        const { data } = await axios.get(
          `${BASE_URL}/api/Departments/GetAllDepartmentsByHotelId/${hotelId}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setDepartments(data);
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      async function getHotels() {
        try {
          const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          });
          // //console.log(data);
          setHotels(data);
        } catch (error) {
          console.error(error);
        }
      }
  
      if (user) {
        getHotels();
      }
    }, [user]);
  
    const handelReturn = () => {
      if (selectedOption === 'department') {
        ReturnFormToDepartment()
      } else {
        ReturnFormToUserCreated()
      }
    }
    return (
      <>
        <div className="row justify-content-center mt-5">
          {levels.map((level, index) => (
            <div key={index} className="col-md-3 g-5">
              <div className="item bg-white text-center ">
                <div>
  
                  <div className={`${Style.card} d-flex justify-content-center py-3 bg-dark`}>
                    {/* <div className={`${Style.mail} me-2 p-3 bg-white rounded-circle mail`}><i className="fa-solid fa-envelope"></i></div> */}
                    <h6 className="text-white">{level.departmentName}</h6>
                  </div>
                </div>
  
                {level.levelOrder == currLevel && (
                  <div>
              <div className="p-4"></div>
                  </div>
                )}
                {(level.levelOrder < currLevel || currLevel == 0) && (
                  <div className="p-3">
                    <h6>{level.signedName}</h6>
                    {getStatus(level) === "Accepted"&&
                       <p>  <img className="w-50" src={AcceptedStatus} alt="Accepted" /></p>
                    }
                 
                 {getStatus(level) === "Rejected"&&
                       <p>  <img className="w-50" src={RejectedStatus} alt="Accepted" /></p>
                    }
                    
   {(getStatus(level) === "Returned to created department"||getStatus(level) === "Returned to created user")&&
                       <p>{getStatus(level)}</p>
                    }  
                     {(getStatus(level) === "Waiting admin confirm")&&
                       <p>{getStatus(level)}</p>
                    }             
                    <p>{level?.signedOn?.substring(0, 10)} {level?.signedOn?.substring(11, 16)}</p>
                  </div>
                )}
                {level.levelOrder > currLevel && <div className="p-4"></div>}
              </div>
  
              {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="staticBackdropLabel">Comment</h1>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="row mb-2 px-3">
  
                        <div className="form-check col-md-6 ">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="user"
                            checked={selectedOption === 'user'}
                            onChange={handleChange}
                            id="userRadio"
                          />
                          <label className="form-check-label" htmlFor="userRadio">
                            User
                          </label>
                        </div>
                        <div className="form-check col-md-6">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="department"
                            checked={selectedOption === 'department'}
                            onChange={handleChange}
                            id="departmentRadio"
                          />
                          <label className="form-check-label" htmlFor="departmentRadio">
                            Department
                          </label>
                        </div>
                      </div>
  
                      {selectedOption === 'department' && (
  
                        <div className='row mb-3 '>
                          <div className="col-md-6">
                            <div className="item bg-white text-center ">
                              <select value={hotelId} onChange={getDepartments} className="form-select border-0 cursor-pointer" aria-label="Default select example">
                                <option value="" disabled>
                                  Select hotel
                                </option>
                                {hotels.map((hotel) => (
                                  <option key={hotel.id} value={hotel.id} className='m-5'>{hotel.name}</option>
                                ))}
  
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="item bg-white text-center">
                              <select value={departmentId} onChange={handleDepartmentChange} disabled={departments.length == 0} className="form-select border-0 cursor-pointer" aria-label="Default select example">
                                <option value="" disabled>
                                  Select department
                                </option>
  
  
                                {departments.map((department) => (
  
                                  <option key={department.id} value={department.id} className="m-5">
                                    {department.name}
                                  </option>
  
  
  
                                ))}
  
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
  
  
                      <div>
                        <textarea className="form2 w-100" type="area" id="Comment" value={comment} onChange={(e) => setComment(e.target.value)}  placeholder="Comment" >
                        </textarea>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handelReturn} disabled={disMiss} >{disMiss ? 'Send...' : 'Send'}</button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </>
    );
}