import React, { useState } from 'react';
import AllForms from './all-forms';
import { CreateForm } from './create-form';
import { AddFormLevel } from '../formLevel/addFormLevel';
import FormPermission from './form-permission';
export const Forms = () => {
  const [showForms, setShowForms] = useState("AllForms");
  const toggleForms = (value) => {
    setShowForms(value); 
  };

   return (
    <div className="">
 <div className="container mx-auto ">
 </div>
    
{showForms=="AllForms" && 
<AllForms/>
}

{showForms=="Createform" && 
<CreateForm />
}
{showForms=="Addlevel" && 
<AddFormLevel/>
}
{showForms=="Formlevels" && 
<FormPermission/>
}

    </div>

  );
}
