import React, { useState } from 'react';
import AllHotels from "../Hotel/hotel";
import AllformNames from "../FormsName/form-name";
import AllDropdowns from "../Dropdowns/allDropdowns";
import AllTextNames from "../TextNames/all-text-names";

export const FormDetails = () => {
  const [activeTab, setActiveTab] = useState("Hotels");

  const renderContent = () => {
    switch (activeTab) {
      case "Hotels":
        return <AllHotels />;
      case "Forms Names":
        return <AllformNames />;
      case "Dropdowns":
        return <AllDropdowns />;
      case "Text Names":
        return <AllTextNames />;
      default:
        return <AllHotels />;
    }
  };

  return (
    <div className="pt-5">
<div className="container">
  <ul className="nav nav-tabs justify-content-center mb-4 row">
    {["Hotels", "Forms Names", "Dropdowns", "Text Names"].map((tab) => (
      <li className="nav-item col-6 col-md-3 text-center custom-font-size" key={tab}>
        <a
          className={`custom-font-size nav-link ${activeTab === tab ? 'active' : ''}`}
          onClick={() => setActiveTab(tab)}
          role="button"
        >
          {tab}
        </a>
      </li>
    ))}
  </ul>

  <div className="content">
    {renderContent()}
  </div>
</div>

    </div>
  );
};
