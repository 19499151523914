import React, { useState } from 'react';
import './DragAndDrop.css';

export default function DragAndDrop({ onFileSelected }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('Drag and drop a file');

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file ? file.name : 'Drag and drop a file');
    if (file) {
      onFileSelected(file);
    }
  };

  // Function to handle file clearing
  const handleClearFile = () => {
    setSelectedFile(null);
    setFileName('Drag and drop a file');
    onFileSelected(null); // Inform parent about clearing the file
  };

  return (
    <div className="form mt-3 header">
      <div className="form-group inputDnD">
        <input
          type="file"
          className="form-control-file text-primary font-weight-bold"
          name="inputFile"
          id="inputFile"
          onChange={handleFileChange}
          data-title={fileName}
        />
      </div>
      <div className="btn btn-dark text-white btn-file position-relative">
        <i className="fa-solid fa-folder-open" />
        Browse...
        <input
          id="offers"
          name="upload"
          type="file"
          onChange={handleFileChange}
          className="browse-file"
          data-show-upload="false"
          data-show-caption="true"
        />
      </div>
      {selectedFile && (
        <div>
          Selected File: {selectedFile.name} &nbsp;
          <button className="btn btn-dark" onClick={handleClearFile}>Clear</button>
        </div>
      )}
    </div>
  );
}
