import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from "../../config";
export const CreateHotel = () => {
  const { id } = useParams()
  const [user, setUser] = useState("");
  const navigate = useNavigate()
const [initialValues, setInitialValues] = useState({
   hotelName : ''
})

const [loading, setLoading] = useState(true);
const [formHeader, setFormHeader] = useState(''); 

useEffect(()=>{
   const token = JSON.parse(localStorage.getItem("user"));
   setUser(token);
},[])
useEffect(()=>{
  if (id) {
    setFormHeader('Update Hotel')
  }else{
    setFormHeader('Create Hotel')
  }
},[])

useEffect(()=>{
  async function GetHotelById(){
  try{
    if(id){
      setFormHeader('Update Hotel')

      const {data} = await axios.get(`${BASE_URL}/api/Hotels/GetHotelById/${id}`,
      {
        headers: {
            Authorization: `Bearer ${user}`,
        }
    }
    )
      //console.log(data);
      setInitialValues({
          hotelName:data.name
        })
      }
    }catch(err){
      console.error('Error fetching hotel:', err);
    }finally {
      setLoading(false); 
    }
  }
  GetHotelById()
},[id])

  async function createHotel(values, { setSubmitting }) {
    try {
  if (user) {
    
    //console.log(values);
if (id) {
 
const { data } = await axios.put(
  `${BASE_URL}/api/Hotels/UpdateHotel/${id}`,
  values,
  {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  });
//console.log( 'update' ,data);
navigate(`/dashboard/hotels`)
} else {
 //console.log(values);
const { data } = await axios.post(
  `${BASE_URL}/api/Hotels/AddHotel`,
  values,
  {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  }
  ,
  {
    'Content-Type': 'application/json', // Add Content-Type header
  }
  );

//console.log( 'AddProduct' ,data);
navigate('/dashboard/hotels')
  }
}
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    hotelName: Yup.string().required("hotel name is required"),
  });

  return (
    <section className="p-5">
      <h2 className="text-center text-cap">{formHeader}</h2>
      {!loading && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createHotel}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="hotelName" className="form-label">
                Hotel Name:
              </label>
              <Field
                type="text"
                name="hotelName"
                id="hotelName"
                className={`form-control ${
                  errors.hotelName && touched.hotelName && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="hotelName"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <button type="submit" className="btn btn-primary"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      )}
    </section>
  );
};
