import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from "../../config";
export const CreateFormName = () => {
  const { id } = useParams()
  const [user, setUser] = useState("");
  const navigate = useNavigate()
const [initialValues, setInitialValues] = useState({
   formName : ''
})

const [loading, setLoading] = useState(true);
const [formHeader, setFormHeader] = useState(''); 

useEffect(()=>{
   const token = JSON.parse(localStorage.getItem("user"));
   setUser(token);
},[])
useEffect(()=>{
  if (id) {
    setFormHeader('Update Form Name')
  }else{
    setFormHeader('Create Form Name')
  }
},[])

useEffect(()=>{
 
  async function GetFormNameById(){
  try{
    if(id){
      setFormHeader('Update Form Name')

      const {data} = await axios.get(`${BASE_URL}/api/FormNames/GetFormNameById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
      )
      //console.log(data);
      setInitialValues({
          formName:data.name
        })
      }
    }catch(err){
      console.error('Error fetching form name:', err);
    }finally {
      setLoading(false); 
    }
  }
  if (user) {
    GetFormNameById() 
    }
},[id,user])

  async function createFormName(values, { setSubmitting }) {
    try {
      //console.log(values);
  if (user?.length>0) {
    
    if (id ) {
      const { data } = await axios.put(
        `${BASE_URL}/api/FormNames/EditFormName/${id}?formName=${values.formName}`,
      {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          }
        });
      //console.log( 'update' ,data);
     navigate(`/dashboard/form-names`)
    } else  {
       //console.log(user);
      const { data } = await axios.post(`${BASE_URL}/api/FormNames/CreateFormName?formName=${values.formName}`,        
      {}, 
      {
          headers: {
            Authorization: `Bearer ${user}`,
          }
        }        
        );
    
      //console.log( 'Add : ' ,data);
      navigate('/dashboard/form-names')
    }
  }    
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    formName: Yup.string().required("Form name is required"),
  });

  return (
    <section className="p-5">
      <div className="">
      <h2 className="text-center text-cap">{formHeader}</h2>
      {!loading && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createFormName}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <Field
                type="text"
                name="formName"
                id="formName"
                className={`form-control mt-4 ${
                  errors.formName && touched.formName && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="formName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <button type="submit" className="btn btn-dark"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      )}
      </div>

    </section>
  );
};