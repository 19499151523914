import React, { useEffect, useState } from 'react'
import Path from '../../Path/Path';
import { BASE_URL } from '../../config';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Loading } from '../../Loading/loading';
import checklist from'../../../assets/imgs/checklist.png';
import allforms from'../../../assets/imgs/allforms.png';

export const DefaultDashboard = (props) => {
    const [path1, setPath] = useState('Forms');
    const [user, setUser] = useState("");
    const [data, setData] = useState("");
    const [userData, setUserData] = useState("");
    const [forms, setForms] = useState([]);
    const [currentForms, setCurrentForms] = useState([]);
    const [groupedForms, setGroupedForms] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const [page, setPage] = useState(1);
    const [pageSize,setPageSize] = useState(5);
    const totalPages = Math.ceil(currentForms.length / pageSize);

    const [paginatedForms,setPaginatedForms] = useState([]);
    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("userData")));
        const token = JSON.parse(localStorage.getItem("user"));
        setUser(token);
    }, [])
    async function getAllForms() {
        const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetUserFormsData`,
            {
                headers: {
                    Authorization: `Bearer ${user}`,
                },
            }
        );
        // console.log("GetAllHotels", data);
        setData(data);

    }

    useEffect(() => {

        async function getForms() {
            try {
                setLoading(true)
                const { data } = await axios.get(
                    `${BASE_URL}/api/RegisterationForms/GetUserFormsToSign/${userData.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user}`,
                        },
                    }
                );
                //console.log('data', data);
                setForms(data);
                setGroupedForms(
                    data?.reduce((result, form) => {
                        if (!result[form.formName]) {
                            result[form.formName] = [];
                        }
                        result[form.formName].push(form);
                        return result;
                    }, {})
                );


            } catch (error) {
                console.log(error);
                if (error.response.data == "no forms to sign related to this user") {
                    setLoading(false)
                }
            }
            finally {
                setLoading(false)
            }
        }
        if (user && userData) {
            //console.log("555555555", userData);
            getForms(userData.id);
        }

    }, [user, userData]);

    const getCurrentForms = (name) => {
        const filtered=forms.filter(form => form.formName == name)
        setCurrentForms(filtered)
        setPaginatedForms(filtered?.slice((page - 1) * pageSize, page * pageSize))   
        setPage(1)
    }
    useEffect(() => {
        if (user) {
            getAllForms()
        }
    }, [user])





useEffect(()=>{
 setPaginatedForms(currentForms?.slice((page - 1) * pageSize, page * pageSize))   
},[currentForms,page,pageSize])

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };
    const rowCount=(e)=>{        
        setPageSize(Number(e.target.value));
          setPage(1);
      }
    const getPaginationItems = () => {
        const paginationItems = [];
        for (let i = 1; i <= totalPages; i++) {
            paginationItems.push(
                <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }
        return paginationItems;
    };



    if (isLoading) {
        <Loading />
    }
    else {
        return <>
            <div className={`${props.isOpenSidebar ? 'content-body' : 'big-content-body'}`}>
                <div className="container">
                    <div className="row g-3">
                        {/* <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <h5>Total Forms</h5>
                            </div>
                            <div className="card-body">
                                <span className="fs-3">{data.totalForms}</span>
                            </div>
                        </div>
                    </div> */}
                        <div className="col-md-4">
                            <div className="card card-style shadow-none cursor-pointer">
                                {/* <div className="card-header">
            <h5>Received Forms</h5>
        </div> */}
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <img src={allforms}style={{ width: '40px' }}/>    
                        
                                        </div>
                                    <div className="text-end">
                                        <h6 className='fw-bold'>Total Forms</h6>
                                        <span className="fs-3">{data.receivedForms}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <Link to="/my-forms" className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <h5>Send Forms</h5>
                            </div>
                            <div className="card-body">
                                <span className="fs-3">{data.sendForms}</span>
                            </div>
                        </div>
                    </Link> */}


                        {Object.entries(groupedForms).map(([name, group], index) =>
                        (
                            <div key={index} className="col-md-4">
                                <div className="card card-style shadow-none cursor-pointer">

                                    <div className="card-body d-flex align-items-center justify-content-between"
                                        onClick={() => getCurrentForms(name)}
                                    >
                                        <div className="d-flex align-items-center ">
                                            <img src={checklist} style={{ width: '40px' }}/>    
                                         </div>
                                        <div className="text-end w-75">
                                            <h6 className='fw-bold'>{name}</h6>
                                            <span className="fs-3">{group?.length}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        )}

                    </div>


                    <div className='card shadow-none w-100 my-5'>
                        <div className='card-header text-start'>
                            <h5>Please sign this</h5>
                        </div>
                        {currentForms?.length == 0 &&
                            <div className='py-2'>
                                <p className='text-primary mb-0'>Dear sir you have forms to sign</p>
                                <p className='text-danger mb-1'>Please select from the above</p>
                            </div>
                        }
                        {currentForms?.length > 0 &&
                            <div className="table-responsive">
                             <div className="col-1 col-sm-2 col-me3 mt-1">
                          <div
                            className="dataTables_length "
                            id="allowance-list-table_length"
                          >
                            <label>
                              <select className="form-control" value={pageSize} onChange={(event)=>rowCount(event)}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                              </select>
                            </label>
                          </div>
                        </div>    
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Department</th>
                                        <th>Created By</th>
                                        <th>Created On</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedForms.map((form, index) => (
                                        <tr key={form.formId}>
                                            <td>{(page - 1) * pageSize + index + 1}</td>
                                            <td>{form?.formName} #{form?.formId}</td>
                                            <td>{form?.departmentThatSentForm}</td>
                                            <td>{form?.createdBy}</td>
                                            <td>{form.createdAt.substring(0, 10)} {form.createdAt.substring(11, 16)}</td>
                                            <td>
                                                <Link to={`/form-sign/${form.formId}`} className="btn btn-outline-dark ">Sign</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-center">
                                <nav>
                                    <ul className="pagination">
                                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(page - 1)}>Previous</button>
                                        </li>
                                        {getPaginationItems()}
                                        <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                                            <button className="page-link" onClick={() => handlePageChange(page + 1)}>Next</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                                <div className="d-flex justify-content-start mb-3 ms-2">
                                    showing {pageSize*page-pageSize+1} to {currentForms?.length-(pageSize*page-pageSize)>pageSize?pageSize*page:currentForms?.length} of {currentForms?.length}
                                </div>
                        </div>
                        }


                    </div>
                </div>
            </div>
        </>
    }
}