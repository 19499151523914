import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { Loading } from "../../Loading/loading";

export default function AllTextNames() {
  const baseUrl = BASE_URL;
  const [user, setUser] = useState("");
  const [textNames, setTextNames] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);

    async function getTextNames() {
      try {
        setLoading(true);
        const { data } = await axios.get(`${baseUrl}/api/FormNames/GetAllTypeString`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const sortedTextNames = [...data].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        setTextNames(sortedTextNames);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      getTextNames();
    }
  }, [user]);

  const deleteTextName = async (id) => {
    try {
      await axios.delete(`${baseUrl}/api/FormNames/DeleteTypeString/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      setTextNames((prev) => prev.filter((textName) => textName.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading && !textNames.length) {
    return <Loading />;
  }

  return (
    <div className="container py-4">
      <div className="row justify-content-between align-items-center mb-3">
        <div className="col-12 col-md-6 mb-3 mb-md-0">
          <h4 className="fw-bold text-secondary text-center text-md-start">
            Text Names
          </h4>
        </div>
        <div className="col-12 col-md-6 text-center text-md-end">
          <Link to="/dashboard/create-text-name" className="btn btn-outline-primary">
            Create Text
          </Link>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {textNames.map((textName, index) => (
              <tr key={textName.id}>
                <td>{index + 1}</td>
                <td>{textName.name}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row gap-2">
                    <Link
                      to={`/dashboard/create-text-name/${textName.id}`}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Update
                    </Link>
                    <button
                      onClick={() => deleteTextName(textName.id)}
                      className="btn btn-outline-danger btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
