import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";

export const Layout = () => {
  const { setUser, setUserRole, user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("userData");
    const storedUserRole = localStorage.getItem("userRole");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setUserRole(JSON.parse(storedUserRole));
    } else {
      navigate("/login");
      return;
    }
    if (user && user.isActive == "True") {
      if (window.location.pathname === "/" || window.location.pathname.includes("login")) {
        navigate("/dashboard");
        return;
      }
    } else {
      navigate("/terms-and-conditions");
      return;
    }
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};
