import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from "../config";
export const DepartmentAdmin = () => {
//   const { id } = useParams()
//   const navigate = useNavigate()
  const [user, setUser] = useState("");
  const [Hotels,setHotels]=useState([]);
  const [departments,setDepartments]=useState([]);
  const [admins,setAdmins]=useState([]);
  const [formHeader,setFormHeader]=useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");

const [initialValues, setInitialValues] = useState({
  hotelId :"",
  departmentId: "", 
  adminId: "",   
})
useEffect(()=>{
  const token = JSON.parse(localStorage.getItem("user"));
  setUser(token);
  setFormHeader('Asign New Admin')
},[])


async function fetchAdminsDetails(){
try{
  if (user) {
     
    const {data} = await axios.get(`${BASE_URL}/api/Auth/GetAllUsers`,
    {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }
    )
    //console.log("Admins",data);
    setAdmins(data)
  }
  }catch(err){
    console.error('Error fetching Hotels:', err);
  }finally {
  
  }
}


async function fetchHotelsDetails(){
    try{
      if (user) {
        const {data} = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
        )
        //console.log("Hotels",data);
       setHotels(data)
      }
      }catch(err){
        console.error('Error fetching Hotels:', err);
      }finally {
       // setLoading(false); // Update loading state
      }
    }

    const getDepartments = async (hotelId) => {
        try {
          const { data } = await axios.get(
            `${BASE_URL}/api/Departments/GetAllDepartmentsByHotelId/${hotelId}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          setDepartments(data);
        } catch (error) {
          console.error(error);
        }
      };

useEffect(()=>{
if (user) {
  fetchHotelsDetails()
  fetchAdminsDetails()
}
},[user])



async function createDepartmentAdmin(values, { setSubmitting,resetForm  }) {
  
    try {


     if(user){
  //console.log(values);    
    const data  = await axios.put(
      `${BASE_URL}/api/Reports/UpdateDepartmentAdmin/${values.hotelId}/${values.departmentId}/${values.adminId}`,
      {}, {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              }
            );
     
                if (data) {
                    setDepartments([])                    
                    initialValues.hotelId=""
                    resetForm();
                    setSuccessMessage("User asign successfully!");
                    setErrMessage("");
                  }
  
      }

    } catch (error) {
        setSuccessMessage("");
        setErrMessage("Faild to asign")
      console.log(error);
    }finally{
      setSubmitting(false)
    }
}

const handleHotelChange = (e, setFieldValue) => {
    const selectedHotelId = e.target.value;
    setFieldValue("hotelId", parseInt(selectedHotelId));
    initialValues.hotelId=selectedHotelId
    getDepartments(parseInt(selectedHotelId));
  };

  const validationSchema = Yup.object().shape({
    departmentId:Yup.number().required("Department is required"),
    hotelId: Yup.number().required("Hotel is required"),
    adminId: Yup.string().required("Admin is required"),
  });

  return (
    <section class="content d-flex justify-content-center">
      {/* <h2 className="text-center text-cap mb-3">{formHeader}</h2> */}
      
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm })=>createDepartmentAdmin(values, { setSubmitting,resetForm  })}
      >
        {({ errors, touched, isSubmitting ,setFieldValue }) => (
  
       <Form className="bg-body p-5 shadow-none" >    
            <div className="mb-3">
            <div className="row align-items-center ">
              <div className="col-md-2" >
              <label htmlFor="hotelId" className="form-label">
                Hotels:
              </label>
              </div>
              <div className="col-md-10">

              <Field
                as="select"
                name="hotelId"
                id="hotelId"
                onChange={(e) => handleHotelChange(e, setFieldValue)}
               className={`form-control ${
                  errors.hotelId && touched.hotelId && "is-invalid"
                }`}
              >
               <option value="" disabled>
                  Select Hotel
                </option>
                {Hotels.map((hotel) => (
                  <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
                ))}
              </Field>
              <ErrorMessage
                name="hotelId"
                component="div"
                className="invalid-feedback"
              />
              </div>
           </div>
            </div>
  <div className="mb-3">
  <div className="row align-items-center">
    <div className="col-md-2">
      <label htmlFor="departmentId" className="form-label ">
        Departments:
      </label>
    </div>
    <div className="col-md-10">
      <Field
        as="select"
        name="departmentId"
        id="departmentId"
        disabled={initialValues.hotelId === ""}
        className={`form-control ${
          errors.departmentId && touched.departmentId && "is-invalid"
        }`}
      >
        <option value="" disabled>
          Select Department
        </option>
        {departments?.map((department) => (
          <option key={department.id} value={department.id}>{department.name}</option>
        ))}
      </Field>
      <ErrorMessage
        name="departmentId"
        component="div"
        className="invalid-feedback"
      />
    </div>
  </div>
</div>

            <div className="mb-3">
            <div className="row align-items-center">
              <div className="col-md-2">              
              <label htmlFor="hotel" className="form-label">
                Admins:
              </label>
             </div>
             <div className="col-md-10">

              <Field
              
                as="select"
                name="adminId"
                id="adminId"
                className={`form-control ${
                  errors.adminId && touched.adminId && "is-invalid"
                }`}
              >
               <option value="" disabled>
                  Select Admin
                </option>
                {admins.map((admin) => (
                  <option key={admin.id}  value={admin.id}>{admin.fullName}</option>
                ))}
              </Field>
              <ErrorMessage
                name="adminId"
                component="div"
                className="invalid-feedback"
              />
             </div>
              </div>
            </div>
            {errMessage && (
                  <div className="alert alert-danger mt-5 text-center fw-bold">
                    {errMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success mt-5 text-center fw-bold">
                    {successMessage}
                  </div>
                )}
                <div className="text-center">
            <button type="submit" className="btn btn-dark px-5 py-1 fs-5"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
                </div>
          </Form>
        )}
      </Formik>
      
    </section>
  );
};
