import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config";
import { Loading } from "../../Loading/loading";
export default function AllFormPermission() {
  const [user, setUser] = useState("");
  const [allForms, setAllForms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
}, []);
  useEffect(() => {
    async function getForms() {
      try {
        setLoading(true)
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetFormLevels`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setAllForms(data);
        //console.log("data", data);
      } catch (error) {
        console.log(error);
      }
      finally {
        setLoading(false)
      }
    }
    if (user) {

      getForms();
    }

  }, [user]);

  if (!allForms.length && loading) {
    return <Loading />
  } else {
    return <>
      <div className="container">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Departments</th>
              
              </tr>
            </thead>
            <tbody>
              {allForms?.map((form, index) => (
                <tr key={form.id} >
                    <td>{index + 1}</td>
                  <td>{form.items[0]?.formName}</td>
                  <td>
                 {form?.items?.map((item) => (   
                    <div>
                     {item.levelOrder}-{item.departmentName}
                    </div>
                ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  }

}
