import React, { useEffect, useState } from "react";
import axios from "axios";
import {BASE_URL} from "../config";
import { Link } from "react-router-dom";
import { Loading } from "../Loading/loading";
import signStyle from './form-to-sign.module.css'
export default function UnSignForms() {
   const baseUrl = BASE_URL
  const [user, setUser] = useState("");
  const [userData, setUserData] = useState("");
  const [forms, setForms] = useState([]);
  const [errorMessage, setErrorMessage]= useState('')
  const [isLoading, setLoading]= useState(false)

  useEffect(() => {
    
    setUser(JSON.parse(localStorage.getItem("user")));
    setUserData(JSON.parse(localStorage.getItem("userData")));
    
  }, []);
  
  useEffect(() => {
    
      async function getForms() {
        try {
         setLoading(true)
            const { data } = await axios.get(
              `${baseUrl}/api/RegisterationForms/GetUserFormsToSign/${userData.id}`,
              {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              }
            );
            //console.log('data' ,data);
            setForms(data);        
          

        } catch (error) {
          console.log(error);
          if(error.response.data=="no forms to sign related to this user"){
            setLoading(false)
          }
        }
        finally{
          setLoading(false)
        }
      }
      if (user&&userData) {
        //console.log("555555555",userData);
        getForms(userData.id);
      }
    
  }, [user,userData]);
if (isLoading) {
  <Loading/>
}
else{
  return <>
     <div className="container ">
        <div className={`${signStyle.tableResponsive} overflow-auto overflow-hidden`}>
        <h4 className="mt-5 mb-4 text-center fw-bold"></h4>
{forms.length>0?(
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Created By</th>
                        <th>Created On</th>
                
                
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {forms?.map((form,index)=>(
                    <tr  key={index} >
                        <td>{index+1}</td>
                        <td>{form?.formName} #{form?.formId}</td>
                        <td>{form?.departmentThatSentForm}</td>
                        <td>{form?.createdBy}</td>
                        <td> {form.createdAt.substring(0, 10)} {form.createdAt.substring(11, 16)}</td>
                     
                        <td><Link to={`/form-sign/${form.formId}`} className="btn btn-primary">Sign</Link></td>
                     
                    </tr>
                    ))}
                </tbody>
            </table>

):(

     <div className="bg-body row p-5 header text-center  " >
              <h4>No forms! </h4>
            </div>
)}


        </div>
    </div> 
    </>
}
}
