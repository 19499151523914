import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import Style from "./view.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../Loading/loading";
import NoDiscrepancyImg from '../../assets/imgs/No Discrepancy.jpeg'
import NoRateChange from '../../assets/imgs/RateChange.jpeg'
import NoSpecial from '../../assets/imgs/Nospecial.jpeg'
import nocheckout from '../../assets/imgs/nocheckout.jpeg'
import nohouse from '../../assets/imgs/nohouse.jpeg'
import noroomchange from '../../assets/imgs/noroomchange.jpeg'
import norooomupgrage from '../../assets/imgs/norooomupgrage.jpeg'
export default function UserFormView({ data }) {
  const [user, setUser] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });
  const [groupCount, setGroupCount] = useState(0);
  const [formGroupHeadShow, setFormGroupHeadShow] = useState([]);
  const [formGroupData, setFormGroupData] = useState([]);
  const [showFiles, setShowFiles] = useState([]);
  const [showForm, setShowForm] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [comments, setComments] = useState([]);
  const [count, setCount] = useState(0);

  const [hide, setHide] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    if (count + 2 == showForm.length) {
      //console.log("I'm here");
      setPairs(getPairs(showForm));
    }
  }, [showForm, count]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);

  useEffect(() => {
    async function getUserFormById() {
      //console.log(data);
      setShowFormName({
        id: data.formId,
        name: data.formName,
      });
      setShowForm(prevArray => [...prevArray, { name: "Hotel", value: data.hotel.hotelName }]);
      setShowForm(prevArray => [...prevArray, { name: "Department", value: data.department.departmentName }]);


      const drop = data.formDropdown.map(({ dropdownName, selectedElement }) => ({
        name: dropdownName,
        value: selectedElement.elementName,
      }));
      setShowForm(prevArray => [...prevArray, ...drop]);


      const string = data.formTypes.map(({ name, value }) => ({
        name,
        value,
      }));
      setShowForm(prevArray => [...prevArray, ...string]);

      const dates = data.formDates.map(({ name, value }) => ({
        name,
        value: formatDate(value),
      }));


      data.formGroups.forEach((group) => {
        let fillgroup = []
        let groupHead = []
        group.formDropdowns.forEach((Dropdown) => {
          const dropdawn = {
            id: Dropdown.dropdownId,
            name: Dropdown.dropdownName,
            options: Dropdown.options,
            value: Dropdown.selectedElement.elementName
          }
          fillgroup.push({ order: Dropdown.order, type: "drop", data: dropdawn })
          groupHead.push({ order: Dropdown.order, name: Dropdown.dropdownName })
        })

        group.groupTypeNames.forEach((text) => {
          const text1 = {
            id: text.id,
            name: text.name,
            value: text.value,
          };
          if (text.name === "Room No") {
            if (data.formName === "Discrepancy" && text.value === "No Discrepancy") {
              setImgSrc(NoDiscrepancyImg)
              setHide(true)
            }
            if (data.formName === "Room Rate Change" && text.value === "No Change") {
              setImgSrc(NoRateChange)
              setHide(true)
            }
            if (data.formName === "Special Rate" && text.value === "No Change") {
              setImgSrc(NoSpecial)
              setHide(true)
            }
            if (data.formName === "Free Room Upgrade" && text.value === "No Change") {
              setImgSrc(norooomupgrage)
              setHide(true)
            }
            if (data.formName === "House Use" && text.value === "No Change") {
              setImgSrc(nohouse)
              setHide(true)
            }
            if (data.formName === "Rate Change" && text.value === "No Change") {
              setImgSrc(noroomchange)
              setHide(true)
            }
          }
          if (text.name === "Travel Agent") {
            if (data.formName === "Free late check out" && text.value === "No Change") {
              setImgSrc(nocheckout)
              setHide(true)
            }
          }
          fillgroup.push({ order: text.order, type: "text", data: text1 });
          groupHead.push({ order: text.order, name: text.name });
        });

        group.groupTypeDates.forEach((date) => {
          const date1 = { id: date.id, name: date.name, value: date.value };
          fillgroup.push({ order: date.order, type: "date", data: date1 });
          groupHead.push({ order: date.order, name: date.name });
        });
        setFormGroupData((prev) => [
          ...prev,
          {
            groupId: group.groupId,
            order: formGroupData.length + 1,
            formGroup: fillgroup,
          },
        ]);
        setGroupCount(data.formGroups.length);
        setFormGroupHeadShow(groupHead);
      });

      setShowForm((prevArray) => [...prevArray, ...dates]);
      setCount(drop.length + string.length + dates.length);
      setComments(data.comments);
    }
    if (data.userFormId && user) {
      getUserFormById();
    }
  }, [data, user]);


  const getUserData = async (comment, userid) => {
    try {
      if (user && userid) {
        const { data } = await axios.get(
          `${BASE_URL}/api/Reports/GetUserById/${userid}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );

        setComments((perv) => [
          ...perv,
          { name: data.username, comment: comment },
        ]);
      }
    } catch (error) {
      comments.push({ name: "", comment: comment });
      return "";
    }
  };


  const getPairs = (array) => {
    const pair = [];
    for (let i = 0; i < count + 2; i += 2) {
      if (array.length % 2 == 1 && array.length - 1 == i) {
        pair.push([array[i], []]);
      } else {
        pair.push([array[i], array[i + 1]]);
      }
    }
    return pair;
  };

  const goTo = async (event, val) => {
    event.preventDefault();
    try {
      const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/CheckOutGingForm/${val}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      if (data) {
        navigate(`/form-view/${val}`);
      }
      //console.log(data);
    } catch (error) {
      //console.log(error);
    }
  }

  useEffect(() => {
    if (formGroupHeadShow.length > 0) {
      //console.log("555555", formGroupData.length);
      const sorted2 = formGroupHeadShow.sort((a, b) => a.order - b.order);
      setFormGroupHeadShow(sorted2)
    }
  }, [formGroupHeadShow])

  useEffect(() => {

    if (formGroupData.length > 0) {
      let arr = []
      formGroupData.forEach((data) => {
        //console.log("555555", data.length);
        const sorted1 = data.formGroup.sort((a, b) => a.order - b.order);
        // 
        arr.push([{ groupId: data.groupId, order: data.order, formGroup: sorted1 }])
        //  setCount3(1);     
      })
    }
  }, [formGroupData])

  useEffect(() => {
    //console.log(imgSrc);
  }, [imgSrc])

  return (
    <>
      <div className="content  p-3 p-md-5 mt-5">
        <table className={`table ${Style.tablecolor}  table-hover`}>
          <thead></thead>
          <tbody>
            {pairs?.map((pair, index) => (
              <tr className="border-2 " key={index}>
                {pair.map((item, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <td className={Style.tablebg} ><strong>{item?.name || ""}</strong></td>
                    <td>
                      {data.formName == "Out Delivery" && item?.name == "Out Going NO" ?
                        <Link onClick={(event) => goTo(event, item?.value)}>
                          Out Going Form {item?.value}
                        </Link>
                        :
                        item?.value
                      }

                    </td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {formGroupData.length > 0 && (
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="table-responsive">
                <div className="text-center h5 fs-4"> </div>
                <table
                  id="selected-Items-list-table"
                  className="table  table-bordered  text-center"
                >
                  <thead>
                    <tr>
                      {formGroupHeadShow.map((item, index) => (
                        <th className="border-2" key={`${index}h`}><strong>{item.name}</strong></th>
                      ))}
                    </tr>
                  </thead>
                  <tbody id="outgoing-data">
                    {
                      !hide &&
                      formGroupData.map((inputshow, index) => (
                        <tr key={index}>
                          {inputshow.formGroup?.map((item, inpIndex) => (
                            <td className="border-2" key={`ii${inpIndex}`}>
                              {item.type != "date"
                                ? item.data?.value
                                : formatDate(item.data?.value)}
                            </td>
                          ))}

                        </tr>
                      ))
                    }
                    {
                      hide &&
                      <tr>
                        <td colSpan={formGroupHeadShow?.length}>
                          <img className="w-50" src={imgSrc} alt="kk" />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {data?.formFiles?.length > 0 && (
          <div className="mt-3 text-center ">
            <h5>Attachment</h5>.
            <Link
              className="fs-3"
              to={`${BASE_URL}/${data.formFiles[0].url}`}
              target="_blank"
            >
              <i className="fa-regular fa-file"></i> File
            </Link>
          </div>
        )}

        {comments?.length > 0 && (
          <div>
            <div className="text-center h5 fs-4 mt-5 mb-4"> Comments</div>
            <table className="table  table-bordered  text-center" >
              <thead>
                <tr>
                  <th className="border-2 text-center"> # </th>
                  <th className="border-2 text-center"> Department </th>
                  <th className="border-2 text-center"> user </th>
                  <th className="border-2 text-center"> Reason&Remarks </th>
                  <th className="border-2 text-center"> Comment </th>
                  <th className="border-2 text-center"> CommentedAt </th>
                  <th className=" border-2 text-center"> Attachment </th>
                </tr>
              </thead>
              <tbody>
                {comments?.map((comment, index) => (
                  <tr className="border-2" key={index}>
                    <td> {index + 1} </td>
                    <td className={`border-2 `}>{comment.department}</td>
                    <td className={`border-2 `}>{comment.user}</td>
                    <td className={`border-2 `}>{comment.reasonRemarks}</td>
                    <td className={`border-2 `}>{comment.comment}</td>
                    <td className={`border-2 `}>{comment.createdOn.substring(0,10)}  {comment.createdOn.substring(11,16)}</td>
                    <td>
                     {
             comment.fileUrl&&
                      <Link
             
                        to={`${BASE_URL}/${comment.fileUrl}`}
                        target="_blank"
                      >
                       <i className="fa-solid fa-paperclip"></i> {comment.fileName?comment.fileName:"File Attach"}
                      </Link>
                     }
                     </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );

}
function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month starts from 0
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}
