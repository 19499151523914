import axios, { toFormData } from "axios";
import { json, useParams } from "react-router-dom";
import { BASE_URL, GlobalFormId } from "../../config";
import { useContext, useEffect, useState } from "react";
import DragAndDrop from "../../DragAndDrop/DragAndDrop";
import { Formik, Form, Field } from "formik";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../context/userContext";
import { Loading } from "../../Loading/loading";
export const FillForm = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState("");
  const [addedGroup, setAddedGroup] = useState(null);
  const [errMessage, setErrMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  const [loadingComp, setloadingComp] = useState(false)
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(0)
  const [groupCount, setGroupCount] = useState(0)
  const [formGroupHeadShow, setFormGroupHeadShow] = useState([])
  const [formGroupData, setFormGroupData] = useState([])
  const [formId, setFormId] = useState("");
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });
  const [showGroup, setShowGroup] = useState(true);
  const [globalVariable,setglobalVariable ] = useState(JSON.parse(localStorage.getItem("formId")));
  const [showInput, setShowInput] = useState([]);
  const [showDateName, setShowDateName] = useState([]);
  const [showFormsStrings, setShowStrings] = useState([]);
  const [showFormsDropdowns, setShowDropdowns] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedInsertData, setSelectedInsertData] = useState([]);
  const [file, setFile] = useState(null);
  // values to send to back
  const [values, setValues] = useState({
    selectedInsertHotel: 0,
    selectedInsertDepartment: 0,
    selectedInsertData: [],
    selectedInsertTypeDates: [],
    selectedInsertTypeDropdowns: [],
    selectedInsertTypeFiles: [],
    seletedInsertTypeGroups: []
  });


  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, [])
  async function getAllDepartments() {
    const { data } = await axios.get(`${BASE_URL}/api/Departments/GetAllDepartments`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    setDepartments(data);
    ////console.log("GetAllDepartments", data);
  }
  async function getAllHotels() {
    const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    ////console.log("GetAllHotels", data);
    setHotels(data);
  }

  useEffect(() => {
    if (user) {
      getAllDepartments();
      getAllHotels();
    }

  }, [user]);

  useEffect(() => {
    //console.log(showInput);

    if (showInput.length > 0 && count1 == 0) {

      setCount1(1)
      const sorted = [...showInput].sort((a, b) => a.order - b.order);
      setShowInput(sorted);
      //console.log(showInput);
    }
  }, [showInput])

  useEffect(() => {

    //console.log("groupdata", formGroupData);

  }, [formGroupData])

  useEffect(() => {
    if (formGroupHeadShow.length > 0) {
      //console.log("555555", formGroupHeadShow.length);
      const sorted2 = formGroupHeadShow.sort((a, b) => a.order - b.order);
      setFormGroupHeadShow(sorted2)

    }
  }, [formGroupHeadShow])


  useEffect(() => {
    //console.log(count2);

    if (formGroupData.length > 0 && count2 === formGroupData.length) {
      let arr = []
      formGroupData.forEach((data) => {
        const sorted1 = data.formGroup.sort((a, b) => a.order - b.order);
        arr.push({ groupId: data.groupId, order: data.order, formGroup: sorted1 })
      })
      setFormGroupData(arr)

      setCount2(perv => { return perv + 1 });
    }
  }, [formGroupData])

  useEffect(() => {
    //console.log(formGroupData);

  }, [formGroupData])

  useEffect(() => {
    if (id && user) {
      getAllDepartments();
      getAllHotels();
      async function getUserFormById() {
        try {
          setloadingComp(true)
          const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetUserFormById/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          if (data) {
            setShowInput([])
            setShowFormName({
              id: data.formId,
              name: data.formName,
            });
            //console.log("////", data);

            setFormId(data.userFormId)

            values.selectedInsertTypeDropdowns = data.formDropdown.map(({ dropdownId, selectedElement }) => ({
              id: dropdownId,
              value: selectedElement.elementId,
            }));

            values.selectedInsertData = data.formTypes.map(({ id, value }) => ({
              id,
              value,
            }));

            values.selectedInsertTypeDates = data.formDates.map(({ id, value }) => ({
              id,
              value: formatDate(value),
            }));
            values.selectedInsertHotel = data.hotel.hotelId;
            values.selectedInsertDepartment = data.department.departmentId;
            //console.log("values", values);


            const date = data.formDates.map(({ id, name, value }) => ({
              id,
              name,
              value: formatDate(value)

            }));
            const drop = data.formDropdown.map(({ dropdownId, dropdownName, option, selectedElement }) => ({
              id: dropdownId,
              name: dropdownName,
              options: option,
              value: selectedElement.elementId,
            }));

            data.formDropdown.forEach((Dropdown) => {
              const dropdawn = {
                id: Dropdown.dropdownId,
                name: Dropdown.dropdownName,
                options: Dropdown.option,
                value: Dropdown.selectedElement.elementId,
              }
              setShowInput(prev => [...prev, { order: Dropdown.order, type: "drop", data: dropdawn }])
            })

            data.formTypes.forEach((text) => {

              setShowInput(prev => [...prev, { order: text.order, type: "text", data: text }])
            })

            data.formDates.forEach((date) => {
              setShowInput(prev => [...prev, { order: date.order, type: "date", data: date }])
            })

            //console.log(data.formGroups);
            let alldata = [];
            data.formGroups.forEach((group) => {
              let newRow = []
              let fillgroup = []
              let groupHead = []
              group.formDropdowns.forEach((Dropdown) => {
                const dropdawn = {
                  id: Dropdown.dropdownId,
                  name: Dropdown.dropdownName,
                  options: Dropdown.options,
                  value: Dropdown.selectedElement.elementId
                }
                newRow.push({ order: Dropdown.order, type: "drop", data: { id: Dropdown.dropdownId, name: Dropdown.dropdownName, options: Dropdown.options, value: "" } })
                fillgroup.push({ order: Dropdown.order, type: "drop", data: dropdawn })
                groupHead.push({ order: Dropdown.order, name: Dropdown.dropdownName })
              })

              group.groupTypeNames.forEach((text) => {
                const text1 = {
                  id: text.id,

                  name: text.name,
                  value: text.value
                }
                newRow.push({ order: text.order, type: "text", data: { id: text.id, name: text.name, value: "" } })
                fillgroup.push({ order: text.order, type: "text", data: text1 })
                groupHead.push({ order: text.order, name: text.name })
              })

              group.groupTypeDates.forEach((date) => {
                const date1 = { id: date.id, name: date.name, value: date.value }
                newRow.push({ order: date.order, type: "date", data: { id: date.id, name: date.name, value: "" } })
                fillgroup.push({ order: date.order, type: "date", data: date1 })
                groupHead.push({ order: date.order, name: date.name })
              })

              setAddedGroup(newRow)

              alldata.push({ groupId: group.groupId, order: formGroupData.length != null ? formGroupData.length + 1 : 0, formGroup: fillgroup })
              setGroupCount(data.formGroups.length)
              setFormGroupHeadShow(groupHead)
            })
            setCount2(alldata?.length)
            setFormGroupData(alldata)
            setShowDropdowns(drop)
            setShowStrings(data.formTypes);
            setShowDateName(date);
          }
        } catch (error) {
          console.log(error);
        }
        finally {
          setloadingComp(false)
        }

      }
      //     //console.log(id);
      getUserFormById();
    }
    else if (user && globalVariable != '') {
      setFormId(globalVariable)
      getFormById(globalVariable);

    }
  }, [id, user,globalVariable])

  async function getFormById(formId) {
    try {
      if (user) {
        setloadingComp(true)
        //console.log(formId);
        const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetFormById/${formId}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log("getFormById", data);
        if (data) {
          setShowFormName({
            id: data.id,
            name: data.name,
          });
          // setLoading(false)
          // set inputs value to zero
          values.selectedInsertTypeDropdowns = data.formDropdown.map(({ id }) => ({
            id,
            value: "",
          }));

          values.selectedInsertData = data.formTypes.map(({ id }) => ({
            id,
            value: "",
          }));

          values.selectedInsertTypeDates = data.formDates.map(({ id }) => ({
            id,
            value: "",
          }));

          // //console.log(values.selectedInsertTypeDropdowns);
          const drop = data.formDropdown.map(({ id, name, options }) => ({
            id,
            name,
            options,
            value: "",
          }));
          const date = data.formDates.map(({ id, name }) => ({
            id,
            name,
            value: "",
          }));
          const string = data.formTypes.map(({ id, name }) => ({
            id,
            name,
            value: ""
          }));
          values.selectedInsertHotel = ""
          values.selectedInsertDepartment = ""

          data.formDropdown.forEach((Dropdown) => {
            const dropdawn = {
              id: Dropdown.id,
              name: Dropdown.name,
              options: Dropdown.options,
              value: ""
            }
            setShowInput(prev => [...prev, { order: Dropdown.order, type: "drop", data: dropdawn }])
          })

          data.formTypes.forEach((text) => {
            const text1 = {
              id: text.id,
              name: text.name,
              value: ""
            }
            setShowInput(prev => [...prev, { order: text.order, type: "text", data: text1 }])
          })

          data.formDates.forEach((date) => {
            const date1 = {
              id: date.id,
              name: date.name,
              value: ""
            }
            setShowInput(prev => [...prev, { order: date.order, type: "date", data: date1 }])
          })


          let fillgroup = []
          data.formGroups?.groupTypeDropdowns.forEach((Dropdown) => {
            const dropdawn = {
              id: Dropdown.id,
              name: Dropdown.name,
              options: Dropdown.options,
              value: ""
            }
            fillgroup.push({ order: Dropdown.order, type: "drop", data: dropdawn })
            setFormGroupHeadShow(prev => [...prev, { order: Dropdown.order, name: Dropdown.name }])
          })

          data.formGroups?.groupTypeNames.forEach((text) => {
            const text1 = {
              id: text.id,
              name: text.name,
              value: ""
            }
            fillgroup.push({ order: text.order, type: "text", data: text1 })
            setFormGroupHeadShow(prev => [...prev, { order: text.order, name: text.name }])

          })

          data.formGroups?.groupTypeDates.forEach((date) => {
            const date1 = { id: date.id, name: date.name, value: "" }
            fillgroup.push({ order: date.order, type: "date", data: date1 })
            setFormGroupHeadShow(prev => [...prev, { order: date.order, name: date.name }])
          })
          setAddedGroup(fillgroup)

          if (data.formGroups?.groupTypeDropdowns.length > 0 || data.formGroups?.groupTypeDates.length > 0 || data.formGroups?.groupTypeNames.length > 0) {
            setFormGroupData(prev => [...prev, { groupId: data.formGroups?.groupId, order: formGroupData?.length + 1, formGroup: fillgroup }])
            setGroupCount(1)
            setCount2(1)
            //console.log("--------", fillgroup);

          }

          setShowDropdowns(drop)
          setShowStrings(string);
          setShowDateName(date);
          // setCount2(data.formGroups?.groupTypeDropdowns.length + data.formGroups?.groupTypeDates.length + data.formGroups?.groupTypeNames.length)

        }
      }

    } catch (error) {
      //console.log(error);
    } finally {
      setloadingComp(false)
      setLoading(false)
    }
  }

  function setEmptyValues() {
    setValues({
      selectedInsertData: [],
      selectedInsertHotel: "",
      selectedInsertDepartment: "",
      selectedInsertTypeDates: [],
      selectedInsertTypeDropdowns: [],
      selectedInsertTypeFiles: [],
      seletedInsertTypeGroups: [],
    });

    //console.log("values", values);
  }

  async function fillForm(values) {

    try {
      values.selectedInsertHotel = parseInt(values.selectedInsertHotel)?parseInt(values.selectedInsertHotel):""
      values.selectedInsertDepartment = parseInt(values.selectedInsertDepartment)?parseInt(values.selectedInsertDepartment):""
      let groups = [];
      //console.log(formGroupData);

      formGroupData?.forEach((obj) => {
        let myobject = {
          id: obj.groupId,
          selectedGroupInsertData: [],
          selectedGroupInsertDropdown: [],
          selectedInsertTypeDatesGroup: [],
          selectedInsertTypeFilesGroup: []
        };
        //console.log(obj);
        obj?.formGroup?.forEach((input) => {
          if (input.type == "drop") {
            myobject.selectedGroupInsertDropdown.push({ value: input.data.value, id: input.data.id })
          }
          if (input.type == "text") {
            myobject.selectedGroupInsertData.push({ value: input.data.value, id: input.data.id })
          }
          if (input.type == "date") {
            myobject.selectedInsertTypeDatesGroup.push({ value: input.data.value, id: input.data.id })
          }
        })
        groups.push(myobject)
      })

      //console.log("kkkkkkkkkkkkkk", groups);
      values.seletedInsertTypeGroups = groups



      values.selectedInsertTypeFiles = [file]

      let formData = new FormData()

      values.selectedInsertData.forEach((item, index) => {
        formData.append(`SelectedInsertData[${index}].id`, item.id);
        formData.append(`SelectedInsertData[${index}].value`, item.value);
      });

      values.selectedInsertTypeDates.forEach((item, index) => {
        formData.append(`SelectedInsertTypeDates[${index}].id`, item.id);
        formData.append(`SelectedInsertTypeDates[${index}].value`, item.value);
      });
      values.selectedInsertTypeDropdowns.forEach((item, index) => {
        formData.append(`SelectedInsertTypeDropdowns[${index}].id`, item.id);
        formData.append(`SelectedInsertTypeDropdowns[${index}].value`, item.value);
      });

      formData.append(`SelectedInsertTypeFiles`, file);

      values.seletedInsertTypeGroups.forEach((group, groupIndex) => {
        group.selectedGroupInsertData.forEach((item, index) => {
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedGroupInsertData[${index}].id`, item.id);
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedGroupInsertData[${index}].value`, item.value);
        });
        group.selectedGroupInsertDropdown.forEach((item, index) => {
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedGroupInsertDropdown[${index}].id`, item.id);
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedGroupInsertDropdown[${index}].value`, item.value);
        });
        group.selectedInsertTypeDatesGroup.forEach((item, index) => {
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedInsertTypeDatesGroup[${index}].id`, item.id);
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedInsertTypeDatesGroup[${index}].value`, item.value);
        });
        group.selectedInsertTypeFilesGroup.forEach((item, index) => {
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedInsertTypeFilesGroup[${index}].Id`, item.Id);
          formData.append(`SeletedInsertTypeGroups[${groupIndex}].SelectedInsertTypeFilesGroup[${index}].FileName`, item.FileName);
        });
      });
      formData.append(`SelectedInsertDepartment`, values.selectedInsertDepartment);
      formData.append(`SelectedInsertHotel`, values.selectedInsertHotel);


      if (user) {
        if (id) {
          setLoading(true);

          const { data } = await axios.put(`${BASE_URL}/api/RegisterationForms/UpdateUserForm/${id}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          setSuccessMessage("Form update successfully!");
          setErrMessage("");
          if (data) {
            navigate(`/form-view/${data}`);
          }
        } else {
          setLoading(true);

          const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/FillForm/${formId}`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          setSuccessMessage("Form submitted successfully!");
          setErrMessage("");
          setDepartments([]);
          setHotels([]);
          setShowDateName([]);
          setShowDropdowns([]);
          setShowStrings([]);
          setEmptyValues();
          values.selectedInsertHotel = "";
          values.selectedInsertDepartment = "";
          getFormById(formId);
          getAllDepartments();
          getAllHotels();
          if (data) {
            navigate(`/form-view/${data}`);
          }
        }

      }
    } catch (err) {
      console.log(err);
      console.log(values);
      setSuccessMessage("");
      setErrMessage("please fill the form first!");
    } finally {
      setLoading(false);
    }
  }



  // Function to handle dropdown selection
  const handleDropdownChange = (event, id) => {
    //console.log("dropdowns before", dropdowns);
    //  const selectedDropdownId = event.target.value;
    const selectedDropdownId = parseInt(event.target.value);
    if (selectedDropdownId) {

      showFormsDropdowns.filter((drop) => {
        if (drop.id == id) {
          drop.value = selectedDropdownId;
        }
      });
      values.selectedInsertTypeDropdowns.filter((drop) => {
        if (drop.id == id) {
          drop.value = selectedDropdownId;
        }
      });
    }
  };
  // Function to handle date selection
  const handleDatesChange = (event, id) => {
    const selectedDateValue = event.target.value;
    //console.log("selectedDateValue", selectedDateValue);
    showDateName.filter((string) => {
      if (string.id == id) {
        string.value = selectedDateValue;
      }
    });
    values.selectedInsertTypeDates.filter((string) => {
      if (string.id == id) {
        string.value = selectedDateValue;
      }
    });

    //console.log("selectedInsertData", selectedInsertData);
  };
  // Function to handle strings selection
  const handleStringsChange = (event, id) => {

    const selectedStringValue = event.target.value;
    //console.log("selectedStringValue", selectedStringValue);
    //console.log("selectedStringId", id);
    showFormsStrings.filter((string) => {
      if (string.id == id) {
        //console.log("selectedStringId1", string.id);

        string.value = selectedStringValue;
        //console.log("selectedStringId1", string.value);

      }
    });
    values.selectedInsertData.filter((string) => {
      if (string.id == id) {
        string.value = selectedStringValue;
      }
    });

    //console.log("selectedInsertData", selectedInsertData);
  };

  const handleFileSelected = (selectedFile) => {
    setFile(selectedFile);
  };

  useEffect(() => {
    //console.log(file);
  }, [file])

  useEffect(() => {
    //console.log(formGroupData);
  }, [formGroupData])


  ///                   Group

  const addGroup = () => {
    setFormGroupData(prev => [...prev, { groupId: formGroupData[0].groupId, order: formGroupData.length + 1, formGroup: addedGroup }])
  }

  const deleteGroup = (groupIndexToDelete) => {
    if (formGroupData.length > 1) {

      const newData = formGroupData.filter(group => group.order != groupIndexToDelete);
      newData.forEach(group => {
        if (group.order > groupIndexToDelete) {
          group.order--;
        }
      });
      setFormGroupData(newData);
    }

  }
  const handleGroupDropdownChange = (event, groupIndex, dataId) => {
    const { name, value } = event.target;
    setFormGroupData(prevData => {
      return prevData.map(group => {
        if (group.order === groupIndex) {
          return {
            ...group,
            formGroup: group.formGroup.map(input => {
              if (input.data.id === dataId && input.type == "drop") {
                return {
                  ...input,
                  data: {
                    ...input.data,
                    value: value
                  }
                };
              }
              return input;
            })
          };
        }
        return group;
      });
    });
  }

  const handleGroupDatesChange = (event, groupIndex, dataId) => {
    const { name, value } = event.target;
    setFormGroupData(prevData => {
      return prevData.map(group => {
        if (group.order === groupIndex) {
          return {
            ...group,
            formGroup: group.formGroup.map(input => {
              if (input.data.id === dataId && input.type == "date") {
                return {
                  ...input,
                  data: {
                    ...input.data,
                    value: value
                  }
                };
              }
              return input;
            })
          };
        }
        return group;
      });
    });
  }

  const handleGroupStringsChange = (event, groupIndex, dataId) => {
    const { name, value } = event.target;
    // //console.log();

    setFormGroupData(prevData => {
      return prevData.map(group => {
        if (group.order === groupIndex) {
          return {
            ...group,
            formGroup: group.formGroup.map(input => {
              if (input.data.id === dataId && input.type == "text") {
                if (input.data.name == "Room No") {
                  handleRoom(groupIndex, value)
                }
                return {
                  ...input,
                  data: {
                    ...input.data,
                    value: value
                  }
                };
              }
              return input;
            })
          };
        }
        return group;
      });
    });
  };
  const handledisable = (name) => {
    if (formGroupHeadShow.find((item) => item.name == "Room No")) {
      switch (name) {
        case "Guest Name":
          return true;
        case "Nationality":
          return true;
        case "Arrival Date":
          return true;
        case "Departure Date":
          return true;
        case "Number Of Pax":
          return true;
        case "Travel Agent":
          return true;
        case "Booked Room Type":
          return true;
        case "Treatment Type":
          return true;
        case "Board Type":
          return true;
        case "NTS":
          return true;
        case "Upgrade Reason":
          return true;
        default:
          return false;

      }
    }
  }
  const handleRoom = async (input1, value) => {
    //console.log("i am here", input1);
    if (value != "") {

      const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetRoomData/${value}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log(data);

      if (data?.length > 0) {

        setFormGroupData(prevData => {
          return prevData.map(group => {
            if (group.order === input1) {
              return {
                ...group,
                formGroup: group.formGroup.map(input => {

                  // "board_Type": "AI",
                  switch (input.data.name) {
                    case "Guest Name":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].guest
                        }
                      };
                    case "Nationality":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].country
                        }
                      };
                    case "Arrival Date":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].arrival_Date
                        }
                      };
                    case "Departure Date":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].departure_Date
                        }
                      };
                    case "Number Of Pax":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].adult
                        }
                      };
                    case "Travel Agent":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].travel_Agent
                        }
                      };
                    case "Booked Room Type":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].room_Category
                        }
                      };

                    case "Treatment Type":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].vip
                        }
                      };
                    case "Board Type":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].board_Type
                        }
                      };
                    case "NTS":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].nights
                        }
                      };
                    case "Upgrade Reason":
                      return {
                        ...input,
                        data: {
                          ...input.data,
                          value: data[0].upgrade_Reason
                        }
                      };
                    default:
                      return input;

                  }


                })
              };
            }
            return group;
          });
        });
      }
    }

  }

  const handleRemoveGroups = () => {
    setShowGroup(false)
    setFormGroupData(prevData => {
      return prevData.map(group => {

        return {
          ...group,
          formGroup: group.formGroup.map(input => {
            if (input.type == "text") {
              return {
                ...input,
                data: {
                  ...input.data,
                  value: "No Discrepancy"
                }
              };
            }
            return input;
          })
        };

      });
    });

  }

  const handleRateChange = () => {
    setShowGroup(false)
    setFormGroupData(prevData => {
      return prevData.map(group => {

        return {
          ...group,
          formGroup: group.formGroup.map(input => {
            if (input.type == "text") {
              return {
                ...input,
                data: {
                  ...input.data,
                  value: "No Change"
                }
              };
            }
            if (input.type == "date") {
              return {
                ...input,
                data: {
                  ...input.data,
                  value: "1-10-1998"
                }
              };
            }
            if (input.type == "drop") {
              return {
                ...input,
                data: {
                  ...input.data,
                  value: "1"
                }
              };
            }
            return input;
          })
        };

      });
    });

  }


  if (!showInput.length && loadingComp) {
    return <Loading />
  } else {
    return <>
      <Formik initialValues={values} onSubmit={fillForm} >
        <Form className=" p-3 mt-5 w-100">
          {formId ? (
            <div>
              <div className="bg-body row p-5 header">
                <h5 className="text-center">{showFormName.name}</h5>
                {/* hotel */}
                <div className="col-md-4">
                  <div className="hotel">
                    <label className="text-right control-label col-form-label">
                      hotel
                    </label>
                    <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                      <Field
                        as="select"
                        name="selectedInsertHotel"
                        id="selectedInsertHotel"
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                      >
                        <option value="" disabled>
                          Hotel
                        </option>
                        {hotels?.map((hotel) => (
                          <option key={hotel.id} value={hotel.id}>
                            {hotel.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                {/* department */}
                <div className="col-md-4">
                  <div className="departments">
                    <label className="text-right control-label col-form-label">
                      Department
                    </label>
                    <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                      <Field
                        as="select"
                        name="selectedInsertDepartment"
                        id="selectedInsertDepartment"
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                      >
                        <option value="" disabled>
                          Select department
                        </option>
                        {departments?.map((department) => (
                          <option key={department.id} value={department.id}>
                            {department.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>

                {showInput.map((item, index) => (
                  <div key={`i${index}`} className="col-md-4" >
                    {item.type == "drop" && (

                      <div className="hotel">
                        <label className="text-right control-label col-form-label">
                          *{item.data?.name}
                        </label>
                        <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                          <Field
                            as="select"
                            name="dropdowns"
                            id="dropdowns"
                            className="selectpicker hotel-select form-select mt-2"
                            aria-label="Default select example"
                            defaultValue={item.data?.value}
                            onChange={(event) => handleDropdownChange(event, item.data.id)}
                          >
                            <option value="" disabled>
                              Select {item.data?.name}
                            </option>
                            {item.data?.options?.map((dropDownOption) => (
                              <option
                                key={dropDownOption.id}
                                value={dropDownOption.id}
                              >
                                {dropDownOption.elementName}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                    )}

                    {item.type == "text" && (
                      <div key={item.data?.id} >
                        <label className="text-right control-label col-form-label">
                          *{item.data?.name}
                        </label>
                        <div className="d-flex align-items-center">
                          <input
                            onBlur={(event) =>
                              handleStringsChange(event, item.data?.id)
                            }
                            type="text"
                            className="form-control mt-2"
                            id={item.data?.id}
                            defaultValue={item.data?.value}
                            name="selectedInsertData"
                            placeholder={item.data?.name}
                          />
                        </div>
                      </div>
                    )}

                    {item.type == "date" && (
                      <div key={index} >
                        <label className="text-right control-label col-form-label">
                          *{item.data?.name}
                        </label>
                        <div className="d-flex align-items-center">
                          <input
                            onChange={(event) => handleDatesChange(event, item.data?.id)}
                            type="date"
                            className="form-control mt-2"
                            data-toggle="datepicker"
                            name={item.data?.name}
                            defaultValue={formatDate(item.data?.value) == "NaN-NaN-NaN" ? "" : formatDate(item.data?.value)}
                            placeholder="yyyy-mm-dd"
                          />
                        </div>
                      </div>
                    )}

                  </div>

                ))}

                {(showFormName.name == "Discrepancy" && showGroup) &&

                  <div className="col-md-12 mt-3 d-flex justify-content-end">
                    <div className="Room Discrepancy">
                      <input type="button" className="btn btn-dark me-4" onClick={() => handleRemoveGroups()} value="No Discrepancy" />

                    </div>
                  </div>
                }


                {(showFormName.name == "Room Rate Change"||showFormName.name == "Special Rate"||
                  showFormName.name == "Free Room Upgrade"||showFormName.name == "Free late check out"||
                  showFormName.name == "House Use"||showFormName.name == "Rate Change" && showGroup) &&

                  <div className="col-md-12 mt-3 d-flex justify-content-end">
                    <div className="Room Rate Change">
                      <input type="button" className="btn btn-dark me-4" onClick={() => handleRateChange()} value="No Change" />

                    </div>
                  </div>
                }

                {(formGroupData.length > 0 && showGroup) &&
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table id="selected-Items-list-table" className="table table-hover table-bordered table-info text-center">
                          <thead >
                            <tr>
                              <th width="5%">
                                <button type="button" name="addRow" id="addRow" className="btn btn-light rounded-circle d-flex" onClick={addGroup}>
                                  <i className="fa-solid fa-database"></i><i className="fa-solid fa-plus"></i>
                                </button>
                              </th>
                              {formGroupHeadShow.map((item, index) =>
                                <th key={`${index}h`} >{item.name}</th>
                              )}
                            </tr>
                          </thead>
                          <tbody id="outgoing-data">

                            {formGroupData.map((inputshow, index) =>

                              <tr key={index}>
                                <td>
                                  <div className="delete">
                                    <button type="button" onClick={() => deleteGroup(inputshow.order)} ><i className="fa-regular fa-trash-can"></i></button>
                                  </div>

                                </td>
                                {inputshow.formGroup?.map((item, inpIndex) =>
                                  <td key={`ii${inpIndex}`}>
                                    {item.type == "drop" && (
                                      <div className="hotel">
                                        <Field
                                          as="select"
                                          name="dropdowns"
                                          id="dropdowns"
                                          data-value={item.data?.value}
                                          className="selectpicker flexible-input hotel-select form-select mt-2"
                                          aria-label="Default select example"
                                          value={item.data?.value}
                                          style={{ '--input-length': item.data?.value?.length + 1 }}
                                          disabled={handledisable(item.data?.name)}
                                          onChange={(event) => handleGroupDropdownChange(event, inputshow.order, item.data?.id)}
                                        >
                                          <option value="" disabled>
                                            Select {item.data?.name}
                                          </option>
                                          {item.data?.options?.map((dropDownOption) => (
                                            <option
                                              key={dropDownOption.id}
                                              value={dropDownOption.id}
                                            >
                                              {dropDownOption.elementName}
                                            </option>
                                          ))}
                                        </Field>
                                      </div>

                                    )}
                                    {item.type == "text" && (
                                      <div key={item.data?.id} >

                                        <div className="d-flex align-items-center">
                                          <input
                                            disabled={handledisable(item.data?.name)}
                                            onChange={(event) =>
                                              handleGroupStringsChange(event, inputshow.order, item.data?.id)
                                            }
                                            type="text"
                                            className="form-control mt-2 flexible-input"
                                            id={item.data?.id}
                                            value={item.data?.value}
                                            data-value={item.data?.value}
                                            style={{ '--input-length': item.data?.value?.length + 1 }}
                                            name="selectedInsertData"
                                            placeholder={item.data?.name}
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {item.type == "date" && (
                                      <div key={index} >
                                        <div className="d-flex align-items-center flexible-input">
                                          <input
                                            disabled={handledisable(item.data?.name)}
                                            onChange={(event) => handleGroupDatesChange(event, inputshow.order, item.data?.id)}
                                            type="date"
                                            className="form-control mt-2"
                                            data-toggle="datepicker"
                                            name={`${item.data?.name}r${index}`}
                                            value={formatDate(item.data?.value) == "NaN-NaN-NaN" ? "" : formatDate(item.data?.value)}
                                            placeholder="yyyy-mm-dd"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                )}



                              </tr>
                            )}



                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }

                {errMessage && (
                  <div className="alert alert-danger mt-5 text-center fw-bold">
                    {errMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success mt-5 text-center fw-bold">
                    {successMessage}
                  </div>
                )}
              </div>
              <div className="mt-1">
                <DragAndDrop onFileSelected={handleFileSelected} />
              </div>
              <div className="form mt-1 text-center header">
                <button
                  type="submit"
                  className="btn btn-dark px-5 py-1 fs-5"
                  disabled={loading}
                >
                  {loading ? "saving..." : "save"}
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-body row p-5 header text-center">
              <h4>No form selected! </h4>
            </div>
          )}
        </Form>
      </Formik>
    </>

  }

};
function formatDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}