import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../config";

export default function UserPendingForms({data,userData}) {
  const [user, setUser] = useState("");
  const [userName, setUsererName] = useState("");
  // const [status, setStatus] = useState("signing");
  const [allForms, setAllForms] = useState([]);
  
  useEffect(() => {
    if (userData) {
      
      //console.log(userData);
      setUsererName(userData) 
    }
  },[userData]) 

  useEffect(() => {
    
      async function getForms() {
        try {
          if (data) {
       

            const forms= data.filter((form)=>form.userFormStatus.isPending) 
            setAllForms(forms);            
            console.log("data", data); 
          }
  
        } catch (error) {
          console.log(error);
        }
      }
      if (data) {
        
        getForms();
      }
  }, [data]);

function dateString(date) {
// console.log(date);
// const subString=date.substring(10);
return date.substring(0,10)+" "+ date.substring(11,16);
  }


  return (
    <>
     
     <div className="container">
        <div className="table-responsive overflow-auto overflow-hidden">
        <h4 className="mt-5 mb-4 text-center fw-bold">Pending Forms</h4>
   {allForms.length>0?( <table className="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Form Name</th>
                        <th>Hotel Name</th>
                        {/* <th>Status</th> */}
                        {/* <th>Signature On</th> */}
                        <th>Created By</th>
                        <th>Created At</th>
                        <th>View</th>

                    </tr>
                </thead>
                <tbody>
                    {allForms?.map((form,index)=>(
                    <tr key={form.userFormId} >
                        <td>{index+1}</td>
                        <td>{form.formName}</td>
                        <td>{form.hotel.hotelName}</td>
                        {/* <td>{statusChange(form.userFormStatus)}</td> */}
                        <td>{userName}</td>
                        <td>{dateString(form.createdDate)}</td>
                        <td> <Link to={`/form-view/${form.userFormId}`} className="btn btn-primary"> <i className="fa-solid fa-eye"></i> View</Link></td>                       
                        {/* <td>length</td>
                        <td> <Link to={`/dashboard/fill-form/${form.id}`} className="btn btn-warning">Update</Link></td>
                        <td> <button onClick={()=>deleteForm(form.id)} className="btn btn-danger">Delete</button></td> */}
                    </tr>
                    ))}
                </tbody>
            </table>): (
            <div className="bg-body row p-5 header text-center">
              <h4>No forms! </h4>
            </div>
          )}
           



        </div>

       
    </div>
       
    </>
  );
}
