import React, { useContext, useEffect, useState } from "react";
import Style from "./Sidebar.css";
import { Link } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { BASE_URL } from "../config";
import axios from "axios";

export default function Sidebar(props) {
  const [forms1, setForms1] = useState([]);
  const [tokens, setTokens] = useState("");
  const { user, userRole } = useContext(UserContext);
  const [openLink1, setOpenLink1] = useState(false);
  const [openLink2, setOpenLink2] = useState(false);
  const [expandedDepartments, setExpandedDepartments] = useState({});
  const [activeElements, setActiveElements] = useState([]);
  const [fontOffice, setFontOffice] = useState([]);
  const [financial, setFinancial] = useState([]);
  const [houseKeeping, setHouseKeeping] = useState([]);
  const [general, setGeneral] = useState([]);
  const [humanResources, setHumanResources] = useState([]);
  const [newForm, setNewForm] = useState([]);
  const [sideHover, setSideHover] = useState(false);
  const toggleCollapse = (index) => {
    setExpandedDepartments((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleActiveElement = (element) => {
    setActiveElements((prevActiveElements) => {
      if (prevActiveElements.includes(element)) {
        return prevActiveElements.filter((el) => el !== element);
      } else {
        return [...prevActiveElements, element];
      }
    });
  }

  useEffect(() => {
    if (!sideHover && !props.isOpenSidebar) {
      setOpenLink1(false)
      setOpenLink2(false)
      setExpandedDepartments((prevState) => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = false;
        }
        return newState;
      });
    }

  }, [sideHover, props.isOpenSidebar]);


  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setTokens(token);
  }, []);

  useEffect(() => {
    if (tokens) {

      async function getAllUserForms() {
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetUserRegisterForm`,
          {
            headers: {
              Authorization: `Bearer ${tokens}`,
            },
          }
        );
      

data?.forEach(element => {
  
  if(getsidebar(element.name)=="Human Resources"){
    setHumanResources(perv=>[...perv,element])
  }
  if(getsidebar(element.name)=="General"){
    setGeneral(perv=>[...perv,element])
  }
  if(getsidebar(element.name)=="House Keeping"){
    setHouseKeeping(perv=>[...perv,element])
  }
  if(getsidebar(element.name)=="Financial"){
    setFinancial(perv=>[...perv,element])
  }
  if(getsidebar(element.name)=="Font Office"){
    setFontOffice(perv=>[...perv,element])
  } 
  if(getsidebar(element.name)=="New Form"){
    setNewForm(perv=>[...perv,element])
  } 
});
      }

      getAllUserForms()
    }
  }, [tokens]);

  const handelMouseOn = () => {
    setSideHover(true)
  }

  const handelMouseOut = () => {
    if (!props.isOpenSidebar) {
      setSideHover(false)
    }
  }
  useEffect(() => {
    setSideHover(props.isOpenSidebar)
  }, [props.isOpenSidebar])

  return (
    <>
      <div
        className={`side-header   
           ${sideHover || props.isOpenSidebar ? "open-sidebar " : "hide-sidebar"} 
          `}
        onMouseLeave={handelMouseOut}
        onMouseEnter={handelMouseOn}
      >
        {userRole !== "Admin" ? (
          <div className={` ${activeElements.includes('dashboard') ? 'currentSideBarElement' : ''} dashboard mb-0  pt-4 sideBarElement`}>
            <a
              data-bs-target="#collapseExample7"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              className="text-decoration-none  "
              onClick={() => {
                setOpenLink1(!openLink1);
                toggleActiveElement('dashboard')
              }}
            >
              {
                !sideHover && !props.isOpenSidebar ? (
                  <div className=" w-100 text-end p-meSide">
                    <i className="fa-solid fa-hotel " />
                  </div>) : (<div className="d-flex px-3 pb-1 ms-0 ">
                    <i className="fa-solid fa-hotel pe-2" />
                    <div className="d-flex justify-content-between w-100 pe-1">
                      <p className="fontSizeMe ">Dashboard</p>
                      {openLink1 ? (
                        <i className="fa-solid fa-angle-down ms-2"></i>
                      ) : (
                        <i className="fa-solid fa-angle-right ms-2"></i>
                      )}

                    </div>

                  </div>)
              }


            </a>
            {(sideHover || props.isOpenSidebar) &&

              <div className="collapse py-3 ps-4 elementMenu" id="collapseExample7">
                <div className="mb-3 ms-4">
                  <div className=" w-100 bg-transparent border-0 pb-0 text-light">
                    <Link
                      className=" w-100 bg-transparent border-0 pb-0 text-light"
                      to="dashboard/form-details"
                    >
                      Form Details
                    </Link>
                  </div>
                </div>
       
                <div className="mb-3 ms-4">
                  <Link
                    className=" w-100 bg-transparent border-0 pb-0 text-light"
                    to="dashboard/forms"
                  >
                    Forms
                  </Link>
                </div>
       
                <div className="mb-3 ms-4">
                  <div
                    className=" w-100 bg-transparent border-0 pb-0 text-light"
                    data-bs-target="#collapseExample9"
                    data-bs-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Admin
                  </div>
                  <div className="collapse " id="collapseExample9">
                    <div className="card card-body  border-0 bg-transparent p-0 ps-4">
                      <h6>
                        <Link
                          className={`text-light ${Style.textHover}`}
                          to="dashboard/users"
                        >
                          Users
                        </Link>
                      </h6>
                      <h6>
                        <Link
                          className={`text-light ${Style.textHover}`}
                          to="dashboard/departments"
                        >
                          Departments
                        </Link>
                      </h6>
                      <h6>
                        <Link
                          className={`text-light ${Style.textHover}`}
                          to="dashboard/logs"
                        >
                          System Actions
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        ) : null}
        <ul className="forms links list-unstyled">
          <li
            onClick={() => {
              toggleActiveElement('myForms');
            }}
            className={`${activeElements.includes('myForms') ? "currentSideBarElement" : ''} ms-0 mb-0 px-3 pt-3 sideBarElement cursor-pointer`}
          >
            <Link
              className={`text-decoration-none ${Style.textHover}`}
              to="my-forms"
            >
              {
                sideHover || props.isOpenSidebar ? (
                  <div className="d-flex pb-3 ">
                    <i className="fa-brands fa-wpforms pe-2"></i>
                    <p className="fontSizeMe pt-1 my-0">My Forms</p>
                  </div>
                ) : (
                  <div className="text-end w-100 ms-1">
                    <i className="fa-brands fa-wpforms p-meSide3"></i>
                  </div>
                )
              }


            </Link>
          </li>
          {/* {forms1?.map((department, index) => (
            <li
              key={`dep${index}`}
              className={`${activeElements.includes(index) ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${index}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[index] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(index);
                  toggleActiveElement(index);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">{department.departmentName}</p>
                        {expandedDepartments[index] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleD${index}`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {department?.formList?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}
                        >
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          ))} */}
{general?.length>0&& (
            <li
              key={`dep${5}`}
              className={`${activeElements.includes("f5") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f5"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[5] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(5);
                  toggleActiveElement(5);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">General</p>
                        {expandedDepartments[5] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf5`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {general?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
{fontOffice?.length>0&& (
            <li
              key={`dep${1}`}
              className={`${activeElements.includes("f1") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f1"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[1] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(1);
                  toggleActiveElement(1);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">Front Office</p>
                        {expandedDepartments[1] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf1`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {fontOffice?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
          
          
{financial?.length>0&& (
            <li
              key={`dep${2}`}
              className={`${activeElements.includes("f2") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f2"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[2] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(2);
                  toggleActiveElement(2);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">Financial</p>
                        {expandedDepartments[2] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf2`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {financial?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
          
{houseKeeping?.length>0&& (
            <li
              key={`dep${3}`}
              className={`${activeElements.includes("f3") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f3"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[3] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse();
                  toggleActiveElement(3);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">House Keeping</p>
                        {expandedDepartments[3] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf3`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {houseKeeping?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
          
{humanResources?.length>0&& (
            <li
              key={`dep${4}`}
              className={`${activeElements.includes("f4") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f4"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[4] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(4);
                  toggleActiveElement(4);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2" />
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">Human Resources</p>
                        {expandedDepartments[4] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }


              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf4`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {humanResources?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
                    

          {newForm?.length>0&& (
            <li
              key={`dep${6}`}
              className={`${activeElements.includes("f6") ? "currentSideBarElement" : ''} w-100 ms-0 pt-3 mb-0 sideBarElement`}
            >
              <a
                data-bs-target={`#collapseExampleD${"f6"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded={expandedDepartments[6] ? "true" : "false"}
                aria-controls="collapseExample"
                onClick={() => {
                  toggleCollapse(6);
                  toggleActiveElement(6);
                }}
                className=""
              >
                {
                  sideHover || props.isOpenSidebar ? (
                    <div className="d-flex px-3">
                      <i className="fa-solid fa-file-signature pe-2"/>
                      <div className="d-flex justify-content-between w-100">
                        <p className="col-10 fontSizeMe pt-1">New Forms</p>
                        {expandedDepartments[6] ? (
                          <i className="fa-solid fa-angle-down "></i>
                        ) : (
                          <i className="fa-solid fa-angle-right "></i>
                        )}
                      </div>

                    </div>
                  ) : (
                    <div className="w-100 text-end">
                      <i className="fa-solid fa-file-signature p-meSide2" />
                    </div>
                  )
                }

              </a>
              {(sideHover || props.isOpenSidebar) &&
                <div className="collapse elementMenu pt-3 ps-4" id={`collapseExampleDf6`}>
                  <div className="card card-body border-0 bg-transparent p-0 ps-4">
                    {newForm?.map((form) => (
                      <h6 key={`f${form.id}`}>
                        <Link
                          className={`text-decoration-none ${Style.textHover} d-flex`}
                          to={`form-search/${form.id}`}>
                          <p>{form.name}</p>
                        </Link>
                      </h6>
                    ))}
                  </div>
                </div>
              }
            </li>
          )}
           <li
            onClick={() => {
              toggleActiveElement('myForms');
            }}
            className={`${activeElements.includes('myForms') ? "currentSideBarElement" : ''} ms-0 mb-0 px-3 pt-3 sideBarElement cursor-pointer`}
          >
            <Link
              className={`text-decoration-none ${Style.textHover}`}
              to="reports"
            >
              {
                sideHover || props.isOpenSidebar ? (
                  <div className="d-flex pb-3 ">
                    <i className="fa-brands fa-wpforms pe-2"></i>
                    <p className="fontSizeMe pt-1 my-0">Reports</p>
                  </div>
                ) : (
                  <div className="text-end w-100 ms-1">
                    <i className="fa-brands fa-wpforms p-meSide3"></i>
                  </div>
                )
              }


            </Link>
          </li>
        </ul>
        <div className="bot"></div>
      </div>
    </>
  );
}

function getsidebar(form) {
  switch (form) {
    case "Room Complimentary":
      return "Font Office";
    case "House Use":
      return "Font Office";
      case "Special Rate":
        return "Font Office";
        case "Illness Log":
          return "Font Office";
    case "Guest Amenity":
      return "Font Office";
    case "Free Day Use":
      return "Font Office";
    case "Free Beach Use":
      return "Font Office";
    case "Free Room Upgrade":
      return "Financial";
    case "Free late check out":
      return "Financial";
    case "Room Rate Change":
      return "Financial";
    case "Rate Change":
      return "Financial";
    case "Discrepancy":
      return "Financial";
    case "Room Out Of Order":
      return "House Keeping";
    case "Gifts and Findings Exit Permit":
      return "House Keeping";
    case "Out Going":
      return "General";
    case "Gate Pass":
      return "General";
    case "Signature Policy":
      return "General";
    case "Out Delivery":
      return "General";
    case "Application for Hiring level 1&2":
      return "Human Resources";
    case "Application for Hiring level 0":
      return "Human Resources";
    case "Change of Status For level 0":
      return "Human Resources";
    case "Change of Status For level 1&2":
      return "Human Resources";
    case "House Allowance over budget":
      return "Human Resources";
      case "Medical Allowance over 10000":
        return "Human Resources";
      default:
      return "New Form";
  }
}