import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { Loading } from "../../Loading/loading";

export default function AllDropdowns() {
  const [user, setUser] = useState("");
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);

    async function getDropdowns() {
      try {
        if (user) {
          const { data } = await axios.get(`${BASE_URL}/api/Dropdowns/GetAllDropdowns`, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          });
          setAllDropdowns(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    getDropdowns();
  }, [user]);

  const deleteDropdown = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/Dropdowns/DeleteDropdown/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      setAllDropdowns((prev) => prev.filter((dropdown) => dropdown.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading && !allDropdowns.length) {
    return <Loading />;
  }

  return (
    <div className="container py-4">
      <div className="row justify-content-between align-items-center mb-3">
        <div className="col-12 col-md-6 mb-3 mb-md-0">
          <h4 className="fw-bold text-secondary text-center text-md-start">
            Dropdowns
          </h4>
        </div>
        <div className="col-12 col-md-6 text-center text-md-end">
          <Link to="/dashboard/create-dropdown" className="btn btn-outline-primary">
            Create Dropdown
          </Link>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Number of elements</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allDropdowns.map((dropdown, index) => (
              <tr key={dropdown.id}>
                <td>{index + 1}</td>
                <td>{dropdown.name}</td>
                <td>{dropdown.options?.length}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row gap-2">
                    <Link
                      to={`/dashboard/create-dropdown/${dropdown.id}`}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Update
                    </Link>
                    <button
                      onClick={() => deleteDropdown(dropdown.id)}
                      className="btn btn-outline-danger btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
