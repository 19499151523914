import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import Style from "./view.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserFormView from "./user-form-view";
import { Loading } from "../Loading/loading";
import { FormLevel } from "../formLevel/form-level";
import { LevelView } from "./level-view";
import logo from '../../assets/imgs/2f4f053102624702e5ea93ae634f0eaaPdyDYuYVlhcozkF3-1.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default function FormView() {
  const [user, setUser] = useState("");
  const [userData, setUserData] = useState("");
  const [ownerData, setOwnerData] = useState("");
  const { id } = useParams()
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });
  const [loading, setLoading] = useState(false)
  const [comment, setComment] = useState('');
  const [formId, setFormId] = useState("");
  const [formData, setFormData] = useState([]);
  const [disMiss, setdisMiss] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    const Data = JSON.parse(localStorage.getItem("userData"));
    setUser(token);
    setUserData(Data)
  }, [])

  useEffect(() => {
    if (id && user) {

      async function getUserFormById() {
        try {
          setLoading(true)
          const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetUserFormById/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          //  if (data) {
          setShowFormName({
            id: data.formId,
            name: data.formName,
          });
          //console.log(data);
          setFormData(data)
          GetUserById(data.createdBy);

          //  }
        } catch (error) {
          console.log(error);
        }
        finally {
          setLoading(false)
        }

      }
      getUserFormById();
    }
  }, [id, user])

  const handelReturn = async () => {

    if (comment && formId) {

      setdisMiss(true)
      try {
        const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/CommentForm/${formId}?comment=${comment}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setComment("")
        window.location.reload();
        //console.log(data);

      } catch (error) {
        console.log(error);
      }
      finally {
        setdisMiss(false)

      }
    }



  }
const [deleteLoad,setDeleteLoad]=useState(false);
 async function  deleteForm(id,registerId) {
    try {
      setDeleteLoad(true)
      const { data } = await axios.delete(`${BASE_URL}/api/RegisterationForms/DeleteUserForm/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      navigate(`/form-search/${registerId}`)
  // 
      //console.log('delete', data);
    } catch (error) {
      console.log(error);
    }
    finally{
      setDeleteLoad(false)
    }
    
  }


      async function GetUserById(createdBy) {
        try {

          const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${createdBy}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          //console.log('GetUserById', data);
          setOwnerData(data)
        } catch (error) {
          console.log(error);
        }

      }



  const getStatus = () => {
    if (formData.userFormStatus?.isAccepted) {
      return "Accepted";
    }
    else if (formData.userFormStatus?.isRejected) {
      return "Rejected";
    }
    else if (formData.userFormStatus?.isPending) {
      return "Pending";
    }
    else if (formData.userFormStatus?.isOpen) {
      return "Waiting admin confirm";
    }
    else {
      return "Returned to created department";
    }
  };


  const contentRef = useRef();
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

    const handleDownloadPdf = () => {
        setIsGeneratingPdf(true); // Set state to hide the button

        setTimeout(() => {
            const input = contentRef.current;

            html2canvas(input, { scale: 2 }).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = 210; // A4 width in mm
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save(`${formData.formName} #${formData.userFormId}`);

                setIsGeneratingPdf(false); // Show the button again after generating the PDF
            });
        }, 100);
};
  if (!formData.length && loading) {
    return <Loading />
  } else {
    return <>
    {}
    <div ref={contentRef} className="mx-5 my-5">
{formData&&
<div className="mx-5">
<div className="row justify-content-between align-items-center my-5">

<div className="col-md-5 ps-0">
  <img src={logo} className="w-48" alt="" />
</div>
<div className="col-md-5 p-3">


  <hm4>{formData.formName} #{formData.userFormId}
  {!isGeneratingPdf && (
  <i onClick={handleDownloadPdf} className="fa-solid fa-file-pdf fs-3 ms-3 text-danger"></i>       
 )}
  </hm4>
  <h6><strong>Hotel Name:</strong> {ownerData.hotelName}</h6>
  <h6><strong>Department Name:</strong> {ownerData.departmentName}</h6>
  <h6><strong>Status:</strong> {getStatus()}</h6>
  <h6><strong>Create By:</strong> {ownerData.username}</h6>
  <h6><strong>Create At:</strong> {formData.createdDate?.substring(0, 10)} {formData.createdDate?.substring(11, 16)}</h6>
</div>
</div>
</div>
}
  {!isGeneratingPdf &&(
      <div className="text-center mt-5 h4">
      {
      ((formData?.userFormStatus?.isRetuningToUser||formData?.userFormStatus?.isRetuningToDepartment)
      && (formData.createdBy==userData.id))
      &&
      <Link className="btn btn-warning cursor-pointer m-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setFormId(formData.userFormId)} >
        <i class="fa-regular fa-comment-dots"></i>
         &nbsp;Add comment
      </Link>
      }
          {((formData?.userFormStatus?.isOpen)&& formData.createdBy==userData.id)
      &&
      <Link to={`/fill-form/${formData.userFormId}`} className="btn btn-warning m-3">
        <i className="fa-solid fa-pen-to-square"></i>
        &nbsp;  Update</Link>
        }
   {((formData?.userFormStatus?.isOpen)&& formData.createdBy==userData.id)
      &&
      <Link onClick={() => deleteForm(formData.userFormId,formData.formId)} className="btn btn-danger" disabled={deleteLoad}>
        <i className="fa-solid fa-trash"></i>
        &nbsp;  
        {deleteLoad?"Deleting...":"Delete"}
        </Link>
   }
    </div>
  )}

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">Comment</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">

                <div>
                  <textarea className="form2 w-100" type="area" id="Comment" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Comment" >
                  </textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={()=>handelReturn()} disabled={disMiss} >{disMiss ? 'Send...' : 'Send'}</button>
              </div>
            </div>
          </div>
      </div>
      <UserFormView data={formData} />
      <LevelView  />
      </div>
    </>
  }

}
