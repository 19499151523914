import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import { Loading } from "../Loading/loading";

export default function MyForms() {
  const { id } = useParams();
 const navigate=useNavigate()
  const [showForms, setShowForms] = useState("AllUserForms"); // Default to 'AllUserForms'
  const [user, setUser] = useState("");
  const [userName, setUsererName] = useState("");
  //const [status, setStatus] = useState("signing");
  const [allForms, setAllForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [form, setForm] = useState({});
  const [statue, setStatue] = useState(null);
  const [hotel, setHotelId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [myForms, setMyForms] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize,setPageSize] = useState(10);
  const [PaginatedForms, setPaginatedForms] = useState([]);
  const totalPages = Math.ceil(allForms?.length / pageSize);


  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUser(token);
    setUsererName(userData.name)

  }, [])
  useEffect(() => {

    async function getForms() {
      try {
        if (user) {
          setLoading(true)
          const { data } = await axios.get(
            `${BASE_URL}/api/RegisterationForms/GetDepartmentForms`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          const forms = data.filter((form) => form.view)
          setAllForms(forms);
          const sortedUsers = [...forms].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
          setPaginatedForms(sortedUsers.slice((page - 1) * pageSize, page * pageSize));   
          setMyForms(forms);
          
          //console.log("data", data);
        }

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false)
      }
    }
    getForms();
  }, [user]);




  const toggleForms = (value) => {
    setShowForms(value);
  };
  function statusChange(state) {
    if (state.isOpen) {
      return "Open";
    }
    if (state.isPending) {
      return "signing";
    }
    if (state.isAccepted) {
      return "Accepted";
    }
    if (state.isRejected) {
      return "Rejected";
    }
    if (state.isRetuningToDepartment || state.isRetuningToUser) {
      return "Returned";
    }
  }
  function statusValue(value) {
    setStatue(value);
    //console.log(value);
  }
  function hotelChange(value) {
    //console.log(value);
    setHotelId(value);
  }
  function departmentChange(value) {
    //console.log(value);
    setDepartmentId(value);
  }

  function filterForms() {
    setPage(1)
    setAllForms(
      myForms.filter((form) => {
        var filterCount = 0;
        var count = 0;
        if (departmentId) {
          count++;
          if (form.department.departmentId == departmentId) {
            filterCount++;
          }
        }
        if (hotel) {
          count++;
          if (form.hotel.hotelId == hotel) {
            filterCount++;
            //console.log('hotel change');

          }
        }
        if (startDate) {
          count++;
          if (
            new Date(form.createdDate.substring(0, 10)) >= new Date(startDate)
          ) {
            filterCount++;
          }
        }
        if (endDate) {
          count++;
          if (
            new Date(form.createdDate.substring(0, 10)) <= new Date(endDate)
          ) {
            filterCount++;
          }
        }

        if (statue) {
          count++;
          if (statue == "Returned" && form.userFormStatus.isRetuningToUser) {
            filterCount++;
          } else if (statue == "signing" && form.userFormStatus.isPending) {
            filterCount++;
          } else if (statue == "Open" && form.userFormStatus.isOpen) {
            filterCount++;
          } else if (statue == "Accepted" && form.userFormStatus.isAccepted) {
            filterCount++;
          }
          if (statue == "Rejected" && form.userFormStatus.isRejected) {
            filterCount++;
          }
        }

        if (count == filterCount) {
          return true;
        } else {
          return false;
        }
      })
    );

  }

const searchByName=(e)=>{
  const name=e.target.value
  setPage(1)
  setAllForms(myForms.filter((form)=>(form.formName +" #"+form.userFormId).toLowerCase().includes(name.toLowerCase())))
}




  async function getFormById(formId) {
    try {
      if (user) {
        // setEmptyValues()
        //console.log(formId);
        setLoading(true);
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetFormById/${formId}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log("getFormById", data);
        if (data) {
          setForm(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      //console.log(id);
      getFormById(id);
    }
  }, [id, user]);

  useEffect(() => {
    async function getForms() {
      try {
        if (user) {
          setLoading(true);
          const { data } = await axios.get(
            `${BASE_URL}/api/RegisterationForms/GetCurrentUserForms`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );

          setAllForms(data);
          setMyForms(data);
          //console.log("data", data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    //getForms();
  }, [user]);

  async function getAllDepartments() {
    const { data } = await axios.get(
      `${BASE_URL}/api/Departments/GetAllDepartments`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    setDepartments(data);
    //console.log("GetAllDepartments", data);
  }
  async function getAllHotels() {
    const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    //console.log("GetAllHotels", data);
    setHotels(data);
  }

  useEffect(() => {
    if (user) {
      getAllDepartments();
      getAllHotels();
    }
  }, [user]);

  const handelStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handelEndDate = (e) => {
    setEndDate(e.target.value);
  };


const viewForm=(id)=>{
navigate(`/form-view/${id}`)
}


const rowCount=(e)=>{
  setPageSize(Number(e.target.value));
    setPage(0);
}
useEffect(() => {
  if (page==0) {

    setPage(1)
  }
},[page])
  useEffect(() => {
    //console.log(allForms);
    
    const sortedForms = [...allForms].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
    setPaginatedForms(sortedForms?.slice((page - 1) * pageSize, page * pageSize));
  }, [page, allForms]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPaginationItems = () => {
    let start = Math.max(1, page - 1);
    let end = Math.min(totalPages, page + 1);
    const pages = [];

    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };


  if (!form.length && loading) {
    return <Loading />;
  } else {
    return (
      <>
        <div className="mx-auto">
          <div className="">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body text-start">

                    <div className="row justify-content-center align-items-center my-2">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="hotel"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Hotels
                        </label>
                        <div className="dropdown  show-tick show-menu-arrow form-control filter-indexs">
                          <select
                            onChange={(e) => {
                              const selectedValues = e.target.value;
                              if (selectedValues !== "") {
                                hotelChange(selectedValues);
                              }
                            }}
                            id="hotel_id"
                            className=" show-menu-arrow form-control filter-indexs"
                            data-container="body"
                            data-live-search="true"
                            title="Select Hotel"
                            data-hide-disabled="true"
                            data-actions-box="true"
                            data-virtual-scroll="false"
                            multiple=""
                            tabIndex="-98"
                          >
                            <option value="">Select Hotel</option>
                            {hotels.map((hotel) => (
                              <option key={hotel.id} value={hotel.id}>
                                {hotel.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="department"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Department
                        </label>
<div className="dropdown show-tick show-menu-arrow form-control filter-indexs">
  <select
    onChange={(e) => {
      const selectedValues = Array.from(e.target.selectedOptions).map(option => option.value);
      if (selectedValues.length > 0) {
        departmentChange(selectedValues);
      }
    }}
    id="department_id"
    className="show-menu-arrow form-control filter-indexs selectpicker"
    data-container="body"
    data-live-search="true"
    title="Select Department"
    data-hide-disabled="true"
    data-actions-box="true"
    data-virtual-scroll="false"
    multiple=""
    tabIndex="-98"
  >
    <option value="">Select Department</option>
    {departments.map(department => (
      <option key={department.id} value={department.id}>
        {department.name}
      </option>
    ))}
  </select>
</div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label className="text-right control-label col-form-label ">
                          From Creation Date:
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="from_date"
                            id="fromDate"
                            onChange={(e) => handelStartDate(e)}
                            className="form-control Delivery-updater"
                            data-toggle="datepicker"
                            placeholder="yyyy-mm-dd"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label className="text-right control-label col-form-label ">
                          To Creation Date:
                        </label>
                        <div className="input-group">
                          <input
                            type="date"
                            name="to_date"
                            id="toDate"
                            onChange={(e) => handelEndDate(e)}
                            className="form-control Delivery-updater"
                            data-toggle="datepicker"
                            placeholder="yyyy-mm-dd"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me">
                        <label
                          htmlFor="hotel1"
                          className="text-right control-label col-form-label "
                        >
                          Filter By Status
                        </label>
                        <div className="dropdown  show-tick show-menu-arrow form-control filter-indexs">
                          <select
                            id="hotel_id1"
                            className=" show-menu-arrow form-control filter-indexs"
                            data-container="body"
                            data-live-search="true"
                            title="Select Hotel"
                            data-hide-disabled="true"
                            data-actions-box="true"
                            data-virtual-scroll="false"
                            multiple=""
                            tabIndex="-98"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              if (selectedValue !== "") {
                                statusValue(e.target.value);
                              }
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="Open">Open</option>
                            <option value="Accepted">Accepted</option>
                            <option value="signing">Signing</option>
                            <option value="Returned">Returned</option>
                            <option value="Rejected">Rejected</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 col-sm-4 col-6 col-me mt-5">
                      <div className="me-3">
                        <input
                          onClick={filterForms}
                          type="submit"
                          name="submit"
                          className="btn btn-cyan btn-lg py-1 mb-3 "
                        />
                      </div>
                      </div>
                    </div>

                    <div className="border-top"></div>

                    <h4 className="card-title" id="totalCount"></h4>
                    <div
                      id="allowance-list-table_wrapper"
                      className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                      <div className="row justify-content-center align-items-between mt-3">
                        <div className="col-3">
                          <div
                            className="dataTables_length"
                            id="allowance-list-table_length"
                          >
                            <label>
                              <select className="form-control" value={pageSize} onChange={(event)=>rowCount(event)} >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                              </select>
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div
                            id="allowance-list-table_filter"
                            className="dataTables_filter text-end "
                          >
                            <label className="d-inline-flex ">
                              Search:&nbsp;&nbsp;
                              <input
                                type="search"
                                className="form-control form-control-sm "
                                placeholder="Form name"
                                aria-controls="allowance-list-table"
                                onChange={(event)=>searchByName(event)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2 ">
                        <div className="col-12">
                          {/* filtration table..................................................... */}
                          <div className="table-responsive">
                            <table
                              id="allowance-list-table"
                              className="table table-hover bg-black indexTable no-footer dataTable dtr-inline"
                              role="grid"
                              aria-describedby="allowance-list-table_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th

                                    className="tableCol sorting_desc"
                                    id="id"
                                    width="1%"
                                  >
                                    <strong>#</strong>
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="id"
                                    width="9%"
                                  >
                                    Form
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="hid"
                                    width="9%"
                                  >
                                    Hotel Name
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="dep_code"
                                    width="9%"
                                  >
                                    Department Name
                                  </th>
                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="status"
                                    width="9%"
                                  >
                                    Status
                                  </th>

                                  <th

                                    className="tableCol sorting fw-semibold"
                                    id="timestamp"
                                    width="9%"
                                  >
                                    Created At
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                
                                {PaginatedForms.map((form, index) => (
                                  <tr key={index} className="odd " style={{ cursor: 'pointer' }} onClick={()=>viewForm(form.userFormId)} >
                                    <td  valign="top">{(page - 1) * pageSize + index + 1}</td>
                                    <td valign="top">{form.formName} #{form.userFormId}</td>
                                    <td valign="top">{form.hotel.hotelName}</td>
                                    <td valign="top">
                                      {form?.department?.departmentName}
                                    </td>
                                    <td valign="top">
                                      {statusChange(form.userFormStatus)}
                                    </td>
                                    <td valign="top">
                                      {form.createdDate.substring(0, 10)}  {form.createdDate.substring(11, 16)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div
                            id="allowance-list-table_processing"
                            className="dataTables_processing card d-none"
                          >
                            Processing...
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center">
                        <nav>
                          <ul className="pagination">
                            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(page - 1)}>Previous</button>
                            </li>
                            {getPaginationItems()}
                            <li className={`page-item ${page === Math.ceil(allForms.length / pageSize) ? 'disabled' : ''}`}>
                              <button className="page-link" onClick={() => handlePageChange(page + 1)}>Next</button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
