import React, { useEffect, useState } from "react";
import Path from "../Path/Path";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import Save from "../Save/Save";
import axios from "axios";
import { BASE_URL } from "../config";
import './HousingAllowance.css';

export default function HousingAllowance(props) {
  const [user, setUser] = useState("");
  const [id, setFormId] = useState("");
  const [formTypes, setFormTypes] = useState([]);
  const [formDates, setFormDates] = useState([]);
  const [formDropdowns, setFormDropdowns] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);

  const [formGroups, setFormGroups] = useState([]);

  const [path1, setPath1] = useState("Forms");
  const [path2, setPath2] = useState([]);
  const getFormId = (formId) => {
    // console.log( 'data',formId )
    setFormId(formId);
  };
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
    if (user && id) {
      async function getFormData(id) {
        // console.log(id);
        try {
          const { data } = await axios.get(
            `${BASE_URL}/api/RegisterationForms/GetFormById/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          // console.log('form data' ,data);
          setFormTypes(data.formTypes);
          setFormDates(data.formDates);
          setFormDropdowns(data.formDropdown);
          //console.log("data", data);

          //console.log("data.formDropdown.options", data?.formDropdown[0]?.options);
          // console.log(formDropdowns);
          setFormGroups(data.formGroups);
        } catch (error) {
          console.log(error);
        }
      }
      getFormData(id);
    }
  }, [user, id]);

  return (
    <>
      <div
        className={` ${
          props.isOpenSidebar ? "content-body" : "big-content-body"
        }`}
      >
        <div className="content">
          <Path getFormId={getFormId} path1={path1} />
          <form className="bg-body p-3">
            <div className="row mt-3">
              {formDropdowns?.map((dropDown) => (
                <div key={dropDown.id} className="col-sm-4">
                  <div className="hotel ">
                    <label className="text-right control-label col-form-label">*{dropDown?.name}</label>
                    <div className="position-relative dropdown bootstrap-select hotel-select">
                      <select
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                      >
                        <option  value="" defaultChecked>Select {dropDown?.name}</option>
                        {dropDown?.options?.map((dropDownOption) => (
                          <option
                            key={dropDownOption.id}
                            value={dropDownOption.id}
                          >
                            {dropDownOption.elementName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              ))}
            
              {formTypes?.map((string) => (
                <div key={string.id} className="col-sm-4">
                  <label className="text-right control-label col-form-label">
                    *{string.name}
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id={string.id}
                    name="name"
                    placeholder={string.name}
                    required
                  />
                </div>
              ))}


              
                    {formDates.map((date)=>(
                      <div key={date.id} className="col-sm-4">
                    <label className="text-right control-label col-form-label">*{date.name}</label>
                    <input type="date" className="form-control mt-2" id={date.id} data-toggle="datepicker" name={date.name} placeholder="yyyy-mm-dd" required />
                    </div>
                    ))}
                           
      
            </div>
          </form>
          <DragAndDrop />
          <Save />
        </div>
      </div>
    </>
  );
}
