import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {  Form, Field, Formik } from "formik";
import { BASE_URL } from "../config";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import { Loading } from "../Loading/loading";

export default function AdminFormView(props) {
  const [user, setUser] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams()
  const [formId, setFormId] = useState("");
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });
  const [count2, setCount2] = useState(0)
  const [showDateName, setShowDateName] = useState([]);
  const [showFormsStrings, setShowStrings] = useState([]);
  const [showFormsDropdowns, setShowDropdowns] = useState([]);
  const [formGroupHeadShow, setFormGroupHeadShow] = useState([])
  const [groupCount, setGroupCount] = useState(0)
  const [formGroupInputsShow, setFormGroupInputsShow] = useState([])
  const [departments, setDepartments] = useState([]);
  const [hotels, setHotels] = useState([]);

  const [dropdowns, setDropdowns] = useState([]);
  const [selectedInsertData, setSelectedInsertData] = useState([]);

  // values to send to back
  const [values, setValues] = useState({
    selectedInsertData: [],
    selectedInsertHotel: 0,
    selectedInsertDepartment: 0,
    selectedInsertTypeDates: [],
    selectedInsertTypeDropdowns: [],
    selectedInsertTypeFiles: [],
    seletedInsertTypeGroups: []
  });

 
  useEffect(()=>{
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  },[])
  async function getAllDepartments() {
    const { data } = await axios.get(`${BASE_URL}/api/Departments/GetAllDepartments`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    
    setDepartments(data);
    //console.log("GetAllDepartments", data);
  }
  async function getAllHotels() {
    const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`,
    {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
//console.log("GetAllHotels", data);
    setHotels(data);
  }

  useEffect(()=>{
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  },[])

  useEffect(()=>{
  if (id&&user) {
    getAllDepartments()
    getAllHotels()
    getFormId(id)
  }
  },[id,user])

  async function getFormById(formId) {
try {
  if (user) {
   // setEmptyValues()
    //console.log(formId);
    setLoading(true)
    const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetFormById/${formId}`,
     {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    //console.log("getFormById", data);
    if (data) {
      setShowFormName({
        id: data.id,
        name: data.name,
      });
// set inputs value to zero
      values.selectedInsertTypeDropdowns = data.formDropdown.map(({ id }) => ({
        id,
        value: "",
      }));

      values.selectedInsertData = data.formTypes.map(({ id }) => ({
        id,
        value: "",
      }));

      values.selectedInsertTypeDates = data.formDates.map(({ id }) => ({
        id,
        value: "",
      }));
      
      // console.log(values.selectedInsertTypeDropdowns);
      const drop= data.formDropdown.map(({ id,name,options }) => ({
        id,
        name,
        options,
        value: "",
      }));
      const date= data.formDates.map(({ id,name }) => ({
        id,
        name,
        value: "",
      }));
      const string= data.formTypes.map(({ id,name }) => ({
        id,
        name,
        value: ""
      }));
      values.selectedInsertHotel= ""
      values.selectedInsertDepartment= ""
      setShowDropdowns(drop) 
      //setShowDropdowns(data.formDropdown);
      setShowStrings(string);
      setShowDateName(date);



      data.formGroups.groupTypeDropdowns.forEach((Dropdown) => {
        setFormGroupInputsShow(prev => [...prev, { order: Dropdown.order, type: "drop", data: Dropdown }])
        setFormGroupHeadShow(prev => [...prev, { order: Dropdown.order, name: Dropdown.name }])
    })

    data.formGroups.groupTypeNames.forEach((text) => {

        setFormGroupInputsShow(prev => [...prev, { order: text.order, type: "text", data: text }])
        setFormGroupHeadShow(prev => [...prev, { order: text.order, name: text.name }])
   
    })

    data.formGroups.groupTypeDates.forEach((date) => {
        setFormGroupInputsShow(prev => [...prev, { order: date.order, type: "date", data: date }])
        setFormGroupHeadShow(prev => [...prev, { order: date.order,  name: date.name }])
    })



if (data.formGroups.groupTypeDropdowns.length>0||data.formGroups.groupTypeDates.length>0||data.formGroups.groupTypeNames.length>0) {
setGroupCount(1)
}
setCount2(data.formGroups.groupTypeDropdowns.length+data.formGroups.groupTypeDates.length+data.formGroups.groupTypeNames.length) 

    }
  }
  
} catch (error) {
  
}
finally{
  setLoading(false)
}
}

useEffect(() => {
  if (id && formGroupInputsShow.length>0&& formGroupInputsShow.length == count2) {
      const sorted1 = [...formGroupInputsShow].sort((a, b) => a.order - b.order);
      const sorted2 = [...formGroupHeadShow].sort((a, b) => a.order - b.order);
      setFormGroupHeadShow(sorted2)
      setFormGroupInputsShow(sorted1)
      setCount2(0)
  }
}, [id, formGroupInputsShow,formGroupHeadShow]) 
  const getFormId = (formId) => {
    setFormId(formId);
    getFormById(formId);
  };
 

  const addGroup = () => {
    const addgroup = groupCount + 1
    //console.log(addgroup);
    setGroupCount(addgroup)
}
const deleteGroup = () => {
  if (groupCount > 1) {
    const addgroup = groupCount - 1
    //console.log(addgroup);
    setGroupCount(addgroup)
}

}
  
if (!showFormsDropdowns.length&& !showFormsStrings.length&& !showDateName.length && loading) {
  return <Loading/>
}else{
  return <> 
     <Formik initialValues={values} onSubmit={""}>
        <Form className=" p-3 mt-5 w-100  mx-auto">
          {formId ? (
            <div className="">
              <div className="bg-body row p-2 header">
                <h5 className="text-center">{showFormName.name}</h5>
                {/* hotel */}
                <div className="col-md-4 ">
                  <div className="hotel">
                    <label className="text-right control-label col-form-label">
                      hotel
                    </label>
                    <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                      <Field
                        as="select"
                        name="selectedInsertHotel"
                        id="selectedInsertHotel"
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                      >
                        <option value="" disabled>
                          hotel
                        </option>
                        {hotels?.map((hotel) => (
                          <option key={hotel.id} value={hotel.id}>
                            {hotel.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>
                {/* department */}
                <div className="col-md-4">
                  <div className="departments">
                    <label className="text-right control-label col-form-label">
                      department
                    </label>
                    <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                      <Field
                        as="select"
                        name="selectedInsertDepartment"
                        id="selectedInsertDepartment"
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                      >
                        <option value="" disabled>
                          Select department
                        </option>
                        {departments?.map((department) => (
                          <option key={department.id} value={department.id}>
                            {department.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                </div>

                {showFormsDropdowns?.map((dropDown) => (
                  <div key={dropDown.id} className="col-md-4">
                    <div className="hotel">
                      <label className="text-right control-label col-form-label">
                        *{dropDown?.name} 
                      </label>
                      <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                      <Field
                        as="select"
                        name="dropdowns"
                        id="dropdowns"
                        className="selectpicker hotel-select form-select mt-2"
                        aria-label="Default select example"
                        defaultValue={dropDown?.value}
                      > 
                          <option value="" disabled>
                            Select {dropDown?.name}
                          </option>
                          {dropDown?.options?.map((dropDownOption) => (
                            <option
                              key={dropDownOption.id}
                              value={dropDownOption.id}
                            >
                              {dropDownOption.elementName}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                ))}

                {showFormsStrings?.map((string) => (
                  <div key={string.id} className="col-md-4">
                    <label className="text-right control-label col-form-label">
                      *{string.name}
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                    
                        type="text"
                        className="form-control mt-2"
                        id={string.id}
                        defaultValue={string.value}
                        name="selectedInsertData"
                        placeholder={string.name}
                      />
                    </div>
                  </div>
                ))}

                {showDateName?.map((date, index) => (
                  <div key={index} className="col-md-4">
                    <label className="text-right control-label col-form-label">
                      *{date.name}
                    </label>
                    <div className="d-flex align-items-center">
                    <input
                        type="date"
                        className="form-control mt-2"
                        data-toggle="datepicker"
                        name={date.name}
                        defaultValue={date.value} 
                        placeholder="yyyy-mm-dd"
                      />
                    </div>
                  </div>
                ))}
             
                {successMessage && (
                  <div className="alert alert-success mt-5 text-center fw-bold">
                    {successMessage}
                  </div>
                )}
{groupCount>0&&
            <div className="row mt-5">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table id="selected-Items-list-table" className="table table-hover table-bordered table-info text-center">
                                    <thead >
                                        <tr>
                                            <th width="5%">
                                                <button type="button" name="addRow" id="addRow" className="btn btn-light rounded-circle d-flex" onClick={addGroup}>
                                                    <i className="fa-solid fa-database"></i><i className="fa-solid fa-plus"></i>
                                                </button>
                                            </th>
                                            {formGroupHeadShow.map((item, index) =>
                                                <th key={`${index}h`} >{item.name}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody id="outgoing-data">
                                        {Array.from({ length: groupCount }, (_, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="delete">
                                                        <button className="btn" onClick={()=>deleteGroup()}><i className="fa-regular fa-trash-can"></i></button>
                                                    </div>
                                                </td>
                                                {formGroupInputsShow.map((inp, inpIndex) => (
                                                    <td key={inpIndex}>
                                                        {inp.type === "drop" && (
                                                            <select
                                                                className="selectpicker hotel-select form-select mt-2"
                                                                aria-label="Default select example"
                                                            >
                                                                <option value="" defaultChecked>Select {inp.data?.name}</option>
                                                                {inp.data?.options?.map((dropDownOption) => (
                                                                    <option
                                                                        key={dropDownOption.id}
                                                                        value={dropDownOption.id}
                                                                    >
                                                                        {dropDownOption.elementName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        )}

                                                        {inp.type === "text" && (
                                                            <input
                                                                type="text"
                                                                className="form-control mt-2"
                                                                id={inp.data.id}
                                                                name="name"
                                                                placeholder={inp.data.name}
                                                            />
                                                        )}

                                                        {inp.type === "date" && (
                                                            <input
                                                                type="date"
                                                                className="form-control mt-2"
                                                                data-toggle="datepicker"
                                                                name={inp.data.name}
                                                                placeholder="yyyy-mm-dd"
                                                            />
                                                        )}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
}
              </div>
              <div className="mt-5">
              </div>
              <div className="mt-5">
                <DragAndDrop />

              </div>
            </div>
          ) : (
            <div className="bg-body row p-5 header text-center">
              <h4>No form selected! </h4>
            </div>
          )}
          
        </Form>
      </Formik>
    </>
}


}
