import React, { createContext, useState } from 'react';

// Create a context
 export const UserContext = createContext();
export default function UserContextProvider (props){

   const [user, setUser] = useState(JSON.parse(localStorage.getItem("userData")));
   const [token, setToken] = useState(JSON.parse(localStorage.getItem("user")));
const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem("userRole")));

//console.log('userRole context',userRole);
return <UserContext.Provider value={{token,user,setUser,setUserRole,userRole}}>

{props.children}

</UserContext.Provider>
}

