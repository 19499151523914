import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/imgs/tree-white.ico";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import axios from "axios";
import { UserContext } from "../context/userContext";

// import signalRService from '../SignalR/signalr'; // Adjust the import pa
export default function Navbar(props) {
  const navigate = useNavigate();
  const { token, user } = useContext(UserContext);
  //const [user, setUser] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [count, setCount] = useState(null);
  const [notifications, setNotifications] = useState([]);

  async function getAllNotifications() {
    try {
      if (token) {
        const { data } = await axios.get(
          `${BASE_URL}/api/Notification/allNotification`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setNotifications(data);
         //console.log(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getUnReadNotificationCount() {
    try {
      if (token) {
        const { data } = await axios.get(
          `${BASE_URL}/api/Notification/unReadNotificationCount`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCount(data);
      //   console.log(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function markAsRead(notify) {
    if (!notify.isRead && token) {
      try {
        // console.log(token);
        const { data } = await axios.put(
          `${BASE_URL}/api/Notification/markAsRead/${notify.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        getUnReadNotificationCount();
        getAllNotifications();
        // console.log(data);
        if (data) {
          goToNotification(notify);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else {
      goToNotification(notify);
    }
  }

  async function goToNotification(notify) {
    switch (notify.type) {
      case "New Form Create":
        navigate("/dashboard/add-form-level");
        break;

      case "Accepted Form":
        navigate("/my-forms");
        break;

      case "Returned Form":
        navigate("/my-forms");
        break;

      case "Rejected Form":
        navigate("/my-forms");
        break;

      case "New Form Filled":
        navigate(`/form-sign/${notify.content}`);
        break;

      default:
        navigate("/dashboard");
        break;
    }
  }

  useEffect(() => {
    if (token) {
      getAllNotifications();
      getUnReadNotificationCount();
    }
  }, [token]);

  const truncateTitle = (title, maxChars = 30) => {
    const words = title.split(" ");
    const rows = [];
    let currentRow = "";

    words.forEach((word) => {
      if (currentRow.length + word.length + 1 <= maxChars) {
        currentRow += (currentRow.length ? " " : "") + word;
      } else {
        rows.push(currentRow);
        currentRow = word;
      }
    });

    if (currentRow.length) {
      rows.push(currentRow);
    }

    const style = {
      width: `${100}px`,
      height: `${100}px`,
      backgroundColor: "#007bff",
      color: "white",
      fontSize: `${100 / 2}px`,
    };

    return rows;
  };
  function logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("userRole");
    localStorage.removeItem("userData");
    localStorage.removeItem("formId");
    localStorage.removeItem("tokens");
    navigate("/login");
  }

  return (
    <>
      {user && (
        <header className="header-section fixed-top">
          <div className="container-fluid mx-3">
            <div className="row">
            <div className="col-6 p-0 d-flex  pe-5">
            <div className="logo">
                  <Link to="/">
                    <img className="w-100" src={logo} alt="KAiSol Logo" />
                  </Link>
                </div>
        <div className="side-header-toggle me-5">
          <button
            onClick={props.toggleSidebar}
            className="btn-side-toggle border-0 bg-transparent"
            id="sidebarToggleBtn"
          >
            <i className="fa-solid fa-bars" />
          </button>
        </div>
            </div>
              {/* <div className="col-md-1 col-3 d-flex justify-content-start">
                <div className="logo">
                  <Link to="/">
                    <img className="w-100" src={logo} alt="KAiSol Logo" />
                  </Link>
                </div>
              </div> */}
    
              <div className="col-6  d-flex align-items-center ">
                <div className="header-notification w-100 d-flex  justify-content-end ">
                  {/* <a className="database text-decoration-none">
                    <i className="fa-solid fa-database" />
                  </a> */}
                  <div className="notification me-4">
                    <div className="dropdown">
                      <a
                        className=" notification position-relative"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fa-solid fa-bell" />
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {count}
                          <span className="visually-hidden">
                            unread messages
                          </span>
                        </span>
                      </a>
                      <ul className="dropdown-menu bg-white card-nav pop-notification">
                        {notifications.map((notification, index) => (
                          <li
                            key={index}
                            onClick={() => markAsRead(notification)}
                            className={`dropdown-item border-bottom ${
                              notification.isRead
                                ? "transparent"
                                : "bg-notification"
                            }`}
                          >
                            <h6 >
                              {truncateTitle(notification.title).map(
                                (row, index) => (
                                  <div key={index}>{row}</div>
                                )
                              )}
                            </h6>
                            <p>{notification.createdOn.substring(0, 10)} {notification.createdOn.substring(11, 16)} </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="header-notification">
                    {/* <a className="h6 pe-3 tealColor">{user.name}</a> */}
                    {/* <a className="database "> */}
                    <a
                      className="profile-photo position-relative"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {user.name.charAt(0).toUpperCase()}
                    </a>
                    <ul className="dropdown-menu bg-white card-nav overflow-hidden ">
                      <li className="my-1">
                        <Link
                          className=" d-flex "
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <img
                            className=" mx-2"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB90lEQVR4nL1VTUtUYRR+LaUWfewEoxRxChual/d5znWUqbiraBdITZBC7SKkiH5DLqRF0EbwB4yM/gURXETkJlqk9gNqVQtxUTkUxcEzIM6d7ofigQMv8zzv85x7zp1zncsYcRyfFpFRzVKpdModVQC4CmAZwA+SfzX1DGBJzQ4lLiJ1AD9J7pJsAnhhuQSgZUZ3i4qPm/Cm9/5KAj4K4DPJXyGEsdwGJN8B2BaRwW4cAEMAdgC8zSUeQrhs/Z5L4wJ4pVwRGcljcM8u3Urjkrxtg88+C5LTZnA9jSsiN4w7ldkghFCzFj1O4wJ4YtyJzAYi0kfyK4APzrkT3Xj1ev0kyY8kv8Rx3OvyBMnnVtlr51xPAqUHwBvr/7Nc4vsEls1kRQdeLpfP1Gq1s3oGsGpYs0sB6aGPTXKtvSISci13a9pB8iaALRPa1T8TgIalnluGbeqblEscwFOSfwB801mIyPmDHP3N9tJ35ZKcySQuIo9scO9FZCBDMRcArOudEMLDNPERW8ufqtXquUwVOeeUS3JD73rvh/9Xja7h31EUXcsq3g4A3lq16JLCe99vO77hCgb3vhkt1eoAQwgPrPd3DmEwaW/W/SRwVsFKpXKxqEEURZfM4GUHCGDetmJfUQPZ22HahfkOkOSCgkXF9+noEywcm8E/ehHHfj4gb74AAAAASUVORK5CYII="
                          />
                          <div>{user.name}</div>
                        </Link>
                      </li>
                      <li className="my-1">
                        <Link className=" d-flex" to={"/reset-password"}>
                          <img
                            className=" mx-2"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3klEQVR4nO2UsQrCQAyGA+oDOPVSxEkER9+gg8/i6it0dHVw8BV8BQfBnLo4u/gO3TUHkbQULVKo0utiPwjkCJef5C4BaGkKtriRC/S8CTiL4qzZCQ37HgVQmMxNzmZSCDJhrMEy331pbE1yP4bTN4Eg0mSffjhja5ZVrCjg4T1cltg5wgX4gK1JtGLwhRzCMfwP4rNczr5m4iW5I5wz4SMftNqQLXSYzKowLIRxNQsizVHmp+hYa1t+WguUTX2Z/6riNBgx4TW/WGuLcnTV6sr1JqDIHrpscZ0eWqABnuOvFPeX/0X5AAAAAElFTkSuQmCC"
                          />
                          <div>reset password</div>
                        </Link>
                      </li>
                      <li className="my-1 " onClick={logOut}>
                        <Link className="d-flex">
                          <img
                            className=" mx-2"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVR4nGNgGDaAL7B7Al9g9wFSMAOJFhzgD+z5TwpmGMQWdBXw+3c7YMVUscC/2wGXOn4sFvxnqGd6zeDuRxML/jMwML5h8Jz2lsHz/xsGzw6qW/CGwSMXZDgSbqDIAnTwnyGU7Q2D5yZkS7D6BJ8F7xh85N4yeJTjwm8YPOreMnh+wesTfBa8ZnB3QNNMFEbxCW0s8KgnyoIPDN5KINfgwm8ZPPvfMHj8JDuISI9kJJcPumTKjzejYXE5pRaQV1TQvLALHKL1wQSaVpkMQxUAAG8f2I5YaZUSAAAAAElFTkSuQmCC"
                          />
                          <div>logout</div>
                        </Link>
                      </li>
                    </ul>
                    {/* <i className="fa-solid fa-user"></i> */}
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}
