import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../config';

export default function Path({getFormId,path1}) {
  const [user,setUser] = useState('');
  const [formId,setFormId] = useState('');
  const [forms,setForms] = useState([]);

  const sendFormId =(id)=>{
    getFormId(id)
  }


    useEffect(()=>{
        const token = JSON.parse(localStorage.getItem('user'))
        setUser(token)  
        if (user) {  
            async function getAllFormsName(){
                const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetAllForms`,
                {
                  headers:{
                    Authorization:`Bearer ${user}` 
                  }  
                })

            setForms(data.filter((c)=>c.hasLevel))
        }
        getAllFormsName()
        }
    },[user])

 
const handleSelectChange = (event)=>{
    const selectedFormId = event.target.value;
    // console.log(selectedFormId);
     setFormId(selectedFormId);
    sendFormId(selectedFormId)
}
  return (
    <>
    <div className="header mb-4">
        <div className="row align-items-center m-0">
            <div className="col-md-6">
            <div className="current-title d-flex align-items-center">
                <h5 className="m-0">Dashboard</h5>
                <div className='d-flex'>
                <p className="m-0 d-flex align-items-center"><i className="fa-solid fa-angle-right mx-1" />{path1}</p>
                <p className="m-0 d-flex align-items-center cursor-pointer"><i className="fa-solid fa-angle-right mx-1" />
                {/* render forms */}
               <select value={formId} onChange={handleSelectChange} className="form-select border-0 cursor-pointer" aria-label="Default select example">
               <option value="" disabled>
                  Select form
                </option>

                {forms.map((form,index)=>(
                

<option value={form.id} key={form.id} className='m-5'>{form.name}</option>
))}

             </select>
                </p>
                </div>
            </div>
            </div>
            {/* <div className="col-md-6">
                <div className="next-content float-end">
                    <a href="/">First Form</a>
                </div>
            </div> */}
        </div>
    </div>
  </>
  );
}
