import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from "../../config";
export const CreateDepartment = () => {
  const { id } = useParams()
  const [user, setUser] = useState("");
  const navigate = useNavigate()
  const [Hotels,setHotels]=useState([]);
  const [admin,setAdmin]=useState("");
  const [formHeader,setFormHeader]=useState("");

const [initialValues, setInitialValues] = useState({
  id:undefined,
  name: "",
  description: "", 
  adminId: "", 
  typeHotelId: '',
})
useEffect(()=>{
  const token = JSON.parse(localStorage.getItem("user"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  setUser(token);
  setAdmin(userData?.id);
  //console.log("/////---",userData?.id);
},[])

useEffect(()=>{
  if (id) {
    setFormHeader('Update Department')
  }else{
    // changeProductImg(true)
    setFormHeader('Create Department')
  }
  // async function fetchAdminsDetails(){
  //   try{
  //     if (user) {
  //       const {data} = await axios.get(`${BASE_URL}/api/Auth/GetAllUsers`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${user}`,
  //         },
  //       }
  //       )
  //       console.log("Admins",data);
  //       setAdmins(data)
  //     }
  //     }catch(err){
  //       console.error('Error fetching Hotels:', err);
  //     }finally {
  //      // setLoading(false); // Update loading state
  //     }
  //   }


    async function fetchHotelsDetails(){
        try{
          if (user) {
            const {data} = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
            )
            //console.log("Hotels",data);
           setHotels(data)
          }
          }catch(err){
            console.error('Error fetching Hotels:', err);
          }finally {
           // setLoading(false); // Update loading state
          }
        }


if (user) {
  
  fetchHotelsDetails()
}
    // fetchAdminsDetails()
},[user])

useEffect(()=>{
    async function fetchDepartmentDetails(){
    try{
      if(id&&user){
        const {data} = await axios.get(`${BASE_URL}/api/Departments/GetDepartmentById/${id}`,
       {
                        headers: {
                            Authorization: `Bearer ${user}`,
                        }
                    }
      )
        //console.log(data);
        initialValues.id=data.id
        initialValues.name=data.name
        initialValues.description=data.description
        initialValues.adminId=data.adminId
        initialValues.typeHotelId=data.typeHotelId
        setInitialValues({
          id:data.id,
          name: data.name,
          description: data.description, 
          adminId: data.adminId, 
          typeHotelId: data.typeHotel,
          })
        }
      }catch(err){
        console.error('Error fetching department:', err);
      }finally {
       // setLoading(false); // Update loading state
      }
    }
    fetchDepartmentDetails()
  },[id,user])

  async function createDepartment(values, { setSubmitting }) {
  
    try {


     if(user){
  //console.log("5555555",admin);
  setInitialValues({
   id:!id?0:parseInt(id),
   name: values.name,
   description: values.description, 
   adminId: admin, 
   typeHotelId: values.typeHotelId,
       })
       initialValues.id=!id?0:parseInt(id)
       initialValues.name= values.name
       initialValues.description= values.description 
       initialValues.adminId=admin
       initialValues.typeHotelId= parseInt(values.typeHotelId)
       //values.id=!id?0:parseInt(id),
  //console.log(values);    
  if (id) {
    //console.log(initialValues);  
      const  data  = await axios.put(`${BASE_URL}/api/Departments/UpdateDepartment`,
          initialValues,  
          {
                    headers: {
                      Authorization: `Bearer ${user}`,
                          }
          }
                );
    if (data) {
            
        //console.log( 'updateProduct' ,data);
      navigate('/dashboard/departments')
    }
   } 
  else
  {
  
    const data  = await axios.post(
      `${BASE_URL}/api/Departments/AddDepartment`,
      initialValues, {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              }
            );
            if (data) {
      
              //console.log( 'AddProduct' ,data);
              navigate('/dashboard/departments')
            }
  }
      }

    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({

    id: Yup.mixed(),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    //adminId: Yup.string().required("Admin is required"),
    typeHotelId: Yup.number().required("Hotel is required"),
  });

  return (
    <section className="p-5">
      <h2 className="text-center text-cap">{formHeader}</h2>
      
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createDepartment}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
    
  
            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Name:
              </label>
              <Field
                type="text"
                name="name"
                id="name"
                className={`form-control ${
                  errors.name && touched.name && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Description:
              </label>
              <Field
                type="text"
                name="description"
                id="description"
                className={`form-control ${
                  errors.description && touched.description && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="invalid-feedback"
              />
            </div>

    
            <div className="mb-3">
              <label htmlFor="hotel" className="form-label">
                Hotels:
              </label>
              <Field
                as="select"
                name="typeHotelId"
                id="typeHotelId"
                className={`form-control ${
                  errors.typeHotelId && touched.typeHotelId && "is-invalid"
                }`}
              >
               <option value="" disabled>
                  Select Hotel
                </option>
                {Hotels.map((hotel) => (
                  <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
                ))}
              </Field>
              <ErrorMessage
                name="typeHotelId"
                component="div"
                className="invalid-feedback"
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="hotel" className="form-label">
                Admin:
              </label>
              <Field
                as="select"
                name="adminId"
                id="adminId"
                className={`form-control ${
                  errors.adminId && touched.adminId && "is-invalid"
                }`}
              >
               <option value="" disabled>
                  Select Admin
                </option>
                {Admins.map((admin) => (
                  <option key={admin.id}  value={admin.id}>{admin.fullName}</option>
                ))}
              </Field>
              <ErrorMessage
                name="adminId"
                component="div"
                className="invalid-feedback"
              />
            </div> */}
            <button type="submit" className="btn btn-dark"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      
    </section>
  );
};
