import React, { useEffect, useState } from "react";
import { Loading } from "../Loading/loading";
import axios from "axios";
import { BASE_URL } from "../config";
import { useParams } from "react-router-dom";

export function UserPermission() {
    const { id } = useParams()
    const [user, setUser] = useState("");
    const [currentUser, setCurrentUser] = useState("");
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [userData, setUserData] = useState({});
    const [values, setValues] = useState([]);
    const [successMessage, setSuccessMessage] = useState("");
    const [errMessage, setErrMessage] = useState("");
    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem("userRole")));


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("user"));
        const curUser = JSON.parse(localStorage.getItem("userData"));
        setCurrentUser(curUser)
        setUser(token);
    }, [])

    async function getUserById() {
        try {
            const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                }
            );
            setUserData(data);
            //console.log(data);
        } catch (error) {
            console.log(error);
        }

    }

    async function getForms() {
        try {
            setLoading(true)
            const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetRelatedForms/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                }
            );
            setForms(data.filter((k) => k.hasLevel));
            //console.log("Forms", data.filter((k) => k.hasLevel));
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false)
        }
    }


    async function getUserPermissionById() {
        try {

            const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetUserPermission/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${user}`,
                    },
                }
            );

            const value = data.map(({ formId, userId, view, create }) => ({
                formId,
                userId,
                view,
                create
            }))
            setValues(value);

            //console.log("User Form", data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (user && id) {
            getForms();
            getUserById()
            getUserPermissionById()
        }
    }, [user, id])
    // const [x,setX]=useState(0)
    // useEffect(() => {
    //   if (x==0&&values.length>0&&forms.length>0) {
    //         console.log("here");
    //         // values.forEach(val => {
    //         //     if (forms.some(form=>form.id!==val.formId)) {       
    //         //       setValues(values.filter(valu=>valu.formId!==val.formId))
    //         //     }
    //         // });
    //      setX(1)
    //     }
    // }, [values,forms])


    const handleCheckboxChange = (type, formId) => {
        //console.log("111", forms.some(form => (form.formId === formId)));
        if (values.some(form => form.formId === formId)) {
            if (type == "create") {
                if (values.some(form => form.formId === formId&&!form.view&&form.create)) {
                    setValues(values.filter(valu=>valu.formId!==formId))
                }
                setValues(perv => perv.filter((form) => {
                    if (form.formId === formId) {
                        form.create = !form.create
                    }
                    return true
                }))
            }
            else {
                if (values.some(form => form.formId === formId&&form.view&&!form.create)) {
                    setValues(values.filter(valu=>valu.formId!==formId))
                }
                setValues(perv => perv.filter((form) => {
                    if (form.formId === formId) {
                        form.view = !form.view
                    }
                    return true
                }
                ))
            }
            
        } else {
            if (type == "create") {
                const value= { formId,userId:id,view:false,create:true}
                setValues(perv => [...perv,value])
            }
            else {
                const value= { formId,userId:id,view:true,create:false}
                setValues(perv => [...perv,value])
            }
        }
  
    };


     async function sendUserPermission(){
      try {
        setSaveLoading(true)
        //console.log(values);
          const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/UserPermission`,
          {permissions:values},
           {
          headers: {
             Authorization: `Bearer ${user}`,
           },
         }
       );
       //console.log(data);
       setSuccessMessage("Permission add successfully")
       setErrMessage("")
      }catch (error) {
          console.log(error);
          setSuccessMessage("")
          setErrMessage("Faild to add permission")
      }
      finally{
        // setLoading(false)
        setSaveLoading(false)
      }
      }
    if (loading) {
        return <Loading />
    } else {
        return <>
            <div className="text-center p-5">
                <div className="pb-4">
                    <h3>{userData.username}</h3>
                </div>
                <div className="container d-flex">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Form</th>
                                <th scope="col">View</th>
                                <th scope="col">Create</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms.map((form, index) => (
                                <tr key={form.id}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                        <label className="form-check-label" htmlFor={form.id}>
                                            {form.name}
                                        </label>
                                    </td>
                                    <td className="d-flex justify-content-center">
                                        <div className="d-block">
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor={`label1-${form.id}`}>View</label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={`label1-${form.id}`}
                                                    onChange={() => handleCheckboxChange("view", form.id)}
                                                    checked={values.some(f => f.formId == form.id && f.view)?true:false}
                                                />
                                            </div>

                                        </div>
                                    </td>
                                    <td >
                                        <div className="">
                                    {(form.myDepartment||(userData.id==currentUser.id)) &&
                                                <div className="">

                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        id={`create-${form.id}`}
                                                        value={form.id}
                                                        onChange={() => handleCheckboxChange("create", form.id)}
                                                        checked={values.some(f => f.formId == form.id && f.create)?true:false}
                                                    />
                                                                                                        <label className="form-check-label" htmlFor={`create-${form.id}`}>
                                                        Create
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                {errMessage && (
                    <div className="alert alert-danger mt-5 text-center fw-bold">
                        {errMessage}
                    </div>
                )}
                {successMessage && (
                    <div className="alert alert-success mt-5 text-center fw-bold">
                        {successMessage}
                    </div>
                )}
                <button onClick={sendUserPermission} className="btn btn-dark border rounded-0" disabled={saveLoading}>
                    {saveLoading ? "Saving..." : "give permission"}
                </button>
            </div>
        </>
    }
}