import React from 'react'
import './Save.css'

export default function Save() {
  return (
    <>
        <div className="form mt-3 text-center header">
            <button type="submit" className="btn btn-dark px-5 py-1 fs-5">Save</button>
        </div>

    </>
  )
}
