import Style from './style.module.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { useParams } from 'react-router-dom';
export const AddFormLevel = () => {
  const [user, setUser] = useState("");
  const [forms, setForms] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [levels, setLevels] = useState([]);
  const [formId, setFormId] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [hotelId, setHotelId] = useState('');
  const [createdBy, setCreatedBy] = useState(null);
  const [emptyElement, setEmptyElement] = useState("");
  const [existMsg, setExistMsg] = useState("");
  const [userDepartmentId, setUserDepartmentId] = useState('');
  const [formLevels, setFormLevels] = useState([]);
  const [displayLevels, setDisplayLevels] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, [])

  const getAllUserForm = async () => {
    try {
      if (user) {
        const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetAllForms`,
          {
            headers: {
              Authorization: `Bearer ${user}`
            }
          })
        //console.log('dataaaaaaaaaa',data);
        setForms(data.filter((c) => !c.hasLevel))
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllUserForm()
  }, [user])



  useEffect(() => {
    async function getHotels() {
      try {
        const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        // console.log(data);
        setHotels(data);
      } catch (error) {
        console.error(error);
      }
    }

    if (user) {
      getHotels();
      getAllLevels()
    }
  }, [user]);

  const getDepartments = async (event) => {
    try {
      const hotelId = event.target.value
      setHotelId(hotelId)
      const { data } = await axios.get(
        `${BASE_URL}/api/Departments/GetAllDepartmentsByHotelId/${hotelId}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log(userDepartmentId);
      const depart = data.filter((dep) => dep.id != userDepartmentId)
      setDepartments(depart);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllLevels = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/RegisterationForms/GetAllLevels`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log(data);
      setLevels(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    //console.log('levels', formLevels);
  }, [formLevels])
  const addFormLevel = async () => {
    //console.log('levels', formLevels);
    //console.log('ID', formId);

    if (formId) {
      try {
        setLoading(true)
        const { data } = await axios.post(
          `${BASE_URL}/api/RegisterationForms/AddFormLevel2/${formId}`,
          formLevels
          , {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        getAllUserForm()
        setHotelId('')
        setDisplayLevels([])
        setLevels([])
        setEmptyElement('')
        //console.log('AddFormLevel', data);
      } catch (error) {
        console.error(error);
      }
      finally {
        setLoading(false)
      }
    }
    else {
      setEmptyElement('Please choose form first!')
    }
  };

  const handleSelectChange = (event) => {
    const selectedFormId = event.target.value;
    //console.log(selectedFormId);
    setFormId(selectedFormId);
    //  forms.filter((form)=> {
    //  if (form.userFormId === parseInt(selectedFormId)) {
    //     //console.log(form.userFormId);
    //     getUserData(form.createdBy)
    //  }

    // })

  }

  const handleDepartmentChange = (event) => {
    const selectedDepartmentId = event.target.value;
    //console.log(selectedFormId);
    setDepartmentId(selectedDepartmentId);
    setEmptyElement('')
    // console.log(selectedDepartmentId);   
  }

  const addToList = () => {
    setHotelId('')
    setDepartmentId('')
    setDepartments([])


    const searchIn = formLevels.find(formLevel => formLevel.departmentId == departmentId);
    const hotel = hotels.find(hotel => hotel.id == hotelId);
    const department = departments.find(department => department.id == departmentId);

    if (formLevels.length < levels.length) {
      if (departmentId && !searchIn) {
        setEmptyElement('')
        setExistMsg('')
        setFormLevels((prev) => [...prev, {
          departmentId: departmentId,
          levelId: levels[formLevels.length].id
        }])
        setDisplayLevels((prev) => [...prev, {
          level: levels[formLevels.length].levelOrder,
          levelId: levels[formLevels.length].id,
          department: department.name,
          hotel: hotel.name
        }])
      }
      else if (!departmentId) {
        //  console.log("Please choose a department!");
        setEmptyElement("Please choose a department!")
      }
      else {
        // console.log("Department already exist!");
        setEmptyElement("Department already exist!")
      }
    }
  }
  useEffect(() => {
    //console.log(formLevels);
    //console.log('displayLevels', displayLevels);
  }, [formLevels, displayLevels])
  const deleteLevel = (level) => {
    setDisplayLevels(displayLevels.filter((item) => item.levelId != level))
    setFormLevels(formLevels.filter((item) => item.levelId != level))
  }
  return <>
    <div className="container m-auto row justify-content-between gy-3 mt-5">
      <div className="col-md-4">
        <div className="item bg-white text-center rounded">
          {/* render forms */}
          <select value={formId} onChange={handleSelectChange} className="form-select border-0 cursor-pointer" aria-label="Default select example">
            <option value="" disabled>
              Select form
            </option>


            {forms.map((form) => (



              <option key={form.id} value={form.id} className='m-5'>{form.name}</option>

            ))}

          </select>
        </div>
      </div>
      <div className="col-md-4">
        <div className="item bg-white text-center rounded">
          <select value={hotelId} onChange={getDepartments} disabled={formId == ''} className="form-select border-0 cursor-pointer" aria-label="Default select example">
            <option value="" disabled>
              Select hotel
            </option>


            {hotels.map((hotel) => (


              <option key={hotel.id} value={hotel.id} className='m-5'>{hotel.name}</option>
            ))}

          </select>
        </div>
      </div>
      <div className="col-md-4">
        <div className="item bg-white text-center rounded">
          <select value={departmentId} onChange={handleDepartmentChange} disabled={departments.length == 0} className="form-select border-0 cursor-pointer" aria-label="Default select example">
            <option value="" disabled>
              Select department
            </option>


            {departments.map((department) => (

              <option key={department.id} value={department.id} className="m-5">
                {department.name}
              </option>



            ))}

          </select>
        </div>
      </div>
    </div>

    {emptyElement ?
      <div className=' mt-5'>
        <p className="alert alert-dark w-25 m-auto" role="alert">
          {emptyElement}
        </p>
      </div>
      : null}

    {existMsg ?
      <div className=' mt-5'>
        <p className="alert alert-dark w-25 m-auto" role="alert">
          {existMsg}
        </p>
      </div>
      : null}

    <div className='text-center mt-5'>
      <button onClick={addToList} className='btn btn-dark px-5 py-1 fs-5'>add level</button>
    </div>
    {displayLevels.length ?
      <div className='container mt-5'>
        <table className={`table table-striped`}>
          <thead className={`${Style.bgBrown} ${Style.textWhite}`}>
            <tr >
              {/* <th scope="col">
      <div className='border rounded-circle d-flex justify-content-center align-items-center'>
    <i class="fa-solid fa-trash"></i>
      </div>
      </th> */}
              <th scope="col">level order</th>
              <th scope="col">hotel</th>
              <th scope="col">department</th>
            </tr>
          </thead>
          <tbody>
            {displayLevels.map((level) => (

              <tr key={level.level}>
                {/* <th onClick={()=>deleteLevel(level.levelId)} scope="row"><i class="fa-solid fa-trash"></i></th> */}
                <td>{level.level}</td>
                <td>{level.hotel}</td>
                <td>{level.department}</td>
              </tr>

            ))}
          </tbody>
        </table>
        <div className='text-center'>
          <button onClick={addFormLevel} className='btn btn-dark px-5 py-1 fs-5' disabled={loading}>
            {loading ? "submiting levels" : "submit levels"}


          </button>
        </div>
      </div>
      : null}

  </>

}