import axios from "axios";
import { useState, useEffect,useRef } from "react"
import { BASE_URL } from "../config";
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { FormLevel } from "../formLevel/form-level";
import UserFormView from "../FormView/user-form-view";
import logo from '../../assets/imgs/2f4f053102624702e5ea93ae634f0eaaPdyDYuYVlhcozkF3-1.png'
import { Loading } from "../Loading/loading";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const FormToSign = () => {
  const [user, setUser] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const { id } = useParams()
  const [disMiss, setdisMiss] = useState(false);
  const navigate  = useNavigate()
  const [levels, setLevels] = useState([]);
  const [currLevel, setCurrLevel] = useState(false);
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });
  const [formData, setFormData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [currentUserDepartment, setCurrentUserDepartment] = useState([]);
  const [userForm, setUserForm] = useState([]);
  const [nextForm, setNextForm] = useState("");
  const [values, setValues] = useState({
    Comment:"",
    ReasonRemarks:"",
    File:null
  });
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    const current = JSON.parse(localStorage.getItem("userData"));
    setCurrentUser(current)
    setUser(token);
  }, [])

  async function getForms() {
    try {
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetUserFormsToSign/${currentUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setUserForm(data);        
//console.log("kkkk",data);      

    } catch (error) {
      console.log(error);
   
    }

  }


  useEffect(() => {
    if (id && user) {

      async function getUserFormById() {
        try {
setReload(true)
          const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetUserFormById/${id}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          //  if (data) {
          setShowFormName({
            id: data.formId,
            name: data.formName,
          });
          //console.log('getUserFormById', data);
          setFormData(data)
          setCreatedBy(data.createdBy)

          //  }
        } catch (error) {
          console.log(error);
        }finally{
          setReload(false)
        }

      }
      getUserFormById();
      getForms()
      
    }
  }, [id, user])

  useEffect(() => {
    if (id && userForm.length>0) {
      const index = userForm.findIndex(form => form.formId === parseInt(id));
      //console.log("66666666666666666666666",id);
      if (index !== -1) {
if (userForm.length==index+1) {
  setNextForm(userForm[0]?.formId)
} else {
  setNextForm(userForm[index+1]?.formId)
}
        //console.log(`Form found at index: ${index}`);
      } else {
        setNextForm("")
        //console.log("Form not found");
      }
    }
  }, [id, userForm])


const gotToNext=()=>{
  
  if (nextForm!=="") {
    navigate(`/form-sign/${nextForm}`)
  }
}
async function GetUserById2(created) {
  try {

    const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${created}`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    //console.log('GetUserById', data);
    setCurrentUserDepartment(data.newDepartmentId)
  } catch (error) {
    console.log(error);
  }

}

  useEffect(() => {
    if (id && user && createdBy) {

      async function GetUserById(created) {
        try {

          const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${created}`,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          //console.log('GetUserById', data);
          setUserData(data)
        } catch (error) {
          console.log(error);
        }

      }
      GetUserById(createdBy)

       GetUserById2(currentUser.id)

    }
  }, [id, user, createdBy])

  const getStatus = () => {
    if (formData.userFormStatus?.isAccepted) {
      return "Accepted";
    }
    else if (formData.userFormStatus?.isRejected) {
      return "Rejected";
    }
    else if (formData.userFormStatus?.isPending) {
      return "Pending";
    }
    else if (formData.userFormStatus?.isOpen) {
      return "Waiting admin confirm";
    }
    else {
      return "Returned to created User";
    }
  };

  const contentRef = useRef();
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const AddComment=async()=>{
    
    let formData = new FormData();
    formData.append(`comment`, values.Comment);
    formData.append(`reasonRemarks`, values.ReasonRemarks);
    if (values.File) {
      formData.append(`file`, values.File);
    }
    
    try {
      if (values.Comment||values.File||values.ReasonRemarks) {
        //console.log(values);
        
      setdisMiss(true)
  
      
      const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/CommentForm/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-Type': `multipart/form-data`
          },
        }
      );
      //console.log(data);
      
     window.location.reload();
    }
    } catch (error) {
      console.log(error);
    }
    finally {
      setdisMiss(false)

    }

  }
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setValues({
      ...values,
      [name]: type === "file" ? files[0] : value
    });
  };
  
  const handleDownloadPdf = () => {
      setIsGeneratingPdf(true); // Set state to hide the button

      setTimeout(() => {
          const input = contentRef.current;

          html2canvas(input, { scale: 2 }).then(canvas => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF('p', 'mm', 'a4');
              const imgWidth = 210; // A4 width in mm
              const imgHeight = (canvas.height * imgWidth) / canvas.width;

              pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
              pdf.save(`${formData.formName} #${formData.userFormId}`);

              setIsGeneratingPdf(false); 
          });
      }, 100);
};

const GetDiscrepancyRequest = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/api/RegisterationForms/DiscrepancyRequest/${id}`,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );
    setLevels(data)
    //console.log("Levels", data);
  } catch (error) {
    console.log(error);
  }
};

useEffect(() => {
  if (levels.length > 0&&currentUserDepartment) {

    levels.filter((level) => {
      if (
        !level.isAccepted &&
        !level.isRejected &&
        !level.isRetunedToCreatedUser &&
        !level.isRetunedToDepartment &&
        currentUserDepartment==level.newDepartmentId
      ) {  
        setCurrLevel(true);
        //console.log(level.levelOrder);
      }
    }); 
  }
}, [levels,currentUserDepartment]);
useEffect(() => {
  if (user) {
    GetDiscrepancyRequest();
  }
}, [user]);




  if (reload) {
    return <Loading/>
  } else {   
  return <>
  <div  ref={contentRef}  >
  <div className="row justify-content-between align-items-center my-5  px-5">
      <div className="col-md-5 ">
        <img src={logo} className="w-55" alt="" />
      </div>
      <div className="col-md-5 p-3">
     
        <h4>{formData.formName} #{formData.userFormId}
        {!isGeneratingPdf && (
  <i onClick={handleDownloadPdf} className="fa-solid fa-file-pdf fs-3 ms-3 text-danger"></i>       
 )}</h4>
        <h6><strong>Hotel Name:</strong> {userData.hotelName}</h6>
        <h6><strong>Department Name:</strong> {userData.departmentName}</h6>
        <h6><strong>Status:</strong> {getStatus()}</h6>
        <h6><strong>Create By:</strong> {userData.username}</h6>
        <h6><strong>Create At:</strong> {formData.createdDate?.substring(0, 10)} {formData.createdDate?.substring(11, 16)}</h6>
      </div>
    </div>
    
    <div >
      <UserFormView data={formData} />
      {(!isGeneratingPdf &&currLevel ) && ( 
        <div className="d-flex justify-content-end pe-5">
        <button className="btn btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
      Add comment <i className="fa-regular fa-comment" >
      </i>
    </button>
        </div>
    )}


    </div>
    <div>
      <FormLevel data={formData} />
    </div>
    {!isGeneratingPdf&&(
    <div className="rotate-button">
    <button className="btn-total">{userForm.length} Total</button>
    <button className="btn-next m-1" onClick={()=>gotToNext()}>Next Form</button>
    </div>
    )}
  </div>
  
  <div
  className="modal fade"
  id="staticBackdrop1"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabIndex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Comment</h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <label htmlFor="Comment" className="form-label">Comment</label>
          <textarea
            className="form-control"
            id="Comment"
            name="Comment"
            defaultValue={values.Comment}
            onChange={handleChange}
            placeholder="Comment"
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="Reason" className="form-label">Reason</label>
          <textarea
            className="form-control"
            id="ReasonRemarks"
            name="ReasonRemarks"
            defaultValue={values.ReasonRemarks}
            onChange={handleChange}
            placeholder="Reason"
          ></textarea>
        </div>
        {/* <div className="mb-3">
          <label htmlFor="Room" className="form-label">Room</label>
          <input
            type="text"
            className="form-control"
            id="Room"
            name="Room"
            defaultValue={values.Room}
            onChange={(e)=>handleChange(e)}
            placeholder="Room"
          />
        </div> */}
        <div className="mb-3">
          <label htmlFor="File" className="form-label">Attach File</label>
          <input
            type="file"
            id="File"
            name="File"
            className="form-control"
            onChange={handleChange}
          
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => AddComment()}
          disabled={disMiss}
        >
          {disMiss ? "Sending..." : "Send"}
        </button>
      </div>
    </div>
  </div>
</div>


  </>
  }
}