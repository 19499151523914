import Style from './form-level.module.css'
import { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import axios from "axios";
import { BASE_URL } from "../config";
import { Link, useParams } from "react-router-dom";
import AcceptedStatus from '../../assets/imgs/accepted icon.png'
import RejectedStatus from '../../assets/imgs/Rejected icon.jpg'

export const FormLevel = ({ data }) => {
  const { id } = useParams();
  const [user, setUser] = useState("");
  const [levels, setLevels] = useState([]);
  const [currLevel, setCurrLevel] = useState(0); // Default to a high value
  const [userDepartmentId, setUserDepartmentId] = useState(0);
  const [userData, setUserData] = useState('');
  const [rejected, setRejected] = useState(false);
  const [disMiss, setdisMiss] = useState(false);
  const [comment, setComment] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [hotels, setHotels] = useState([]);
  const [run, setrun] = useState(false);




  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    const data = JSON.parse(localStorage.getItem("userData"));
    setUserData(data)
    setUser(token);
  }, []);

  useEffect(() => {
    if (userData) {
      getUserData(userData.id)
    }
  }, [userData]);
  const getUserData = async (id) => {
    try {
      if (user && id) {
        const { data } = await axios.get(`${BASE_URL}/api/Reports/GetUserById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log(data);
        setUserDepartmentId(data.newDepartmentId)
      }

    } catch (error) {
      console.log(error);
    }
  }


  const GetDiscrepancyRequest = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/RegisterationForms/DiscrepancyRequest/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      sortLevelOrder(data);
      //console.log("Levels", data);
    } catch (error) {
      console.log(error);
    }
  };
  const acceptForm = async () => {
    try {
      setrun(true)
      const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/AcceptForm/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log(data);
      window.location.reload();
      GetDiscrepancyRequest();
    } catch (error) {
      console.log(error);
    }
    finally{
      setrun(false)
    }
  };

  const RejectForm = async () => {
    try {
      setrun(true)
      const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/RejectForm/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log(data);
      window.location.reload();
      GetDiscrepancyRequest();

    } catch (error) {
      console.log(error);
    }
    finally{
      setrun(false)
    }
  };

  const ReturnFormToUserCreated = async () => {
    if (comment) {
      //console.log(id);
      setdisMiss(true)
      setrun(true)
      try {
        const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/ReturnFormToUserCreated/${id}?comment=${comment}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        setComment("")
        window.location.reload();
        //console.log(data);
        window.location.reload();
        GetDiscrepancyRequest();
      } catch (error) {
        console.log(error);
      }
      finally {
        setdisMiss(false)
        setrun(false)

      }
    }
  };


  // const ReturnFormToDepartment = async () => {
  //   if (comment && departmentId) {
  //     setdisMiss(true)
  //     try {
  //       const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/ReturnFormToDepartment/${id}/${departmentId}?comment=${comment}`,
  //         {},
  //         {
  //           headers: {
  //             Authorization: `Bearer ${user}`,
  //           },
  //         }
  //       );
  //       setComment("")
  //       console.log(data);
  //       window.location.reload();
  //       GetDiscrepancyRequest();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     finally {
  //       setdisMiss(false)

  //     }
  //   }
  // };



  useEffect(() => {
    if (user) {
      GetDiscrepancyRequest();
    }
  }, [user]);

  useEffect(() => {
    if (levels.length > 0) {
      //console.log(levels);
      var num = 1000000;
      levels.filter((level) => {
        if (
          !level.isAccepted &&
          !level.isRejected &&
          !level.isRetunedToCreatedUser &&
          !level.isRetunedToDepartment &&
          level.levelOrder < num
        ) {
          num = level.levelOrder;
          setCurrLevel(level.levelOrder);
          //console.log(level.levelOrder);
        }
      });
    }
  }, [levels]);


  const sortLevelOrder = (arr) => {
    const sortedLevels = [...arr].sort((a, b) => a.levelOrder - b.levelOrder);
    setLevels(sortedLevels);
    //console.log("555555555555555555555555555555",arr);
    
  };
  const getStatus = (level) => {
    if (level.isAccepted) {
      return "Accepted";
    } else if (level.isRejected) {
      if (!rejected) {
        setRejected(true);
      }
      return "Rejected";
    } else if (level.isRetunedToCreatedUser || level.isRetunedToDepartment) {
      return "Returned to created user";
    } else {
      return "";
    }
  };

 
  useEffect(() => {
    async function getHotels() {
      try {
        const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        // console.log(data);
        setHotels(data);
      } catch (error) {
        console.error(error);
      }
    }

    if (user) {
      getHotels();
    }
  }, [user]);

  return (
    <>
      <div className="row justify-content-center mt-5">
        {levels.map((level, index) => (
          <div key={index} className="col-md-3 g-5">
            <div className="item bg-white text-center ">
              <div>
                <div className={`${Style.card} d-flex justify-content-center py-3 bg-dark`}>
                  <h6 className="text-white">{level.departmentName}</h6>
                </div>
              </div>

              {(level.levelOrder == currLevel && getStatus(level) === "") && (
                <div>

                  {(userDepartmentId == level.newDepartmentId && !rejected) ? (
                    <div className="pb-2">
                      <Link disabled={run} className="cursor-pointer">
                        <i onClick={acceptForm} className="fa-solid fa-calendar-check m-3 fs-3 text-success "></i>
                      </Link>
                      <Link disabled={run}>
                        <i onClick={RejectForm} className="fa-solid fa-calendar-xmark m-3 fs-3 text-danger cursor-pointer"></i>
                      </Link>
                      <Link disabled={run}>
                        <i
                          className="fa-solid fa-calendar-minus m-3 fs-3 text-warning cursor-pointer"
                          data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                        ></i>
                      </Link>
                    </div>

                  ) : <div className="p-4"></div>}
                </div>
              )}
              {(level.levelOrder < currLevel || currLevel == 0) && (
                <div className="p-3">
                  <h6>{level.signedName}</h6>

                  {getStatus(level) === "Accepted" &&
                    <p>  <img className="w-50" src={AcceptedStatus} alt="Accepted" /></p>
                  }

                  {getStatus(level) === "Rejected" &&
                    <p>  <img className="w-50" src={RejectedStatus} alt="Rejected" /></p>
                  }

                  {(getStatus(level) === "Returned to created department" || getStatus(level) === "Returned to created user") &&
                    <p>{getStatus(level)}</p>
                  }

                  <p>{level?.signedOn?.substring(0, 10)} {level?.signedOn?.substring(11, 16)}</p>
                </div>
              )}
              {(level.levelOrder > currLevel||getStatus(level) !== "") && <div className="p-4"></div>}
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Comment</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {/* <div className="row mb-2 px-3">

                      <div className="form-check col-md-6 ">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="user"
                          checked={selectedOption === 'user'}
                          onChange={handleChange}
                          id="userRadio"
                        />
                        <label className="form-check-label" htmlFor="userRadio">
                          User
                        </label>
                      </div>
                      <div className="form-check col-md-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="department"
                          checked={selectedOption === 'department'}
                          onChange={handleChange}
                          id="departmentRadio"
                        />
                        <label className="form-check-label" htmlFor="departmentRadio">
                          Department
                        </label>
                      </div>
                    </div> */}

                    {/* {selectedOption === 'department' && (

                      <div className='row mb-3 '>
                        <div className="col-md-6">
                          <div className="item bg-white text-center ">
                            <select value={hotelId} onChange={getDepartments} className="form-select border-0 cursor-pointer" aria-label="Default select example">
                              <option value="" disabled>
                                Select hotel
                              </option>
                              {hotels.map((hotel) => (
                                <option key={hotel.id} value={hotel.id} className='m-5'>{hotel.name}</option>
                              ))}

                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="item bg-white text-center">
                            <select value={departmentId} onChange={handleDepartmentChange} disabled={departments.length == 0} className="form-select border-0 cursor-pointer" aria-label="Default select example">
                              <option value="" disabled>
                                Select department
                              </option>


                              {departments.map((department) => (

                                <option key={department.id} value={department.id} className="m-5">
                                  {department.name}
                                </option>



                              ))}

                            </select>
                          </div>
                        </div>
                      </div>
                    )} */}


                    <div className='d-flex justify-content-center'>
                      <textarea className="form2 w-90 " style={{ minHeight: '200px' }} type="area" id="Comment" value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Comment" >
                      </textarea>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={() => ReturnFormToUserCreated()} disabled={disMiss} >{disMiss ? 'Send...' : 'Send'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
