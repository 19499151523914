import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from "../../config";
export const CreateTextName = () => {
  const { id } = useParams()
  const [user, setUser] = useState("");
  const navigate = useNavigate()
const [initialValues, setInitialValues] = useState({
   textName : ''
})

const [loading, setLoading] = useState(true);
const [formHeader, setFormHeader] = useState(''); 

useEffect(()=>{
   const token = JSON.parse(localStorage.getItem("user"));
   setUser(token);
},[])
useEffect(()=>{
  if (id) {
    setFormHeader('Update Text Name')
  }else{
    setFormHeader('Create Text Name')
  }
},[])

useEffect(()=>{
  async function GetTextNameById(){
  try{
   
      
      if(id){
        setFormHeader('Update Text Name')
  
        const {data} = await axios.get(`${BASE_URL}/api/FormNames/GetTypeStringById/${id}`
      ,
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
      )
        //console.log(data);
        setInitialValues({
            textName:data.name
          })
        }
    
    }catch(err){
      console.error('Error fetching text name:', err);
    }finally {
      setLoading(false); 
    }
  }
  if (user) {
  GetTextNameById()
  }
},[id,user])

  async function createTextName(values, { setSubmitting }) {
    try {
      if (user) {
        
        //console.log(values);
  if (id) {
     
    const { data } = await axios.put(`${BASE_URL}/api/FormNames/EditTypeString/${id}?typeName=${values.textName}`,
  {},
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
    //console.log( 'update' ,data);
   navigate(`/dashboard/text-names`)
  } else {
     //console.log(values.textName);
    const { data } = await axios.post(
      `${BASE_URL}/api/FormNames/CreateTypeString?typeName=${values.textName}`
    ,{},
     {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }
      );
  
    //console.log( 'Add : ' ,data);
    navigate('/dashboard/text-names')
  }
      }
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitting(false)
    }
  }

  const validationSchema = Yup.object().shape({
    textName: Yup.string().required("Text name is required"),
  });

  return (
    <section className="p-5">
      <h2 className="text-center text-cap">{formHeader}</h2>
      {!loading && (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createTextName}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="textName" className="form-label">
                Text Name:
              </label>
              <Field
                type="text"
                name="textName"
                id="textName"
                className={`form-control ${
                  errors.textName && touched.textName && "is-invalid"
                }`}
              />
              <ErrorMessage
                name="textName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <button type="submit" className="btn btn-dark"  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </Form>
        )}
      </Formik>
      )}
    </section>
  );
};