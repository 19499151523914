import React, { useEffect, useState } from 'react';
import logo from '../../assets/imgs/KSR-Master.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';

const TermsAndConditions = () => {
   const navigate = useNavigate();
   const [user, setUser] = useState(null)
   const [userData, setUserData] = useState(null)

   useEffect(()=>{
      const token = JSON.parse(localStorage.getItem("user"));
      const userData = JSON.parse(localStorage.getItem("userData"));
      setUser(token)
      setUserData(userData)

},[])

const activateUser = async ()=>{
   try {
      if (user&&userData) {
         //console.log(user);
         const {data} = await axios.post(`${BASE_URL}/api/Auth/UserActivation/${userData.id}`,
           {},
            {
               headers: {
                  Authorization: `Bearer ${user}`,
                },
              }
      
         )
      //console.log(data);
      userData.isActive = "True"
      localStorage.setItem('userData',JSON.stringify(userData))
      navigate('/dashboard')

      }
      
   } catch (error) {
   console.log(error);   
   }
}
   const handleCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      // setCheckboxChecked(isChecked);
      if (isChecked) {
          activateUser();
      }
  };
    return (
        <div className="container mt-4">
         <div className='d-flex justify-content-center pb-5'>
            <img src={logo} className='w-25' alt="" />
         </div>
            <div>
               <h6 className='pb-3'>Accept the terms and conditions to access the dashboard.  </h6>
               <div className="form-check border-dark-subtle border p-3 mb-5 col-md-5 bg-dark-subtle rounded">
  <input
   className="form-check-input mx-2" 
   type="checkbox" 
   value="" 
   id="flexCheckDefault"
   onChange={handleCheckboxChange}

  />
  <label className="form-check-label" htmlFor="flexCheckDefault">
  I agree to the Terms of Use and acknowledge the Privacy Policy
  </label>
</div>
             
            </div>
<div className='border-dark border-bottom col-md-6'>
<h1 className="mb-4 fw-semibold font-monospace">Terms and Conditions</h1>
</div>
            <div className="mt-4">
                <h2>Additional Costs</h2>
                <p>
                    We make every effort to identify and price all required Work upfront. After invasive investigation, however, we may discover 
                    unforeseen problems - deeper rot, for example. In such an instance, we will recommend extra Work at extra indicative cost. By 
                    giving your consent to extra Work you agree to pay us the indicated extra costs. Also, you agree to pay us extra for the re-
                    glazing of panes of glass broken during Work when there has been no avoidable error on our part. Old sashes, for example, 
                    contain thin glass vulnerable to vibrations caused by machining.
                </p>
            </div>

            <div className="mt-4">
                <h2>Payments</h2>
                <p>
                    Your Deposit, Balance 1 and Balance 2 etc. payments are due no later than the respective Due Dates specified in your Project 
                    and Payment Schedule. We reserve the right to cancel our Guarantee and/or abandon Work if you do not make a payment on 
                    time or in full. Should you instigate a pause, delay or change in Work you agree to pay us on our request an unscheduled 
                    interim payment to cover the price of Work already completed and up to a 100% deposit of the price of the previously agreed 
                    Work that remains outstanding. All products are the property of Acorn Sash Windows until payment is received in full. In 
                    payment disputes, the decision of Acorn Sash Windows is ultimate and final.
                </p>
            </div>

            <div className="mt-4">
                <h2>Curtains, Furniture and Breakages</h2>
                <p>
                    We require that you clear away curtains and blinds etc. and all nearby furniture, fixtures and belongings etc. from work areas. 
                    We will not repair or replace, or provide reimbursement for, damaged items not removed from work areas prior to our arrival. 
                    We accept no responsibility for the safety of any person, pet or other animals within work areas.
                </p>
            </div>

            <div className="mt-4">
                <h2>Keys</h2>
                <p>
                    We require that you supply us with keys to windows and doors on our request for the carrying out of Work.
                </p>
            </div>

            <div className="mt-4">
                <h2>Parking and Congestion Charges</h2>
                <p>
                    You will pay us the face value of Congestion Charge fees that we incur during Work. If parking restrictions apply where we want  
                    to park to carry out Work, we require that you supply for us for the duration of Work off-street parking as we require, or 
                    enough Visitor's or Tradesman's (or other) parking permits for us to park as we require in the nearest Controlled Parking Zone. 
                    For time wasted due to inadequate parking, you agree to pay us £35 plus VAT per workman per hour or part hour. Also, you 
                    agree to pay us the face value of Pay & Display tickets or other parking fees and Penalty Charge Notices or other fines incurred. 
                    We reserve the right to abandon Work if you do not supply us with parking permits.
                </p>
            </div>

            <div className="mt-4">
                <h2>Permissions</h2>
                <p>
                    You may require Planning Permission for Work if it includes the installation of complete new windows and you live in a flat or 
                    maisonette, if you live in a Conservation Area under Article 4 Direction, or if you live in a listed Building. If you are a 
                    Leaseholder you may also require the consent of your Freeholder. Other permissions may also be required. The owner of the 
                    property is legally responsible for obtaining relevant Planning Permission and ensuring Building Regulations compliance.
                </p>
            </div>

            <div className="mt-4 mb-4">
                <h2>Guarantee</h2>
                <p>
                    For Option A work, Acorn Sash Windows will rectify defective workmanship free of charge for up to 1 year after the completion 
                    date and supply replacement materials free of charge for up to 1 year after the completion date. For Option B work, Acorn Sash 
                    Windows will rectify defective workmanship free of charge for up to 1 year after the completion date and supply replacement 
                    materials free of charge for up to 5 years after the completion date. For Option C work, Acorn Sash Windows will rectify 
                    defective workmanship free of charge for up to 5 years after the completion date and supply replacement materials free of 
                    charge for up to 10 years after the completion date. Our Insurance-Backed Guarantee is provided by GGF Insurance Ltd. In the 
                    event that Acorn Sash Windows should cease trading, GGF Insurance Ltd. will fulfil our guarantee to you and/or refund all 
                    deposit amounts paid for the installation of complete new box sash windows, in line with their terms and conditions. In 
                    Guarantee disputes, the decision of Acorn Sash Windows is ultimate and final.
                </p>
            </div>
        </div>
    );
}

export default TermsAndConditions;
