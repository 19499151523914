import axios from "axios";
import { BASE_URL } from "../config";
import React, { useContext, useEffect, useState } from 'react';
import { Loading } from "../Loading/loading";
import { UserSearch } from "./user-search";
import { DepartmentAdmin } from "./department-admin";
import { AddUser } from "./addUser";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';

export const AllUsers = () => {
  const navigate = useNavigate();
  const [addedMsg, setAddedMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [togleMsgError, setTogleMsgError] = useState(null);
  const [deletedMsg, setDeletedMsg] = useState(null);
  const { token } = useContext(UserContext)

  const [searchData, setSearchData] = useState('');
  const [user, setUser] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [paginatedUsers, setPaginatedUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const totalPages = Math.ceil(users?.length / pageSize);
  const [showForms, setShowForms] = useState("AllUsers");
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);
useEffect(()=>{
  //console.log('searchData',searchData);
},[searchData])

  // Function to handle data from the child component
  const handleChildData=(data)=>{
    setSearchData(data);
  };

  const getUsers = async () => {
    try {
        setLoading(true)
      const { data } = await axios.get(
        `${BASE_URL}/api/Auth/GetAllUsers`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
if (data) {
  setUsers(data);
}

const sortedUsers = [...data].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
setPaginatedUsers(sortedUsers.slice((page - 1) * pageSize, page * pageSize));
      filterUsers()
    } catch (error) {
      console.error(error);
    }
    finally{
        setLoading(false)
    }
  };
useEffect(()=>{
  //console.log('paginatedUsers',paginatedUsers);
setFilteredUsers(paginatedUsers)
},[paginatedUsers])

function filterUsers(){
  const lowercasedSearchData = searchData?.toLowerCase() || '';

  const filtered = users.filter((user) => {
    const fullName = user.fullName?.toLowerCase() || ''; // If null or undefined, default to an empty string
    const email = user.email?.toLowerCase() || ''; // If null or undefined, default to an empty string
    const userName = user.userName?.toLowerCase() || ''; // If null or undefined, default to an empty string

    return (
      fullName.includes(lowercasedSearchData) ||
      email.includes(lowercasedSearchData) ||
      userName.includes(lowercasedSearchData)
    );
  });
  setPage(1)
  
const sortedUsers = [...filtered].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
  setPaginatedUsers(sortedUsers.slice((page - 1) * pageSize, page * pageSize));
  setFilteredUsers(sortedUsers);
}

useEffect(() => {
  filterUsers()
}, [searchData]);

  // const toggleForms = (value) => {
  //   setShowForms(value); 
  // };
  // const toggleUserAllowed = async (id) => {
  //   try {
  //       if (user) {
  //             const { data } = await axios.put(
  //             `${BASE_URL}/api/Reports/ToggleUserAllowedSign/${id}`,
  //            {},
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${user}`,
  //               },
  //             }
  //           );
            
  //           setUsers(
  //           users.filter((user)=>{
  //                   if (user.id==id&&user.email!="Admin@Hotel.com") {
  //                       user.isAllowedToAsign=data
  //                   }
  //                   return true;
  //               })
  //           )
  //               console.log(users);
  //               console.log("888888888888888");
            
  //       }
  //   } catch (error) {
  //     console.error(error);
  //   }
  
  // };


  useEffect(() => {
if(user)
    {
        getUsers()
    }
  }, [user]);

  useEffect(() => {
  const sortedUsers = [...users].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
setPaginatedUsers(sortedUsers.slice((page - 1) * pageSize, page * pageSize));
    //console.log('users',users);
  }, [page, users]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const getPaginationItems = () => {
    let start = Math.max(1, page - 1);
    let end = Math.min(totalPages, page + 1);
    const pages = [];
    
    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };
  const deleteUser = async (userId) => {
    try {
      const { data } = await axios.delete(
        `${BASE_URL}/api/Auth/toggleUserDelete/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      if (data) {
        //console.log(data);

      }

      setFilteredUsers(filteredUsers.filter((use)=>use.id!=userId))   
       getUsers()
       filterUsers()
      setTogleMsgError(null)
    } catch (error) {
        console.log(error);
        setTogleMsgError(error.response.data)
        setDeletedMsg(null)
        setAddedMsg(null)
    }
    finally{
      setLoading(false)
    }
  };

  const handleClick = (id) => {
    navigate(`/dashboard/user/${id}`);
  };
  if (loading) {
    return <Loading />
  }else{
  return (

    <div className="">
   <div className="dropdown ms-5">
  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    ACTION MENU
  </button>
  <ul className="dropdown-menu">
    <li><Link className="dropdown-item" to="/dashboard/add-user">Add User</Link></li>
    <li><Link className="dropdown-item" to="/dashboard/departments/Create">Add Department</Link></li>
  </ul>
</div>

    <div>
      <UserSearch handleChildData={handleChildData} />
    </div>
{showForms=="AllUsers" && 
<div>
  {users?.length>0?
  <div className="container-fluid m-auto row ">
  {/* <div className="text-center h4 mb-4">Users</div> */}
       <div className="col-lg-12 bg-body">
         <div className="table-responsive">
           <table className="table custom-user-table">
             <thead>
               <tr>
                 <th>#</th>
                 <th>Full Name</th>
                 <th>User Name</th>
                 <th>Email</th>
                 <th>Hotel</th>
                 <th>Department</th>
                 <th>Disabled</th>
               </tr>
             </thead>
             <tbody>
               {paginatedUsers?.map((user, index) => (
               
                 <tr   key={user.id}>
                   <th>{(page - 1) * pageSize + index + 1}</th>
                   <td>
                    <div onClick={()=>handleClick(user.id)} style={{ cursor: 'pointer' }}>
                   <p className="mb-0">{user.fullName}</p> 
                    </div>
                    <div>
                   
                    <Link  className="text-decoration-underline pe-2 me-2 border-end text-danger" onClick={()=>deleteUser(user.id)}>
                      {user.isDeleted?"Recover":"Delete"}
                      </Link>
                    <Link className=" link-underline-me" to={`/dashboard/user/${user.id}`}>View</Link>
                    </div>
                  
                    </td>
                   <td onClick={()=>handleClick(user.id)} style={{ cursor: 'pointer' }}>{user.userName}</td>
                   <td onClick={()=>handleClick(user.id)} style={{ cursor: 'pointer' }}>{user.email}</td>
                   <td>{user.hotel?user.hotel:"Not have"}</td>
                   <td>{user.department?user.department:"Not have"}</td>
                   <td>
                   <div className="form-check">
                    <input onChange={()=>deleteUser(user.id)} className="form-check-input" type="checkbox" id="inlineCheckbox1"  checked={user.isDeleted}/>
                   </div>
                   </td>
                 </tr>
               ))}
             </tbody>
           </table>
           <div className="d-flex justify-content-center">
             <nav>
               <ul className="pagination">
                 <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                   <button className="page-link" onClick={() => handlePageChange(page - 1)}>Previous</button>
                 </li>
                  {getPaginationItems()}
                 <li  className={`page-item ${page === Math.ceil(users.length / pageSize) ? 'disabled' : ''}`}>
                   <button  className="page-link" onClick={() => handlePageChange(page + 1)}>Next</button>
                 </li>
               </ul>
             </nav>
           </div>
         </div>
       </div>
     </div>
     :
     <div className="bg-body row p-5 header text-center">
     <h4>No Users! </h4>
   </div>
    }
</div>
}

{showForms=="AddUser" && 
<AddUser />
}
{showForms=="DepartmentAdmin" && 
<DepartmentAdmin/>
}
{showForms=="UserSearch" && 
<UserSearch/>
}

    </div>

  );
}
};