import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { Loading } from "../../Loading/loading";

export default function AllformNames() {
  const baseUrl = BASE_URL;
  const [user, setUser] = useState("");
  const [formNames, setFormNames] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);

    async function getDepartments() {
      try {
        setLoading(true);
        const { data } = await axios.get(`${baseUrl}/api/FormNames/GetAllFormName`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setFormNames(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      getDepartments();
    }
  }, [user]);

  const deleteFormName = async (id) => {
    try {
      setFormNames((prev) => prev.filter((current) => current.id !== id));
      await axios.delete(`${baseUrl}/api/FormNames/DeleteFormName/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading && !formNames.length) {
    return <Loading />;
  }

  return (
    <div className="container py-4">
      <div className="row justify-content-between align-items-center mb-3">
        <div className="col-12 col-md-6 mb-3 mb-md-0">
          <h4 className="fw-bold text-secondary text-center text-md-start">
            Form Names
          </h4>
        </div>
        <div className="col-12 col-md-6 text-center text-md-end">
          <Link to="/dashboard/create-form-name" className="btn btn-outline-primary">
          Create Form Name
          </Link>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {formNames.map((formName, index) => (
              <tr key={formName.id}>
                <td>{index + 1}</td>
                <td>{formName.name}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row gap-2">
                    <Link
                      to={`/dashboard/create-form-name/${formName.id}`}
                      className="btn btn-outline-secondary btn-sm"
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteFormName(formName.id)}
                      className="btn btn-outline-danger btn-sm"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
