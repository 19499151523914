import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../config";
import { Loading } from "../../Loading/loading";

export default function Logs() {
  const [allLogs, setAllLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [paginatedLogs, setPaginatedLogs] = useState([]);
  const totalPages = Math.ceil(filteredLogs?.length / pageSize);

  const [nameFilter, setNameFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    const currentToken = JSON.parse(localStorage.getItem("user"));
    setToken(currentToken);
  }, []);

  const getAllLogs = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${BASE_URL}/api/Reports/GetLogs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllLogs(data);
      setFilteredLogs(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getAllLogs();
    }
  }, [token]);

  const filterLogs = () => {
    let filtered = [...allLogs];

    if (nameFilter) {
      filtered = filtered.filter((log) =>
        log.userName.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (departmentFilter) {
      filtered = filtered.filter((log) =>
        log.departmentName.toLowerCase().includes(departmentFilter.toLowerCase())
      );
    }

    if (startDate) {
      filtered = filtered.filter(
        (log) => new Date(log.createdOn) >= new Date(startDate)
      );
    }
    if (endDate) {
      filtered = filtered.filter(
        (log) => new Date(log.createdOn) <= new Date(endDate)
      );
    }

    setFilteredLogs(filtered);
    setPage(1);
  };

  useEffect(() => {
    filterLogs();
  }, [nameFilter, departmentFilter, startDate, endDate, allLogs]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const getPaginationItems = () => {
    let start = Math.max(1, page - 1);
    let end = Math.min(totalPages, page + 1);
    const pages = [];

    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === page ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return pages;
  };

  useEffect(() => {
    const sortedLogs = [...filteredLogs].sort(
      (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
    );
    setPaginatedLogs(sortedLogs.slice((page - 1) * pageSize, page * pageSize));
  }, [page, filteredLogs]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="container-fluid mt-5 row">
        <div className="col-lg-12 bg-body">

          {/* Filter Section */}
          <div className="row mb-3 mt-3">
            <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
              <label htmlFor="nameFilter">Filter by Name</label>
              <input
                id="nameFilter"
                type="text"
                className="form-control"
                placeholder="Enter Name"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
              <label htmlFor="departmentFilter">Filter by Department</label>
              <input
                id="departmentFilter"
                type="text"
                className="form-control"
                placeholder="Enter Department"
                value={departmentFilter}
                onChange={(e) => setDepartmentFilter(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
              <label htmlFor="startDate">Start Date</label>
              <input
                id="startDate"
                type="date"
                className="form-control"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
              <label htmlFor="endDate">End Date</label>
              <input
                id="endDate"
                type="date"
                className="form-control"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          {/* Logs Table */}
          <div className="table-responsive">
            <table className="table custom-user-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Department</th>
                  <th>Action</th>
                  <th>Action Date</th>
                </tr>
              </thead>
              <tbody>
                {paginatedLogs?.map((log, index) => (
                  <tr key={log.id}>
                    <th>{(page - 1) * pageSize + index + 1}</th>
                    <td>{log.userName}</td>
                    <td>{log.departmentName}</td>
                    <td>{log.action}</td>
                    <td>
                      {log.createdOn.substring(0, 10)}{" "}
                      {log.createdOn.substring(11, 16)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page - 1)}
                  >
                    Previous
                  </button>
                </li>
                {getPaginationItems()}
                <li
                  className={`page-item ${
                    page === Math.ceil(filteredLogs.length / pageSize)
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
