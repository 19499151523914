import "./Loading.css";

export const Loading = () => {
  return (
    <>
      <div className="loadingBg d-flex justify-content-center align-items-center vh-100">
        <div className="loadingio-spinner-ellipsis-2by998twmg8 loadingBg">
          <div className="ldio-yzaezf3dcmj">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
