import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../Loading/loading";

export const ResetPassword = () => {
  const {id,setId} = useState("")
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    const userDatalocal = JSON.parse(localStorage.getItem("userData"));
    setUser(token);
    setUserData(userDatalocal);
  }, []);




  const resetPassword = async (values) => {
    try {
      if (user&&userData) {
        const addValues = {
          id: userData?userData.id:"",
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
 
        setLoading(true);
    
          const { data } = await axios.put(
            `${BASE_URL}/api/Auth/ChangePassword`,
            addValues,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          if (data) {
            localStorage.clear()
            navigate("/login")
            setErrMessage("");
          }
  
        
      }
    } catch (error) {
      setSuccessMessage("");
      if(error.response)
      { if (typeof error.response.data === 'string') {
         setErrMessage(error.response.data);
            }
       else{
         setErrMessage(error.response.data.errors[0]);
         }
      }
      else
      setErrMessage("Failed to add user");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="content pt-5 mt-3 d-flex justify-content-center">
      <Formik
        initialValues={{
          password: "",
          confirmPassword: ""
        }}
        validationSchema={Yup.object({
          password:  Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters long")
          .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
          .matches(/[a-z]/, "Password must contain at least one lowercase letter")
          .matches(/\d/, "Password must contain at least one digit")
          .matches(/[\W_]/, "Password must contain at least one non-alphanumeric character"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required")
        })}
       onSubmit={resetPassword} 
      >
{() => (
  <Form className="form w-75 ">
  <div className="row ">
  
  <h2 className="text-center mb-5">Change Password</h2>  
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="password" className="form-label">
            Password
          </label>
        </div>
        <div className="col-10">
          <Field
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter Password"
          />
          <ErrorMessage
            name="password"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
        </div>
        <div className="col-10">
          <Field
            type="password"
            name="confirmPassword"
            className="form-control"
            placeholder="Enter Confirm Password"
          />
          <ErrorMessage
            name="confirmPassword"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
      
    {errMessage && (
      <div className="col-md-12">
        <div className="alert alert-danger mt-5 text-center fw-bold">
          {errMessage}
        </div>
      </div>
    )}
   
    <div className="col-md-12 mt-3 text-center">
      <button
        type="submit"
        className="btn btn-dark px-5"
        disabled={loading}
      >
        {loading ? "saving..." : "save"}
      </button>
    </div>
  </div>
</Form>

)}
      </Formik>
    </div>
  );

};