import React, { useEffect, useState } from "react";
import { Loading } from "../Loading/loading";
import axios from "axios";
import { BASE_URL } from "../config";
import { useParams } from "react-router-dom";

export function FormPermission (){
  const {id} = useParams()
   const [user, setUser] = useState("");
   const [loading, setLoading] = useState(true);
   const [saveLoading, setSaveLoading] = useState(false);
   const [departments, setDepartments] = useState([]);
   const [form, setForm] = useState({});
   const [checkedDepartments, setCheckedDepartments] = useState([]);
   const [successMessage, setSuccessMessage] = useState("");
   const [errMessage, setErrMessage] = useState("");
   const [formPermission, setFormPermission] = useState({
    formId: form.id,
    departmentId: checkedDepartments
   });
   const handleCheckboxChange = (departmentId) => {
    setCheckedDepartments((prev) =>
      prev.includes(departmentId)
        ? prev.filter((id) => id !== departmentId)
        : [...prev, departmentId]
    );
   // console.log(checkedDepartments);
    setFormPermission({
      formId: form.formId,
      departmentId: checkedDepartments
    })
  };


   useEffect(()=>{
      const token = JSON.parse(localStorage.getItem("user"));
      setUser(token);
    },[])
    useEffect(()=>{
      setFormPermission({
        formId: form.formId,
        departmentId: checkedDepartments
      })
    },[checkedDepartments])
    async function getFormById(){
      try {
    const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetFormById/${id}`,
        {
          headers: {
             Authorization: `Bearer ${user}`,
           },
         }
       );
       setForm(data);
       //console.log(data);
      } catch (error) {
          console.log(error);
      }
      finally{
        setLoading(false)
        }
        }
        
        async function getFormPermissionById(){
          try {
           
        const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetFormPermission/${id}`,
            {
              headers: {
                 Authorization: `Bearer ${user}`,
               },
             }
           );
           setCheckedDepartments(data.departmentsId);
          } catch (error) {
              console.log(error);
          }
          finally{
            setLoading(false)
                }
              }
        async function getDepartments(){
          try {
           const { data } = await axios.get(`${BASE_URL}/api/Departments/GetAllDepartments`,
         {
           headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
          setDepartments(data);
       } catch (error) {
           console.log(error);
       }
       finally{
         setLoading(false)
       }
           }
useEffect(()=>{   
      if (user) {
   getDepartments();
      }
},[user])
useEffect(()=>{   
  if (user&&id) {
    getFormById()
    getFormPermissionById()
  }
},[user,id])

const checkAll=()=>{
  if (checkedDepartments.length===departments.length) {
    setCheckedDepartments([])
  }
  else{
    const departs=departments.map(({id})=>(
          id
    ))
    setCheckedDepartments(departs)
  }
}

async function sendFormPermission(){
  try {
    setSaveLoading(true)
    //console.log(formPermission);
      const { data } = await axios.post(`${BASE_URL}/api/RegisterationForms/FormPermission`,
      formPermission,
       {
      headers: {
         Authorization: `Bearer ${user}`,
       },
     }
   );
   //console.log(data);
   setSuccessMessage("Permission add successfully")
   setErrMessage("")
  }catch (error) {
      console.log(error);
      setSuccessMessage("")
      setErrMessage("Faild to add permission")
  }
  finally{
    setLoading(false)
    setSaveLoading(false)
  }
  }
if (loading || form.formId==undefined) {
   return <Loading/>
 } else {
   return<>
   <div className="text-center p-5">
    <div className="pb-4">
    <h3>{form.name}</h3>
    </div>
        <div className="container d-flex">
 <table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Department</th>
      <th scope="col" className=" d-flex justify-content-center"> 
          
        <div className="me-2">
          {checkedDepartments?.length!==departments?.length?"Select All":"UnSelect All"}
        </div>
       <div>
  <input className="form-check-input" type="checkbox" 
 onChange={() => checkAll()}
   checked={checkedDepartments.length==departments.length}
  />
        </div> 
      </th>
    </tr>
  </thead>
  <tbody>
      {departments.map((department,index)=>(
    <tr key={department.id}>
      <th scope="row">{index+1}</th>
      <td>
      <label className="form-check-label" htmlFor={department.id}>
  {department.name}
  </label>
      </td>
      <td className="d-flex justify-content-center">
<div className="form-check">
  <input className="form-check-input" type="checkbox" 
  value={department.id}
  onChange={() => handleCheckboxChange(department.id)}
  checked={checkedDepartments.includes(department.id)}
  />
</div>

      </td>
    </tr>
))}

  </tbody>
</table>
</div>
 {errMessage && (
                  <div className="alert alert-danger mt-5 text-center fw-bold">
                    {errMessage}
                  </div>
                )}
                {successMessage && (
                  <div className="alert alert-success mt-5 text-center fw-bold">
                    {successMessage}
                  </div>
                )}
 <button onClick={sendFormPermission} className="btn btn-dark border rounded-0" disabled={saveLoading}>
  {saveLoading?"Saving...":"give permission"}
  </button>
   </div>
     </>
   
 }
}