import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Loading } from "../Loading/loading";

export const AddUser = () => {
  const {id} = useParams()
  const [departments, setDepartments] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [userData, setUserData] = useState({
          id:"",
          fullName: "",
          username: "",
          email: "",
          department: "",
          password: "",
          // confirmPassword: "",
          hotel: "",
          permission: false,
  });

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);

  useEffect(() => {
    async function getUserById() {
      try {
        setLoadingUser(true)
        const { data } = await axios.get(`${BASE_URL}/api/Auth/GetUserById/${id}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
       //console.log(data);
userData.id=id;
userData.fullName=data.fullName;
userData.username=data.userName;
userData.email=data.email;
 userData.department=data.newDepartmentId;
userData.hotel=data.typeHotelId;
userData.permission=data.isAllowedToAsign;
getDepartments(data.typeHotelId);
      } catch (error) {
        console.error(error);
      }
      finally{
        setLoadingUser(false)
      }
    }

    if (user&&id) {
      getUserById();
    }
  }, [user,id]);




  useEffect(() => {
    async function getHotels() {
      try {
        const { data } = await axios.get(`${BASE_URL}/api/Hotels/GetAllHotels`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setHotels(data);
      } catch (error) {
        console.error(error);
      }
    }

    if (user) {
      getHotels();
    }
  }, [user]);

  const getDepartments = async (hotelId) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/Departments/GetAllDepartmentsByHotelId/${hotelId}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      setDepartments(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleHotelChange = (e, setFieldValue) => {
    const selectedHotelId = e.target.value;
    setFieldValue("hotel", selectedHotelId);
    getDepartments(selectedHotelId);
  };

  const addUser = async (values) => {
    try {
      if (user) {
        const addValues = {
          id: id?id:"",
          fullName: values.fullName,
          username: values.username,
          email: values.email,
          password: values.password,          
          isAllowedToAsign: values.permission,
          selectedRoles: ["Admin"],
          newDepartmentId: values.department,
          typeHotelId: values.hotel,
        };
          
        setLoading(true);
        if (id) {
          //console.log(addValues);

          const { data } = await axios.put(
            `${BASE_URL}/api/Auth/UpdateUser`,
            addValues,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          if (data) {
            setSuccessMessage("User Updated successfully!");
            setErrMessage("");
          }
  
        } else {
          
          const { data } = await axios.post(
            `${BASE_URL}/api/Auth/AddUser`,
            addValues,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          if (data) {
            setSuccessMessage("User added successfully!");
            setErrMessage("");
          }
        }







      }
    } catch (error) {
      setSuccessMessage("");
      if(error.response)
      { if (typeof error.response.data === 'string') {
         setErrMessage(error.response.data);
            }
       else{
         setErrMessage(error.response.data.errors[0]);
         }
      }
      else
      setErrMessage("Failed to add user");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

if (loadingUser) {
  <Loading/>
}
else{
  return (
    <div className=" content d-flex justify-content-center pt-4 ">
      <Formik
        initialValues={{
          
          fullName: userData.fullName,
          username: userData.username,
          email: userData.email,
          department: userData.department,
          password: "",
          confirmPassword: "",
          hotel: userData.hotel,
       //   role: "",
          permission: userData.permission,
        }}
        validationSchema={Yup.object({

          fullName: Yup.string().required("Full name is required"),
          username: Yup.string().required("Username is required"),
          email: Yup.string().email("Invalid email address").required("Email is required"),
          department: Yup.string().required("Department is required"),
          hotel: Yup.string().required("Hotel is required"),
        })}
        
       onSubmit={addUser} 
      >
{(formikProps) => (
  <Form className="form mx-2 shadow-none">
  <div className="row">
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="fullName" className="form-label">
            Full Name
          </label>
        </div>
        <div className="col-10">
          <Field
            type="text"
            name="fullName"
            className="form-control"
            placeholder="Enter Full Name"
          />
          <ErrorMessage
            name="fullName"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="username" className="form-label">
            Username
          </label>
        </div>
        <div className="col-10">
          <Field
            type="text"
            name="username"
            className="form-control"
            placeholder="Enter Username"
          />
          <ErrorMessage
            name="username"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="email" className="form-label">
            Email Address
          </label>
        </div>
        <div className="col-10">
          <Field
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email Address"
          />
          <ErrorMessage
            name="email"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="hotel" className="form-label">
            Hotel
          </label>
        </div>
        <div className="col-10">
          <Field
            as="select"
            name="hotel"
            className="form-select"
            onChange={(e) => handleHotelChange(e, formikProps.setFieldValue)}
          >
            <option value="" defaultValue disabled>
              Select Hotel
            </option>
            {hotels.map((hotel) => (
              <option key={hotel.id} value={hotel.id}>
                {hotel.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="hotel"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="password" className="form-label">
            Password
          </label>
        </div>
        <div className="col-10">
          <Field
            type="password"
            name="password"
            className="form-control"
            placeholder="Enter Password"
          />
          <ErrorMessage
            name="password"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    {/* <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
        </div>
        <div className="col-10">
          <Field
            type="password"
            name="confirmPassword"
            className="form-control"
            placeholder="Enter Confirm Password"
          />
          <ErrorMessage
            name="confirmPassword"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div> */}
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="department" className="form-label">
            Department
          </label>
        </div>
        <div className="col-10">
          <Field
            as="select"
            name="department"
            className="form-select"
            disabled={departments.length == 0}
          >
            <option value="" defaultValue disabled>
              Select Department
            </option>
            {departments.map((department) => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="department"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    <div className="col-md-12 mb-3">
      <div className="row align-items-center">
        <div className="col-2">
          <label htmlFor="permission" className="form-label d-block">
            Permission
          </label>
        </div>
        <div className="col-10">
          <Field
            type="checkbox"
            name="permission"
            className="form-check-input"
            id="permission"
          />
          <label className="form-check-label" htmlFor="permission">
            &nbsp; &nbsp; Signing
          </label>
          <ErrorMessage
            name="permission"
            component="div"
            className="text-danger"
          />
        </div>
      </div>
    </div>
    
    {errMessage && (
      <div className="col-md-12">
        <div className="alert alert-danger mt-5 text-center fw-bold">
          {errMessage}
        </div>
      </div>
    )}
    {successMessage && (
      <div className="col-md-12">
        <div className="alert alert-success mt-5 text-center fw-bold">
          {successMessage}
        </div>
      </div>
    )}
    
    <div className="col-md-12 mt-3 text-center">
      <button
        type="submit"
        className="btn btn-dark px-5"
        disabled={loading}
      >
        {loading ? "saving..." : "save"}
      </button>

    </div>
  </div>
</Form>

)}
      </Formik>
    </div>
  );
}
};