
import "./App.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import HousingAllowance from "./Components/HousingAllowance/HousingAllowance";
import { SignIn } from "./Components/auth/login/login";
import { Layout } from "./Components/layout/layout";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AllHotels from "./Components/Dashboard/Hotel/hotel";
import { CreateHotel } from "./Components/Dashboard/Hotel/create-hotel";
import AllDepartments from "./Components/Dashboard/Departments/allDepartments";
import { CreateDepartment } from "./Components/Dashboard/Departments/create-department";
import AllDropdowns from "./Components/Dashboard/Dropdowns/allDropdowns";
import { CreateDropDown } from "./Components/Dashboard/Dropdowns/create-dropdown";
import { CreateForm } from "./Components/Dashboard/Form/create-form";
import { CreateFormName } from "./Components/Dashboard/FormsName/creare-form-name";
import AllformNames from "./Components/Dashboard/FormsName/form-name";
import AllTextNames from "./Components/Dashboard/TextNames/all-text-names";
import { CreateTextName } from "./Components/Dashboard/TextNames/create-text-name";
import { DefaultDashboard } from "./Components/Dashboard/DefaultDashboard/default-dashboard";
import AllForms from "./Components/Dashboard/Form/all-forms";
import { FillForm } from "./Components/Dashboard/fillForm/fill-form";
import UserContextProvider from "./Components/context/userContext";
import { AddUser } from "./Components/auth/addUser";
import UserFormView from "./Components/FormView/user-form-view";
import AdminFormView from "./Components/FormView/admin-form-view";
import { FormLevel } from "./Components/formLevel/form-level";
import { AddFormLevel } from "./Components/Dashboard/formLevel/addFormLevel";
import { ApplicationRoute } from "./Components/layout/applicationRoute";
import UserOpenigForms from './Components/UserForms/opening-forms';
import UserPendingForms from './Components/UserForms/pending-forms';
import UserRejectedForms from './Components/UserForms/rejected-forms';
import MyForms from './Components/UserForms/my-forms';
import AllUserForms from './Components/UserForms/user-forms';
import { Loading } from "./Components/Loading/loading";
import FormView from "./Components/FormView/form-view";
import { FormToSign } from "./Components/FormStatus/form-to-sign";
import GoogleLoginComponent, { FormInputsChangeOrders } from "./Components/formInputsChangeOrders/formInputsChangeOrders";
import UnSignForms from "./Components/FormStatus/un-asign-forms";
import { DepartmentAdmin } from "./Components/auth/department-admin";
import { FormSearch } from "./Components/Dashboard/fillForm/form-search";
import { UserSearch } from "./Components/auth/user-search";
import { AllUsers } from "./Components/auth/all-users";
import { ResetPassword } from "./Components/auth/reset-password";
import { FormPermission } from "./Components/formPermission/form-permission";
import { UserPermission } from "./Components/userPermission/user-permission";
import { PrintForm } from "./Components/Dashboard/FormPdf/print-form";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import { FormDetails } from "./Components/Dashboard/FormDetails/form-details";
import { Forms } from "./Components/Dashboard/Form/Forms";
import PdfGenerator from "./Components/formInputsChangeOrders/formInputsChangeOrders";
import { ToggleUserData } from "./Components/auth/toggleUserData";
import AllFormPermission from "./Components/Dashboard/Form/form-permission";
import Logs from "./Components/Dashboard/Logs/logs";
import { Reports } from "./Components/Reports/reports";

let routers = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      { index: true, element: <SignIn /> },
      { path: "terms-and-conditions", element: <TermsAndConditions /> },
      { path: "login", element: <SignIn /> },
      { path: "loading", element: <Loading /> },
      { path: "PdfGenerator", element: <PdfGenerator /> },
      {
        path: "",
        element: <ApplicationRoute />,
        children: [

          { path: "housing-allowance", element: <HousingAllowance /> },
          { path: "form-view/:id", element: <FormView /> },
          { path: 'user-form/:id', element: <UserFormView/> },
          { path: 'all-user-forms', element: <AllUserForms /> },
          { path: 'user-opening-forms', element: <UserOpenigForms /> },
          { path: 'user-pending-forms', element: <UserPendingForms /> },
          { path: 'user-rejected-forms', element: <UserRejectedForms /> },
          { path: 'my-forms', element: <MyForms /> }, 
          { path: 'form-sign/:id', element: <FormToSign /> }, 
           { path: "try-google", element: <GoogleLoginComponent/> },
           { path: 'unsign-forms', element: <UnSignForms /> },
           { path: 'form-search/:id', element: <FormSearch /> },          
           { path: "fill-form", element: <FillForm /> },
           { path: "fill-form/:id", element: <FillForm /> },
           { path: "reports", element: <Reports/> },
           { path: "reset-password", element: <ResetPassword /> },
           {
             path: "dashboard",
             element: <Dashboard />,
             children: [
              { index: true, element: <DefaultDashboard /> },
              { path: "hotels", element: <AllHotels /> },
              { path: "create-hotel", element: <CreateHotel /> },
              { path: "create-hotel/:id", element: <CreateHotel /> },
              { path: "departments", element: <AllDepartments /> },
              { path: "departments/Create", element: <CreateDepartment /> },
              { path: "departments/Update/:id", element: <CreateDepartment /> },
              { path: "dropdown", element: <AllDropdowns /> },
              { path: "create-dropdown", element: <CreateDropDown /> },
              { path: "create-dropdown/:id", element: <CreateDropDown /> },
              { path: "CreateForm", element: <CreateForm /> },
              { path: "CreateForm/:id", element: <CreateForm /> },
              { path: "allforms", element: <AllForms /> },
              { path: "create-form-name/:id", element: <CreateFormName /> },
              { path: "create-form-name", element: <CreateFormName /> },
              { path: "form-names", element: <AllformNames /> },
              { path: "create-text-name/:id", element: <CreateTextName /> },
              { path: "create-text-name", element: <CreateTextName /> }, 
              { path: "text-names", element: <AllTextNames /> },
              { path: "add-user", element: <AddUser /> },
              { path: "update-user/:id", element: <AddUser /> },
              { path: "users", element: <AllUsers /> },
              { path: "user-search", element: <UserSearch /> },
              { path: "add-admin", element: <DepartmentAdmin /> },
              { path: "form-view/:id", element: <AdminFormView /> },
              { path: "add-form-level", element: <AddFormLevel /> },
              { path: "form-permission/:id", element: <FormPermission /> },
              { path: "AllFormPermission", element: <AllFormPermission /> },
              { path: "user-permission/:id", element: <UserPermission /> },
              { path: "form-details", element: <FormDetails/> },
              { path: "forms", element: <Forms/> },
              { path: "logs", element: <Logs/> },
              { path: "user/:id", element: <ToggleUserData/> }
            ],
          },
        ],
      },
    ],
  },
]);
function App() {
  // const [isOpenSidebar, setIsOpen] = useState(true);
  // const toggleSidebar = () => {
  //     setIsOpen(!isOpenSidebar);
  // };
  return (
    <>
      <UserContextProvider>
        {/* <Sidebar />  */}
        <RouterProvider router={routers}></RouterProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
