import React, { useContext,useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { UserContext } from '../context/userContext';
import { Link } from "react-router-dom";

export const UserSearch = ({handleChildData}) => {
  const { token } = useContext(UserContext)

  const [selectedOption, setSelectedOption] = useState('Email'); // Default option
  
  const [errMsg, setMsg] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchLoad, setSearchLoad] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const [searchQuery, setSearchQuery] = useState(null);

  const [deletedMsg, setDeletedMsg] = useState(null);
  const [addedMsg, setAddedMsg] = useState(null);
  const [togleMsgError, setTogleMsgError] = useState(null);
  
  const [userData, setUserData] = useState({
    key: '',
    value: ''
  });


  const handleSearchChange = (event) => {
    // console.log(event.target.value);
   setSearchQuery(event.target.value);
  //  handleChildData(event.target.value) 
  };
 useEffect(()=>{
handleChildData(searchQuery)
 },[searchQuery])
const handleChange = (event) => {
  setSelectedOption(event.target.value);
};

useEffect(()=>{
  setUserData({
    key: selectedOption,
    value: searchQuery
  })
},[searchQuery,selectedOption])

 const userSearch = async () => {
  try {
    if (userData.value == null || '') {
      setMsg('please enter a valid email or name!')

    }
    else{
    setSearchLoad(true)
    //console.log('userData',userData);
    const { data } = await axios.post(
      `${BASE_URL}/api/Auth/userSearch`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log(data);
    setUserDetails(data)
    setIsDeleted(data.isDeleted)
    setMsg(null)
    setTogleMsgError(null)
    setDeletedMsg(null)
    setAddedMsg(null)
  }
  } catch (error) {
    if (error.response.data.title == 'Not Found') {
      console.log(error);
      setMsg('this user is not found!')
    }
    
  }
  finally{
    setSearchLoad(false)
  }
};

const deleteUser = async () => {
  try {
    setLoading(true)
    const { data } = await axios.delete(
      `${BASE_URL}/api/Auth/toggleUserDelete/${userDetails.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log(data);
    if (userDetails.isDeleted) {
      setAddedMsg('user added successfully')
      setDeletedMsg(null)
     // userSearch()

    }else{
      setDeletedMsg('user deleted successfully')
      setAddedMsg(null)
     // userSearch()

    }
    setTogleMsgError(null)
    userSearch()
  } catch (error) {
      console.log(error);
      setTogleMsgError(error.response.data)
      setDeletedMsg(null)
      setAddedMsg(null)
  }
  finally{
    setLoading(false)
  }
};
   return <>
   <div className="row justify-content-end align-items-start pe-3">
   {/* search input */}
    <div className="col-md-3 d-flex">
    <label class="form-label w-50 pt-2">search user:</label>
   <input
   type="text" 
   className="form-control py-2"
   placeholder="search by email or name"
   onChange={handleSearchChange}
 />
    </div>
<div className="px-5 pb-3">
{errMsg&&(
<div className="alert alert-danger text-center">{errMsg}</div>
)}
</div>
   </div>
  </>
};