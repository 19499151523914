import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const PdfGenerator = () => {
    const contentRef = useRef();

    const handleDownloadPdf = () => {
        const input = contentRef.current;

        html2canvas(input, { scale: 2 }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('download.pdf');
        });
    };

    return (
        <div>
            <div ref={contentRef}>
                <h1>Your Content Here</h1>
                <p>This content will be saved as a PDF.</p>
                {/* Add more content as needed */}
            </div>
            <button onClick={handleDownloadPdf}>Download as PDF</button>
        </div>
    );
};

export default PdfGenerator;
