import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import "../ContentBody/ContentBody.css";

export const ApplicationRoute = () => {
  const [isOpenSidebar, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpenSidebar);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebarToggleBtn = document.getElementById("sidebarToggleBtn");

      // Check if the sidebar is open and the click is outside the sidebar
      if (isOpenSidebar && !event.target.closest(".side-header") && (!sidebarToggleBtn || !sidebarToggleBtn.contains(event.target)) && window.innerWidth < 768) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenSidebar]);

  return <>
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpenSidebar={isOpenSidebar} />
      <div className={`${isOpenSidebar ? "content-body" : "big-content-body"} `}>
        <Outlet />
      </div>
    </>
};
