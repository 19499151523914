import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../config";
import "./create-form.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";

export const CreateForm = (props) => {
  // get basic data
  const [user, setUser] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [errMsg, setErrorMessage] = useState("");
  const [formsName, setFormsName] = useState([]);
  const [loading, setloading] = useState(false);
  const [formsStrings, setStrings] = useState([]);
  const [formsDropdowns, setFormsDropdowns] = useState([]);
  const [formGroupHeadShow, setFormGroupHeadShow] = useState([]);
  const [formGroupInputsShow, setFormGroupInputsShow] = useState([]);

  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  // Display
  const [showFormName, setShowFormName] = useState({
    id: 0,
    name: "",
  });

  const [draggedItem, setDraggedItem] = useState(null);
  const [showInput, setShowInput] = useState([]);
  const [showDateName, setShowDateName] = useState([]);
  const [showFormsStrings, setShowStrings] = useState([]);
  const [showFormsDropdowns, setShowDropdowns] = useState([]);

  // values to send to back
  const [values, setValues] = useState({
    selectedFormNameId: undefined,
    selectedTypeNameIds: [],
    selectedDropdowns: [],
    selectedTypeDates: [],
    selectedInnerGroupFormDtos: {},
  });

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);

  useEffect(() => {
    if (id && user) {
      async function getForm() {
        //console.log(id);
        const { data } = await axios.get(
          `${BASE_URL}/api/RegisterationForms/GetFormById/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        //console.log(data);
        if (data) {
          setShowFormName({
            id: data.id,
            name: data.name,
          });

          data.formDropdown.forEach((Dropdown) => {
            setShowInput((prev) => [
              ...prev,
              { order: Dropdown.order, type: "drop", data: Dropdown },
            ]);
          });
          data.formGroups?.groupTypeDropdowns.forEach((Dropdown) => {
            setFormGroupInputsShow((prev) => [
              ...prev,
              { order: Dropdown.order, type: "drop", data: Dropdown },
            ]);
            setFormGroupHeadShow((prev) => [
              ...prev,
              { order: Dropdown.order, name: Dropdown.name },
            ]);
          });
          data.formTypes.forEach((text) => {
            setShowInput((prev) => [
              ...prev,
              { order: text.order, type: "text", data: text },
            ]);
          });
          data.formGroups?.groupTypeNames.forEach((text) => {
            setFormGroupInputsShow((prev) => [
              ...prev,
              { order: text.order, type: "text", data: text },
            ]);
            setFormGroupHeadShow((prev) => [
              ...prev,
              { order: text.order, name: text.name },
            ]);
          });

          data.formDates.forEach((date) => {
            setShowInput((prev) => [
              ...prev,
              { order: date.order, type: "date", data: date },
            ]);
          });
          data.formGroups?.groupTypeDates.forEach((date) => {
            setFormGroupInputsShow((prev) => [
              ...prev,
              { order: date.order, type: "date", data: date },
            ]);
            setFormGroupHeadShow((prev) => [
              ...prev,
              { order: date.order, name: date.name },
            ]);
          });
          setShowDropdowns(data.formDropdown);
          setShowStrings(data.formTypes);
          setShowDateName(data.formDates);

          if (
            data.formGroups?.groupTypeDropdowns.length > 0 ||
            data.formGroups?.groupTypeDates.length > 0 ||
            data.formGroups?.groupTypeNames.length > 0
          ) {
            setGroupCount(1);
          }
          setCount2(
            data.formGroups?.groupTypeDropdowns.length +
              data.formGroups?.groupTypeDates.length +
              data.formGroups?.groupTypeNames.length
          );
        }
      }
      getForm();
    }
  }, [id, user]);

  async function createForm() {
    try {
      let groupNo=0;
      const formGroup = {
        selectedTypeNameIdsGroup: [],
        selectedDropdownIdsGroup: [],
        selectedTypeDatesGroup: [],
        selectedTypeFilesGroup: [],
      };

      formGroupInputsShow.forEach((input) => {
        if (input.type == "drop") {
          groupNo++;
          formGroup.selectedDropdownIdsGroup.push({
            order: input.order,
            inputId: input.data.id,
          });
        }
        if (input.type == "text") {
          groupNo++;
          formGroup.selectedTypeNameIdsGroup.push({
            order: input.order,
            inputId: input.data.id,
          });
        }
        if (input.type == "date") {
          groupNo++;
          formGroup.selectedTypeDatesGroup.push({
            order: input.order,
            name: input.data.name,
          });
        }
      });

      values.selectedTypeDates = [];
      values.selectedDropdowns = [];
      values.selectedTypeNameIds = [];
      let counter = 1;
      showInput.forEach((item) => {
        //console.log(item);
        if (item.type == "text") {
          values.selectedTypeNameIds.push({
            inputId: item.data.id,
            order: counter,
          });
        }
        if (item.type == "drop") {
          values.selectedDropdowns.push({
            inputId: item.data.id,
            order: counter,
          });
        }
        if (item.type == "date") {
          values.selectedTypeDates.push({
            name: item.data.name,
            order: counter,
          });
        }
        counter++;
      });

      values.selectedFormNameId = showFormName.id;
if (
groupNo==0
){
  //console.log("group", "gggg");

  values.selectedInnerGroupFormDtos = {};
}
else{
  //console.log("group", "kkkk");
  values.selectedInnerGroupFormDtos = formGroup;
}

      //console.log(values);
      if (!id && user) {
        setloading(true);
        // console.log("values", values);
        const { data } = await axios.post(
          `${BASE_URL}/api/RegisterationForms/CreateForm`,
          values,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );

        if (data) {
          //console.log("values", data);
          getAllFormsName();
          setShowFormName({ id: 0, name: "" });
          setShowInput([]);
          setloading(false);
          navigate(`/dashboard/form-view/${data}`);
        }
        //console.log(data);
      } else {
        if (user) {
          setloading(true);
          const { data } = await axios.put(
            `${BASE_URL}/api/RegisterationForms/UpdateForm/${id}`,
            values,
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          );
          if (data) {
            //console.log("values", values);
            getAllFormsName();
            setShowFormName({ id: 0, name: "" });
            setloading(false);
            navigate(`/dashboard/form-view/${data}`);
          }
          //console.log(data);
        }
      }
    } catch (error) {
      console.log("catch err", error);
      setErrorMessage(error.response.data.title);
      //console.log("errMsg", errMsg);

      setloading(false);
    }
  }
  async function getAllFormsName() {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/FormNames/GetUnRegisteredForms`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log("FormNames", data);
      setFormsName(data);
    } catch (err) {
      console.error("Error fetching FormsName:", err);
    }
  }
  async function getAllStrings() {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/FormNames/GetAllTypeString`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log("Strings", data);
      setStrings(data);
    } catch (err) {
      console.error("Error fetching FormsName:", err);
    } finally {
      setloading(false); // Update loading state
    }
  }
  async function getAllDropdowns() {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/Dropdowns/GetAllDropdowns`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      //console.log("Dropdowns", data);
      setFormsDropdowns(data);
    } catch (err) {
      console.error("Error fetching FormsName:", err);
    } finally {
      setloading(false); // Update loading state
    }
  }

  useEffect(() => {
    if (id && showInput.length > 0 && count1 == 0) {
      setCount1(1);

      const sorted = [...showInput].sort((a, b) => a.order - b.order);
      setShowInput(sorted);
    }
  }, [id, showInput]);

  useEffect(() => {
    if (
      id &&
      formGroupInputsShow.length > 0 &&
      formGroupInputsShow.length == count2
    ) {
      setCount2(0);
      const sorted1 = [...formGroupInputsShow].sort(
        (a, b) => a.order - b.order
      );
      const sorted2 = [...formGroupHeadShow].sort((a, b) => a.order - b.order);
      setFormGroupHeadShow(sorted2);
      setFormGroupInputsShow(sorted1);
    }
  }, [id, formGroupInputsShow, formGroupHeadShow]);
  useEffect(() => {
    if (user) {
      getAllFormsName();
      getAllStrings();
      getAllDropdowns();
    }
  }, [user]);


  const handleFormName = (event) => {
    const selectedId = event.target.value;
    const selectedFormsName = formsName.find(
      (dropDown) => dropDown.id == selectedId
    );
    //console.log(selectedFormsName);
    setShowFormName(selectedFormsName);
  };

  // Function to handle dropdown selection
  const handleDropdownChange = (event) => {
    const selectedDropdownId = event.target.value;
    const selectedDropdown = formsDropdowns.find(
      (dropDown) => dropDown.id == selectedDropdownId
    );
    const searchin = showInput.find(
      (dropDown) =>
        dropDown.type == "drop" && dropDown.data.id == selectedDropdownId
    );
    if (selectedDropdown && !searchin) {
      const drop = {
        id: selectedDropdown.id,
        name: selectedDropdown.name,
        options: selectedDropdown.options,
      };
      setShowInput((prev) => [
        ...prev,
        { order: showInput.length + 1, type: "drop", data: drop },
      ]);
    }
  };

  const handleStringsChange = (event) => {
    const selectedId = event.target.value;
    const selectedString = formsStrings.find(
      (dropDown) => dropDown.id == selectedId
    );
    //console.log(selectedString);
    const searchin = showInput.find(
      (text) => text.type == "text" && text.data.id == selectedId
    );
    if (selectedString && !searchin) {
      setShowInput((prev) => [
        ...prev,
        { order: showInput.length + 1, type: "text", data: selectedString },
      ]);
    }
  };
  const handleAddDate = () => {
    const input = document.getElementById("dateInput");
    const dateName = input.value.trim();
    const datesearch = showInput.find(
      (date) => date.type == "date" && date.data.name == dateName
    );

    if (dateName && !datesearch) {
      const date = { id: 0, name: dateName };
      setShowInput((prev) => [
        ...prev,
        { order: showInput.length + 1, type: "date", data: date },
      ]);
      input.value = "";
    }
  };

  // Deleting
  const deleteText = (event, id) => {
    event.preventDefault();
    //console.log(id);
    setShowInput((perv) =>
      perv.filter((cur) => !(cur.type == "text" && cur.data.id == id))
    );
  };

  const deleteDate = (event, name) => {
    event.preventDefault();
    setShowInput((perv) =>
      perv.filter((cur) => !(cur.type == "date" && cur.data.name == name))
    );
  };

  const deleteDropdown = (event, id) => {
    event.preventDefault();
    setShowInput((perv) =>
      perv.filter((cur) => !(cur.type == "drop" && cur.data.id == id))
    );
  };

  useEffect(() => {
    //console.log("drop", showFormsDropdowns);
  }, [showFormsDropdowns]);

  const handleDragStart = (item) => {
    setDraggedItem(item); // Keep track of the dragged item
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (targetItem) => {
    if (draggedItem === targetItem) {
      return; // If dropped on the same item, do nothing
    }

    // Get the indices of the dragged and target items
    const draggedIndex = showInput.indexOf(draggedItem);
    const targetIndex = showInput.indexOf(targetItem);

    // Swap the dragged item with the target item in the array
    const newItems = [...showInput];
    newItems[draggedIndex] = targetItem;
    newItems[targetIndex] = draggedItem;

    setShowInput(newItems); // Update the state
    setDraggedItem(null); // Reset dragged item
  };

  const handleDragEnd = () => {
    setDraggedItem(null); // Reset dragged item when drag ends
  };

  const addGroup = () => {
    const addgroup = groupCount + 1;
    //console.log(addgroup);
    setGroupCount(addgroup);
  };

  useEffect(() => {
    //console.log(formGroupHeadShow);
    //console.log(formGroupInputsShow);
  }, [formGroupHeadShow]);

  const handleDropdownGroupChange = async (event) => {
    const selectedDropdownId = event.target.value;
    const selectedDropdown = formsDropdowns.find(
      (dropDown) => dropDown.id == selectedDropdownId
    );
    const searchin = formGroupInputsShow.find(
      (text) => text.type == "drop" && text.data.id == selectedDropdownId
    );
    if (selectedDropdown && !searchin) {
      // setGroupCount(1);
      const drop = {
        id: selectedDropdown.id,
        name: selectedDropdown.name,
        options: selectedDropdown.options,
      };
      setFormGroupHeadShow((prev) => [
        ...prev,
        { order: formGroupHeadShow.length + 1, name: selectedDropdown.name },
      ]);
      setFormGroupInputsShow((prev) => [
        ...prev,
        { order: formGroupHeadShow.length + 1, type: "drop", data: drop },
      ]);
    }
  };
  const handleStringsGroupChange = (event) => {
    const selectedId = event.target.value;
    const selectedString = formsStrings.find(
      (dropDown) => dropDown.id == selectedId
    );
    const searchin = formGroupInputsShow.find(
      (text) => text.type == "text" && text.data.id == selectedId
    );
    if (selectedString && !searchin) {
      setFormGroupHeadShow((prev) => [
        ...prev,
        { order: formGroupHeadShow.length + 1, name: selectedString.name },
      ]);
      setFormGroupInputsShow((prev) => [
        ...prev,
        {
          order: formGroupHeadShow.length + 1,
          type: "text",
          data: selectedString,
        },
      ]);
    }
  };
  const handleAddDateGroup = () => {
    const input = document.getElementById("dateInput1");
    const dateName = input.value.trim();
    const datesearch = formGroupInputsShow.find(
      (date) => date.type == "date" && date.data.name == dateName
    );
    if (dateName && !datesearch) {
      const date = { id: 0, name: dateName };
      input.value = "";
      setFormGroupHeadShow((prev) => [
        ...prev,
        { order: formGroupHeadShow.length==0?1:formGroupHeadShow[formGroupHeadShow.length-1].order+1 , name: date.name },
      ]);
      setFormGroupInputsShow((prev) => [
        ...prev,
        { order: formGroupHeadShow.length==0?1:formGroupHeadShow[formGroupHeadShow.length-1].order+1, type: "date", data: date },
      ]);
    }
  };
  const DeleteGroupInput = (e,order) => {
  e.preventDefault();
      setFormGroupHeadShow( (prev) =>prev.filter((inp)=>
        {
          if (inp.order==order) {
          return false;
          }
          if(inp.order>order){
            inp.order=inp.order-1;
          }
          return true;
        }
      ));
      setFormGroupInputsShow((prev) =>prev.filter((inp)=>
        {
          if (inp.order==order) {
          return false;
          }
          if(inp.order>order){
            inp.order=inp.order-1;
          }
          return true;
        }
      ) );
      if (formGroupHeadShow.length==0) {
        setGroupCount(0)
      }
    
  };
useEffect(()=>{
if (formGroupHeadShow.length>0&&groupCount==0) {
   setGroupCount(1);
}
},[formGroupHeadShow])

  const deleteGroup = () => {
    const addgroup = groupCount - 1;
    //console.log(addgroup);
    setGroupCount(addgroup);
  };

  return (
    <>
      <div className=''>
        <div className="content">
          {/* {({ errors, touched, isSubmitting }) => ( */}
          <form className="bg-body  w-100 p-3 mb-4">
            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="">
                  <label className="text-right control-label col-form-label">
                    * Form name
                  </label>
                  <div className="position-relative dropdown bootstrap-select hotel-select">
                    <select
                      className="selectpicker hotel-select form-select mt-2"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleFormName}
                    >
                      <option disabled value="">
                        Select name
                      </option>
                      {formsName?.map((formname) => (
                        <option key={formname.id} value={formname.id}>
                          {formname.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="">
                  <label className="text-right control-label col-form-label">
                    * Dropdowns
                  </label>
                  <div className="position-relative dropdown bootstrap-select hotel-select">
                    <select
                      className="selectpicker hotel-select form-select mt-2"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleDropdownChange}
                    >
                      <option disabled value="">
                        Select Dropdown
                      </option>
                      {formsDropdowns?.map((dropDown) => (
                        <option key={dropDown.id} value={dropDown.id}>
                          {dropDown.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="">
                  <label className="text-right control-label col-form-label">
                    * Texts
                  </label>
                  <div className="position-relative dropdown bootstrap-select hotel-select">
                    <select
                      className="selectpicker hotel-select form-select mt-2"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleStringsChange}
                    >
                      <option disabled value="">
                        Select text
                      </option>
                      {formsStrings?.map((formstring) => (
                        <option key={formstring.id} value={formstring.id}>
                          {formstring.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <label className="text-right control-label col-form-label">
                * Dates
              </label>
              <div className="col-md-6">
                <input
                  id="dateInput"
                  className="form-control position-relative w-75"
                  type="text"
                  placeholder="Date name"
                />
              </div>
              <div className="col-md-4">
                <input
                  className="btn btn-success position-relative mt-3 mt-md-0"
                  type="button"
                  value="Add date"
                  onClick={handleAddDate}
                />
              </div>
            </div>

            <div className="text-center h4 mt-3">Group</div>

            <div className="row mt-2">
              <div className="col-sm-4">
                <div className="">
                  <label className="text-right control-label col-form-label">
                    * Dropdowns
                  </label>
                  <div className="position-relative dropdown bootstrap-select hotel-select">
                    <select
                      className="selectpicker hotel-select form-select mt-2 input-height"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleDropdownGroupChange}
                    >
                      <option disabled value="">
                        Select Dropdown
                      </option>
                      {formsDropdowns?.map((dropDown) => (
                        <option key={dropDown.id} value={dropDown.id}>
                          {dropDown.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="">
                  <label className="text-right control-label col-form-label">
                    * Texts
                  </label>
                  <div className="position-relative dropdown bootstrap-select hotel-select">
                    <select
                      className="selectpicker hotel-select form-select mt-2 input-height"
                      aria-label="Default select example"
                      defaultValue=""
                      onChange={handleStringsGroupChange}
                    >
                      <option disabled value="">
                        Select text
                      </option>
                      {formsStrings?.map((formstring) => (
                        <option key={formstring.id} value={formstring.id}>
                          {formstring.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <label className="text-right control-label col-form-label">
                  * Dates
                </label>
                <div className="input-group">
                  <input
                    id="dateInput1"
                    className="form-control input-height"
                    type="text"
                    placeholder="Date name"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={handleAddDateGroup}
                    >
                      Add Date
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <form className="bg-body w-100 p-3">
            <h5 className="text-center">{showFormName.name}</h5>
            {errMsg && (
              <div className="alert alert-danger">Form name is required</div>
            )}

            <div className="row">
              {showInput.map((item) => (
                <div
                  className="col-md-4 "
                  key={item.order}
                  draggable
                  onDragStart={() => handleDragStart(item)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(item)}
                  onDragEnd={handleDragEnd}
                >
                  {/* Display relevant information */}
                  {item.type == "drop" && (
                    <div className="div2">
                      <label className="text-right control-label col-form-label">
                        *{item.data?.name}
                      </label>
                      <div className="d-flex align-items-center dropdown bootstrap-select hotel-select">
                        <select
                          className="selectpicker hotel-select form-select mt-2"
                          aria-label="Default select example"
                        >
                          <option value="" defaultChecked>
                            Select {item.data?.name}
                          </option>
                          {item.data?.options?.map((dropDownOption) => (
                            <option
                              key={dropDownOption.id}
                              value={dropDownOption.id}
                            >
                              {dropDownOption.elementName}
                            </option>
                          ))}
                        </select>
                        <button
                          className="btn btn-danger  m-3"
                          onClick={(event) =>
                            deleteDropdown(event, item.data.id)
                          }
                        >
                          <i className="fa-solid fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  )}

                  {item.type == "text" && (
                    <div className="div2" key={item.data.id}>
                      <label className="text-right control-label col-form-label">
                        *{item.data.name}
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control mt-2"
                          id={item.data.id}
                          name="name"
                          placeholder={item.data.name}
                        />
                        <button
                          className="btn btn-danger  m-3"
                          onClick={(event) => deleteText(event, item.data.id)}
                        >
                          <i className="fa-solid fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  )}

                  {item.type == "date" && (
                    <div className="div2">
                      <label className="text-right control-label col-form-label">
                        *{item.data.name}
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="date"
                          className="form-control mt-2"
                          data-toggle="datepicker"
                          name={item.data.name}
                          placeholder="yyyy-mm-dd"
                        />
                        <button
                          className="btn btn-danger  m-3"
                          onClick={(event) => deleteDate(event, item.data.name)}
                        >
                          <i className="fa-solid fa-minus"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table
                    id="selected-Items-list-table"
                    className="table table-hover table-bordered table-info text-center"
                  >
                    <thead>
                      <tr>
                        <th width="5%">
                          <button
                            type="button"
                            name="addRow"
                            id="addRow"
                            className="btn btn-light rounded-circle d-flex"
                            onClick={addGroup}
                          >
                            <i className="fa-solid fa-database"></i>
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </th>
                        {formGroupHeadShow.map((item, index) => (
                          <th key={`${index}h`}>{item.name}
                           <button
                          className="btn btn-danger  m-2"
                          onClick={(event) =>
                            DeleteGroupInput(event, item.order)
                          }
                        >
                          <i className="fa-solid fa-minus"></i>
                        </button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody id="outgoing-data">
         {Array.from({ length: groupCount }, (_, index) => (
                        <tr key={index}>
                          <td>
                            <div className="delete">
                              <button
                                type="button"
                                className="btn"
                                onClick={() => deleteGroup()}
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </div>
                          </td>
                          {formGroupInputsShow.map((inp, inpIndex) => (
                            <td key={inpIndex}>
                              {inp.type === "drop" && (
                                <select
                                  className="selectpicker hotel-select form-select mt-2"
                                  aria-label="Default select example"
                                >
                                  <option value="" defaultChecked>
                                    Select {inp.data?.name}
                                  </option>
                                  {inp.data?.options?.map((dropDownOption) => (
                                    <option
                                      key={dropDownOption.id}
                                      value={dropDownOption.id}
                                    >
                                      {dropDownOption.elementName}
                                    </option>
                                  ))}
                                </select>
                              )}

                              {inp.type === "text" && (
                                <input
                                  type="text"
                                  className="form-control mt-2"
                                  id={inp.data.id}
                                  name="name"
                                  placeholder={inp.data.name}
                                />
                              )}

                              {inp.type === "date" && (
                                <input
                                  type="date"
                                  className="form-control mt-2"
                                  data-toggle="datepicker"
                                  name={inp.data.name}
                                  placeholder="yyyy-mm-dd"
                                />
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
          <div className="d-flex justify-content-center mt-4">
            <button
              onClick={() => createForm()}
              className="btn btn-success"
              disabled={loading}
            >
              {!id ? "Create" : "Update"} {loading ? "..." : ""}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
