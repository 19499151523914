import { Link } from "react-router-dom";
import { AddUser } from "./addUser";
import { useState } from "react";
import { UserPermission } from "../userPermission/user-permission";
import { DepartmentAdmin } from "./department-admin";

export function ToggleUserData(){
   const [openToggle, setOpenToggle] = useState(1);

   const handleToggle = (toggleId) => {
     setOpenToggle((prev) => (prev === toggleId ? null : toggleId));
   };
return<>
        <div className=' toggle pt-3 '>
          <div className='d-flex justify-content-center border shadow-me w-30 py-3  text-dark fs-6 bg-white'>
            <div className='px-3 border-end'>
              <Link
                className='  text-decoration-none text-dark fs-6'
                onClick={() => handleToggle(1)}
                aria-expanded={openToggle === 1}
              >
                Profile
              </Link>
            </div>
            <div className='px-3 border-end'>
              <Link
                className='  text-decoration-none text-dark fs-6'
                onClick={() => handleToggle(2)}
                aria-expanded={openToggle === 2}
              >
                Department Admin
              </Link>
            </div>
            <div className=''>
              <Link
                className='px-3 text-decoration-none text-dark fs-6'
                onClick={() => handleToggle(3)}
                aria-expanded={openToggle === 3}
              >
                Permissions
              </Link>
            </div>
          </div>
          <div>
            <div className={`collapse ${openToggle === 1 ? 'show' : ''}`}>
              <div className='card card-body mt-4'>
              <AddUser />
              </div>
            </div>
            <div className={`collapse ${openToggle === 2 ? 'show' : ''}`}>
              <div className='card card-body mt-5'>
            <DepartmentAdmin/>
              </div>
            </div>
            <div className={`collapse ${openToggle === 3 ? 'show' : ''}`}>
              <div className='card card-body mt-5'>
               <UserPermission/>
              </div>
            </div>
          </div>
        </div>
</>   
}