import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config";
import kaiSolLogo from '../../../assets/imgs/logo.png'
import { UserContext } from "../../context/userContext";
//import Jwt from 'jsonwebtoken';
import { jwtDecode } from "jwt-decode";
import './login.css';
import loginLogo from '../../../assets/imgs/KSR-Master.png'
export const SignIn = () => {
  // const [decodedToken1, setDecodedToken] = useState('');
  const navigate = useNavigate();
  const { setUser, setUserRole } = useContext(UserContext)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (setUser) {
      //console.log(window.location.pathname);
      //console.log(window.location.href);
      //console.log(window.location);
      if (window.location.pathname == "/")
        navigate('/dashboard')
    }
  }, [setUser])
  async function submitLogin(values) {
    setLoading(true)
    // console.log('values',values);
    const formData = new FormData()
    formData.append('password', values.password)
    formData.append('email', values.email)
    try {
      const { data } = await axios
        .post(`${BASE_URL}/api/Auth/login`, values)
      // console.log('data',data.token);
      localStorage.setItem('user', JSON.stringify(data.token))

      if (data) {
        const decodedToken = jwtDecode(data.token);
        // setUser(jwtDecode(data.token))
        if (decodedToken) {
          // console.log('decodedToken',decodedToken);
          localStorage.setItem('userData', JSON.stringify(decodedToken))
          localStorage.setItem('userRole', JSON.stringify(decodedToken.roles))
          setUser(decodedToken)
          setUserRole(decodedToken.roles)
          //console.log(decodedToken.isActive);
          if (decodedToken.isActive == "True") {
            navigate('/dashboard')
          } else {
            navigate('/terms-and-conditions')
          }
        }
      }

    } catch (error) {
      console.log('err', error);
      setError(error.response.data)
    } finally {
      setLoading(false)
    }
  }
  let validationSchema = Yup.object({
    email: Yup.string().email("in-valid email").required("email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "wrong password")
      .matches(/[A-Z]/, "wrong password")
      .matches(/[a-z]/, "wrong password")
      .matches(/\d/, "wrong password")
      .matches(/[\W_]/, "wrong password")
  });
  let formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: submitLogin,
  });
  return <>
    <div className="login  p-0 m-0">
      <div className="overlay d-flex justify-content-center align-items-center">
        <div className="content text-center d-flex justify-content-center align-items-center">
          <form onSubmit={formik.handleSubmit} className="">
            <div className="pt-1 pb-4">
              <span><img className="w-75" src={loginLogo} alt="kaisol login logo" srcSet="" /></span>
            </div>
            {error !== null ? <div className="alert alert-danger">{error}</div> : ''}
            <div className="input-group row mx-1 mb-3 p-0 border-focus">
              <span className="input-group-addon col-2 col-md-1 text-white text-center bg-GoldColor  pt-1 "
               id="basic-addon1">
                <i className="fa-solid fa-user pt-2 "></i></span>
              <input name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email}
                type="email" className="col-10 col-md-11 border-0 py-2" id="userEmail" aria-describedby="basic-addon1" 
                placeholder="User ID / Email" />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="alert alert-danger mt-2 p-2">
                {formik.errors.email}
              </div>
            ) : null}
            <div className="input-group row mx-1 mb-3 p-0 mb-4">
              <span className="input-group-addon col-2 col-md-1 text-white text-center bg-GoldColor  pt-1" id="basic-addon1">
                <i className="fa-solid fa-pencil pt-2"></i></span>
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.password}
                type="password" className="col-10 col-md-11 border-0 py-2" id="password" placeholder="Password" />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="alert alert-danger mt-2 p-2">
                {formik.errors.password}
              </div>
            ) : null}

            <div className="border-top pt-4">
              <button type="submit" className="btn-signIn" disabled={loading}
              >
                {loading ? "signing in..." : "LOGIN"}</button>

            </div>
          </form>
        </div>

      </div>

    </div>

  </>
}
