import axios from "axios";
import { BASE_URL } from "../../config";
import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

export const CreateDropDown = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [errMsg, setErrorMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [outputValues, setOutputValues] = useState([]);
  const [initialValues, setInitialValues] = useState({
    dropdownName: "",
    elementsOfDropdown: [],
  });

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);
  }, []);

  useEffect(() => {
    if (id) {
      getDropDownById();
    }
  }, [id, user]);

  const getDropDownById = async () => {
    if (user) {
      const { data } = await axios.get(
        `${BASE_URL}/api/Dropdowns/GetDropdownById/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      setInitialValues({
        dropdownName: data.name,
        elementsOfDropdown: data.options,
      });
      setOutputValues(data.options);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSetOutput = () => {
    setOutputValues([...outputValues, { id: Date.now(), value: inputValue }]);
    setInputValue("");
  };

  const deleteOption = (deletedValue) => {
    const updatedOptions = outputValues.filter((value) => value.value !== deletedValue);
    setOutputValues(updatedOptions);
  };

  const validationSchema = Yup.object().shape({
    dropdownName: Yup.string().required("Name is required"),
  });

  const createDropDown = async (values, { setSubmitting }) => {
    try {
      values.elementsOfDropdown = outputValues;

      if (id) {
        // Update existing dropdown
        await axios.put(
          `${BASE_URL}/api/Dropdowns/UpdateDropdownName/${id}`,
          { name: values.dropdownName },
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
        await axios.put(
          `${BASE_URL}/api/Dropdowns/UpdateDropdownOptions/${id}`,
          { options: values.elementsOfDropdown },
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
      } else {
        // Create new dropdown
        await axios.post(
          `${BASE_URL}/api/Dropdowns/AddDropdown`,
          values,
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        );
      }

      navigate("/dashboard/dropdown");
    } catch (error) {
      setErrorMessage(error.response.data.errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const getOptionData = (event, optionId) => {
    const value = event.target.value;
    const options = outputValues.map((option) => {
      if (option.id === optionId) {
        return { ...option, value };
      }
      return option;
    });
    setOutputValues(options);
  };

  const addOption = async () => {
    if (user) {
      await axios.post(
        `${BASE_URL}/api/Dropdowns/AddOptionToDropdown/${id}?elementName=${inputValue}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      getDropDownById();
    }
  };

  const UpdateOptions = async (optionId) => {
    const name = outputValues.find((option) => option.id === optionId);
    const value = { name: name.value };

    if (id && name) {
      await axios.put(
        `${BASE_URL}/api/Dropdowns/UpdateElementName/${id}/${optionId}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
    }
  };

  const DeleteOptionFromDropdown = async (optionId) => {
    if (id) {
      await axios.delete(
        `${BASE_URL}/api/Dropdowns/DeleteOptionFromDropdown/${id}/${optionId}`,
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      setOutputValues((prev) => prev.filter((dep) => dep.id !== optionId));
    }
  };

  return (
    <div className="container pt-4">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createDropDown}
        enableReinitialize={true}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => (
          <Form className="w-100" onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="dropdownName" className="form-label">
                Name
              </label>
              <div className="row">
                <div className="col-8 col-md-10">
                  <Field
                    type="text"
                    name="dropdownName"
                    id="dropdownName"
                    className={`form-control  ${
                      errors.dropdownName && touched.dropdownName && "is-invalid"
                    }`}
                    placeholder="Name"
                  />
                  <ErrorMessage
                    name="dropdownName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                {id && (
                  <div className="col-4 col-md-2">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-outline-secondary w-100"
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
            {errMsg && <div className="alert alert-danger">{errMsg}</div>}

            <label className="form-label">Option</label>
            <div className="row align-items-center mb-3">
              <div className="col-8 col-md-10">
                <input
                  value={inputValue}
                  onChange={handleInputChange}
                  type="text"
                  name="dropdownOption"
                  className="form-control"
                  placeholder="Option"
                />
              </div>
              <div className="col-4 col-md-2">
              <button
  type="button"
  onClick={!id ? handleSetOutput : addOption}
  className="btn btn-outline-success w-100 custom-font-size"
  disabled={!inputValue}
>
  Add Option
</button>

              </div>
            </div>
            {outputValues.length > 0 && (
              <div className="pt-3">
                <div className="table-responsive">
                <table className="table">
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {outputValues.map((value, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          <input
            onChange={(event) => getOptionData(event, value.id)}
            type="text"
            value={value.value || ""}
            className="form-control"
            disabled={!id}
          />
        </td>
        <td>
          <div className="d-flex flex-column flex-md-row gap-2">
            {id && (
              <div>

              <button
                type="button"
                onClick={() => UpdateOptions(value.id)}
                className="btn btn-outline-secondary btn-sm me-2"
              >
                Edit
              </button>

                <button
                type="button"
                onClick={() => DeleteOptionFromDropdown(value.id)}
                className="btn btn-outline-danger btn-sm"
              >
                Delete
              </button>
              </div>
            )}
            {
              !id&&
            <button
              type="button"
              onClick={() => deleteOption(value.value)}
              className="btn btn-outline-danger btn-sm"
            >
              Delete
            </button>
            }
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>
                </div>


              </div>
            )}
            <div className="text-center mt-4">
              {!id && (
                <button
                  type="submit"
                  className="btn btn-dark"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
