import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config";
import { Loading } from "../../Loading/loading";

export default function AllForms() {
  const [user, setUser] = useState("");
  const [allForms, setAllForms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"));
    setUser(token);

    async function getForms() {
      try {
        setLoading(true);
        const { data } = await axios.get(`${BASE_URL}/api/RegisterationForms/GetAllForms`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const sortedForms = [...data].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
        setAllForms(sortedForms);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    if (user) {
      getForms();
    }
  }, [user]);

  const deleteForm = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/RegisterationForms/DeleteForm/${id}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      setAllForms((prev) => prev.filter((form) => form.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  if (loading && !allForms.length) {
    return <Loading />;
  }

  return (
    <div className="container py-4">
           <div className="dropdown  pb-md-4 pt-5 pt-md-0">
  <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    ACTION MENU
  </button>
  <ul className="dropdown-menu">
  <li><Link className="dropdown-item" to="/dashboard/CreateForm">Create Form</Link></li>
  <li><Link className="dropdown-item" to="/dashboard/add-form-level">Add Level</Link></li>
  <li><Link className="dropdown-item" to="/dashboard/AllFormPermission">Show all levels</Link></li>
  </ul>
</div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allForms.map((form, index) => (
              <tr key={form.id}>
                <td>{index + 1}</td>
                <td>{form.name}</td>
                <td>
                  <div className="d-flex flex-column flex-md-row gap-2">
                    <Link
                      to={`/dashboard/form-view/${form.id}`}
                      className="btn btn-outline-primary btn-sm"
                    >
                       View
                    </Link>
                    <Link
                      to={`/dashboard/CreateForm/${form.id}`}
                      className="btn btn-outline-secondary btn-sm"
                    >
                       Update
                    </Link>
                    <button
                      onClick={() => deleteForm(form.id)}
                      className="btn btn-outline-danger btn-sm"
                    >
                       Delete
                    </button>
                    <Link
                      to={`/dashboard/form-permission/${form.id}`}
                      className="btn btn-outline-success btn-sm"
                    >
                       Add Permission
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
